export enum CONFIG_STATE_TYPE {
    CONFIG_STATE_NONE = 0,
    CONFIG_STATE_CONFIGURE = 1,
    CONFIG_STATE_STAGE_START = 2,
    CONFIG_STATE_STAGE1 = 3,
    CONFIG_STATE_STAGE2 = 4,
    CONFIG_STATE_STAGE3 = 5,
    CONFIG_STATE_ERROR = 100,
}

export interface Social {
    id: number,
    socialId: string,
    type: number;
    typeName: string;
    active: boolean;
    image: string;
    configState: CONFIG_STATE_TYPE;
    configData: string;
    errorData: string;
    linked: boolean
}

export interface CaptionToneOptions {
    label: string;
    value: string;
}

export class Product
{
    id: number;
    name: string;
    type: number;
    description: string;
    image: string;
    active: boolean;
    socials: number[]
    url: string;
    hash_tags: string;
    caption_tones: string[];
    caption_tone_scheme: number;
    caption_emojis: boolean;
    caption_tones_options: CaptionToneOptions[];

    constructor(id = 0, name = "", type=1, description="", image="", active=true, socials=[], url="", hashTags="", caption_tones=[], caption_tone_scheme=2, caption_emojis=false)
    {        
        this.id=id;
        this.name=name;
        this.type=type;
        this.description=description;
        this.image=image;
        this.active=active;
        this.socials=socials;
        this.url=url;
        this.hash_tags=hashTags;        
        this.caption_tone_scheme=caption_tone_scheme;
        this.caption_emojis=caption_emojis;
        this.caption_tones=caption_tones;
        this.caption_tones_options=[]; //this is a working editor field for the caption tones selector

        for (var caption_tone_index in caption_tones)
        {
            var caption_tone = caption_tones[caption_tone_index];
            var captionThemeOption = {label: caption_tone, value: caption_tone} as CaptionToneOptions;
            this.caption_tones_options.push(captionThemeOption);            
        }
    }

    CaptionToneOptions_Commit()
    {
        var caption_tones = [] as string[]

        for (var caption_tone_index in this.caption_tones_options)
        {
            var caption_tone = this.caption_tones_options[caption_tone_index];
            caption_tones.push(caption_tone.value);            
        }

        this.caption_tones = caption_tones;
    }
}


export class ProductType {
    typeName: string = "";
    hash_tags: string = "";
}