import {createSlice} from '@reduxjs/toolkit'

const authenticationSlice = createSlice({
    name: 'auth',
    initialState: {
        user:null, 
        token:null
    },
    reducers: {
        setCredentials: (state, action) => {
            const {user, token}  = action.payload.data
            return{
                user : user,
                token: token
            }
        },
        logOut: () => {            
            return {
                user: null,
                token: null
            }
        }
    },
})

export const {setCredentials, logOut} = authenticationSlice.actions

export default authenticationSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token


