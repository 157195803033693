import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageTitle from 'components/common/PageTitle';
import Socials from 'features/socials/Socials';

class SocialManagement extends Component {    
    render() {
        return (
            <div className="mtb-30 theme-color">                           
                <Socials/>                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(SocialManagement);