import React, {useEffect} from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
    friend1,
    friend2,
    friend3,
    people1,
    people2,
    people3,
    media5
} from "helper/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "redux/auth/actions";
import PopoverBlock from './PopoverBlock';
import { logOut } from "features/authentication/authenticationSlice";
import { resetProduct } from 'features/products/productsSlice'
import { resetSchedule } from 'features/schedule/scheduleSlice'
import { resetSocials } from 'features/socials/socialsSlice'
import { resetSubscription } from "features/subscription/subscriptionSlice";
import { useGetSubscriptionBalancesQuery } from "features/subscription/subscriptionAPISlice";
import RoyTooltip from "components/common/RoyTooltip";
import { apiSlice } from "../../app/api/apiSlice";

const Header = props => {
    const { drawerMiniMethod, mini, layoutSettingDrawerToggle } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data:balanceData, isLoading} = useGetSubscriptionBalancesQuery()

    const userSignout = () => {
        dispatch(logOut());        
        dispatch(resetProduct());
        dispatch(resetSchedule());
        dispatch(resetSocials());
        dispatch(resetSubscription());
        dispatch(apiSlice.util.resetApiState());
    };    

    return (
        <HeaderWrapper {...props}>
            <div className="headerBack">
                <div className="flex-x align-center">
                    <div className="drawer-handle-arrow">
                        {mini ? (
                            <button
                                className="top-header-icon"
                                onClick={() => drawerMiniMethod()}
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                        ) : (
                            <button
                                className="top-header-icon"
                                onClick={() => drawerMiniMethod()}
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                        )}
                    </div>
                   
                    <div className="pl-50 flex-1">
                        {
                            !isLoading &&
                            <RoyTooltip id={`tokens`} title={balanceData?.expired ? "Your subscription has expired!" : "Token Balance"} placement="bottom">
                                <button id="tokens" onClick={() => navigate('/subscription')} className={balanceData?.expired ? "top-header-icon-coins-low" : "top-header-icon-coins"}>
                                    <i className="fas fa-coins"></i>&nbsp;&nbsp;{balanceData?.token_balance ? balanceData?.token_balance : "0"}                                
                                </button>
                            </RoyTooltip>
                        }
                        
                    </div>                        
                    <div className="pl-10">
                        <button id="profile" className="top-header-profile-class" alt="notify"> 
                            <i className="fas fa-user"></i>                                
                        </button>    
                        <UncontrolledPopover 
                            className="roy-menu"
                            innerClassName="roy-inner-content"
                            placement="bottom-end" 
                            target="profile" 
                            trigger="focus">
                            <PopoverBody>
                                <div
                                    className="roy-menu-list"
                                    onClick={() => navigate('/profile')}
                                >
                                    My Profile
                                </div>
                                {/* <div
                                    className="roy-menu-list"
                                >
                                    Settings
                                </div> */}
                                {/* <div    
                                    className="roy-menu-list"      
                                    onClick={() => navigate('/subscription')}                                                                      
                                >
                                    Token Balance : {tokenBalance ? tokenBalance : "0"}
                                </div> */}
                                <div
                                    className="roy-menu-list"
                                    onClick={userSignout}
                                >
                                    Logout
                                </div>
                                
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                {/* <div className="pl-10">
                        <button
                            onClick={layoutSettingDrawerToggle}
                            className="top-header-icon"
                        >
                            <i className="fas fa-th-large"></i>
                        </button>
                    </div> */}
                </div>
            </div>
        </HeaderWrapper>
        );
    };
    
    export default Header;
