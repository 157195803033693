import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import './style.css'
import { useNavigate } from "react-router-dom";

export default function TermsOfService() {        
        const navigate = useNavigate()

        return (            
            <div className="termsContainer">                  
                <div className="row pt-45">                               
                    <div className="col-12 center">
                    <h1 className="widget-dark-color">Autigma Terms of Service</h1>                                           
                    </div>                    
                    <div className="col-12 paragraph">                        
                        <br/>
                        This Terms of Service agreement ("Agreement") is entered into by and between Light Hearted Tech, a South African Private Company, having its principal place of business at Jackal Creek Estate, Johannesburg, Gauteng, South Africa ("Company"), and you, the user of the Company's services ("User"), collectively referred to as the "Parties".                                                
                        <ol>
                            <br/>
                            <li>Acceptance of Terms - By accessing or using any services provided by Company (the "Services", "Autigma"), User agrees to be bound by the terms and conditions set forth in this Agreement. If User does not agree to all the terms and conditions of this Agreement, User must not access or use the Services.</li>
                            <li>Acceptance of Privacy Policy - By accessing or using any Services provided by Company, User agrees to be bound by the terms and conditions set forth in this Agreement. If User does not agree to all the terms and conditions of this Agreement, User must not access or use the Services.</li>
                            <li>Acceptance of Terms of Use of Third Parties - Autigma uses a number of third party social media API services:
                            <ul>
                                <li>X (Twitter) Api</li>
                                <li>YouTube API Services</li>
                                <li>TikTok API</li>
                                <li>Instagram</li>
                            </ul>
                            <br/>
                            By accessing or using Autigma, the User understands and agrees to be bound by additional third-party Terms of Use/Service required for Autigma to operate. This includes the following: </li>
                            <ul>
                                <li><a href="https://x.com/en/tos">X (Twitter)</a></li>
                                <li><a href="https://www.youtube.com/t/terms">YouTube</a></li>
                                <li><a href="https://www.tiktok.com/legal/page/row/terms-of-service/en">TikTok</a></li>
                                <li><a href="https://help.instagram.com/581066165581870">Instagram</a></li>
                            </ul>
                            <br/>
                            <li>Description of Services - Company provides the Autigma SaaS service, which allows for AI-assisted analysis of gaming video content for automated captioning and marketing purposes. The Services may include, but are not limited to, (a) AI driven gameplay video analysis and short-form clip generation with captioning. (b) Scheduled social media account posting of generated short-form content, including videos and text. (c) Social media metrics of posted content.</li>
                            <li>User Accounts - (a) User must register for an account to access certain features of the Services. User agrees to provide accurate, current, and complete information during the registration process and to keep such information updated. (b) User is responsible for maintaining the confidentiality of User's account credentials and for all activities that occur under User's account. User agrees to immediately notify Company of any unauthorized use of User's account or any other breach of security.</li>
                            <li>Fees and Payment - (a) User agrees to pay all fees associated with User's use of the Services as set forth in Company's pricing policy. Fees are subject to change upon notice from Company. (b) Payments are non-refundable except as expressly provided in this Agreement or as required by law.</li>
                            <li>Intellectual Property - (a) Company retains all right, title, and interest in and to the Services, including all related intellectual property rights. User may not use the Services for any purpose not expressly permitted by this Agreement. (b) User retains all rights to any data or content uploaded, created, or generated by User using the Services ("User Content"). By uploading, creating, or generating User Content, User grants Company a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, modify, and display User Content solely for the purpose of providing the Services.</li>
                            <li>Confidentiality - (a) Each party agrees to maintain the confidentiality of any confidential information disclosed by the other party ("Confidential Information") and not to use or disclose such Confidential Information except as necessary to perform its obligations under this Agreement or as required by law. (b) The obligations of confidentiality under this Agreement shall survive the termination or expiration of this Agreement for a period of 3 years.</li>
                            <li>Disclaimer of Warranties - THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND. COMPANY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
                            <li>Limitation of Liability - IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS, REVENUE, DATA, OR BUSINESS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                            <li>Governing Law and Dispute Resolution - This Agreement shall be governed by and construed in accordance with the laws of South Africa. Any dispute arising out of or in connection with this Agreement shall be resolved through arbitration administered by The Arbitration Foundation of Southern Africa (AFSA) in accordance with its rules.</li>
                            <li>Miscellaneous - (a) Entire Agreement. This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether written or oral. (b) Amendments. This Agreement may be amended only by a written instrument executed by both Parties. (c) Severability. If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions of this Agreement shall remain in full force and effect. (d) Waiver. The failure of either Party to enforce any provision of this Agreement shall not constitute a waiver of such provision or any other provision.</li>
                            <li>Contact Information - If you have any questions about this Agreement, please contact us via the Support Page or <a href="mailto:support@autigma.com">support@autigma.com</a>.</li>
                        </ol>                        
                    </div>
                    <br/>
                </div>
            </div>            
        );
    
}
