import React from "react";
import { LinearProgressWidget } from "components/widgets/statisticswidgets";

const ScheduleInsights = (props) => {    

    const {this_week_scheduled, this_week_completed, last_week_scheduled} = props

    const getProgressColour = (value) => {
        let percentage = value / 7 * 100;

        if(percentage < 50) {
            return "#FA7252"
        }
        if(percentage < 75){
            return "#fcba03"
        }
        return "#00FF00"
    }

    const getPercentage = (value) => {
        return Math.min(Math.ceil(value / 7 * 100), 100);
    }

    return (
        <div>
            <div className="col-12">

            </div>
           <div className="col-12">
                <LinearProgressWidget
                    background={props.layoutTheme.cardBackground}  
                    dark                         
                    headline="Posts Scheduled This Week"
                    subheader={this_week_scheduled + "/7"}
                    progress={getPercentage(this_week_scheduled)}
                    progressColor={getProgressColour(this_week_scheduled)}
                    info={this_week_scheduled + " / 7"}
                />
            </div>
            <div className="col-12 pt-10">
                <LinearProgressWidget
                    background={props.layoutTheme.cardBackground}  
                    dark      
                    headline="Posts Completed This Week"
                    subheader={this_week_completed + "/7"}
                    progress={getPercentage(this_week_completed)}
                    progressColor={"#00FF00"}
                    info={this_week_completed + " / 7"}
                />
            </div>
            <div className="col-12 pt-10">
                <LinearProgressWidget
                    background={props.layoutTheme.cardBackground}  
                    dark      
                    headline="Posts Last Week"
                    subheader={last_week_scheduled + "/7"}
                    progress={getPercentage(last_week_scheduled)}
                    progressColor={getProgressColour(last_week_scheduled)}
                    info={last_week_scheduled + " / 7"}
                />
            </div>
        </div>
    )
}

export default ScheduleInsights