import {apiSlice} from '../../app/api/apiSlice'

export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({                
        getDashboard: builder.query({
            query: () => ({
                url: '/dashboard/get',                                    
            }),
            providesTags: ['Socials', 'Products', 'Dashboard']
        }),
        getNewsFeed: builder.query({
            query: () =>  ({
                url: '/news_feed',
            }),
            providesTags: ['News']
        })               
    })
})


export const {
    useGetDashboardQuery,
    useGetNewsFeedQuery,
} = dashboardApiSlice