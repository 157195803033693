import {React, useEffect, useState} from "react";
import { useSelector, useDispatch, } from "react-redux";
import {
    cover5,
} from "helper/constant";
import ScheduleItemWrapper from "./ScheduleItem";
import { useGetScheduleQuery, useCreateScheduleMutation, useInsertUpdateScheduleItemMutation } from "./scheduleApiSlice";
import { setSchedule, updateScheduledItem } from "./scheduleSlice";
import LoaderComponent from "components/common/LoaderComponent";
import {Button} from 'reactstrap'
import { useNavigate  } from 'react-router-dom';
import { Wrapper, CenterChildrenWrapper } from "./time.style";
import { setStartDate } from "./scheduleAdminSlice";
import { useSocial_GetIsLinkedByProductQuery } from "features/socials/socialsAPISlice";
import { useGetSubscriptionBalancesQuery } from "features/subscription/subscriptionAPISlice";
import { ToISODateWithTimeZone } from "helper/methods";
import { WeekPicker } from "./WeekPicker";
import { last } from "lodash";


const WeekSchedule = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [now] = useState(new Date());
    const selectedProduct = useSelector((state) => state.products.selectedProduct);
    const [createSchedule, {error, isError}] = useCreateScheduleMutation();    
    const [updateScheduledItem] = useInsertUpdateScheduleItemMutation();
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const [isLoading, setIsLoading] = useState(false);    
    const {data:balances} = useGetSubscriptionBalancesQuery();

    const [currentViewingDate, setCurrentViewingDate] = useState(new Date());
    const [itemValid, setitemValid] = useState(true);
    const [itemValidMessage, setitemValidMessage] = useState("");

    const [scheduleRequestData, setScheduleRequestData] = useState({
        productId:selectedProduct?.value,
        referenceDate:ToISODateWithTimeZone(currentViewingDate),               
        nowDate:ToISODateWithTimeZone(now),
        //this makes sure the passed parameter is never cached
        paramTrigger: Date.now()         
    })

    const {data: weeks, isFetching: isLoadingSchedule} = useGetScheduleQuery(scheduleRequestData, {
        skip: (scheduleRequestData.productId === null || scheduleRequestData.productId === undefined)
    })    

    const {data:linkedSocialsData} = useSocial_GetIsLinkedByProductQuery(selectedProduct?.value, {
        skip: (selectedProduct === null || selectedProduct === undefined)
    })              

    const [updatedItems, setUpdatedItems] = useState([])    

    useEffect(() => {        
        if(weeks){
            setUpdatedItems(weeks);         
            ValidateItems(weeks);
        }        

        if(selectedProduct){            
            setScheduleRequestData({
                ...scheduleRequestData,
                productId: selectedProduct.value
            })
        }
    }, [weeks, selectedProduct])

    const failureCount = () => {
        let failureCount = 0

        weeks?.forEach(item => {
            if(item.state === 3){
                failureCount++;
            }
        });

        return failureCount;
    }

    async function SaveSchedule(){
        setIsLoading(true)

        const data = {
            "product":selectedProduct.value,
            "scheduled_items":updatedItems
        }
        
        try{
            await createSchedule(data);                    
        }
        catch(err){
            console.log(err);
        }        

        setIsLoading(false)
    }

  

    function HandleClick(){
        navigate('/Subscription')
    }    

    function SetWeek(date)
    {
        if(isLoadingSchedule){
            return;
        }

        setCurrentViewingDate(date);
        setScheduleRequestData({
            ...scheduleRequestData,
            referenceDate: ToISODateWithTimeZone(date),
            paramTrigger: Date.now()
        })
    }

    function SetNextWeek(){       

        let nextWeekDate = new Date(currentViewingDate.getFullYear(), currentViewingDate.getMonth(), currentViewingDate.getDate());
        nextWeekDate.setDate(nextWeekDate.getDate() + 7);
        SetWeek(nextWeekDate);
    }

    function SetPreviousWeek(){
       
        let lastWeekDate = new Date(currentViewingDate.getFullYear(), currentViewingDate.getMonth(), currentViewingDate.getDate());
        lastWeekDate.setDate(lastWeekDate.getDate() - 7);
        SetWeek(lastWeekDate);
    }    
    
    function GetSocialName(scheduleItemSocial)
    {
        switch (scheduleItemSocial.socialType)
        {
            case 1: return "X (Twitter)";
            case 2: return "YouTube";
            case 3: return "TikTok";
            case 4: return "Instagram";
        }
    }
    const ScheduleItemChanged = (item) => {
        let newUpdatedItems = [...updatedItems];

        if(newUpdatedItems.length === 0){
            newUpdatedItems = [...weeks];
        }        

        newUpdatedItems?.map((x, index) => {
            if(item.id === x.id){                
                newUpdatedItems[index] = item
            }            
        })

        ValidateItems(newUpdatedItems);     
        setUpdatedItems(newUpdatedItems);
    }    

    const ValidateItems = (items) => {        

        let result = true;

        for(let item of items){            
            for(let social of item.scheduleItemSocials){                
                let {valid, message} = DoValidation(true, item, social);
                if(!valid){                    
                    setitemValid(valid);
                    setitemValidMessage(message);                                    
                    return;
                }                
                
            };                               
        }

        if(result){
            setitemValid(true);
            setitemValidMessage("");
        }        
    }

    const DoValidation = (isWeek, scheduleItem, scheduleItemSocial) => {  
        var result = {
            valid: false,
            message: ''
        }

        if (scheduleItem && scheduleItemSocial)
        {      
            let prefix = undefined;
                 
            if (isWeek)
            {
                let date = new Date(scheduleItem.date);
                let day = days[date.getDay()];
             
                prefix = `[${day} : ${GetSocialName(scheduleItemSocial)} -`;
            }
            else
                prefix = `[${GetSocialName(scheduleItemSocial)} -`

            if (scheduleItemSocial.enabled && (scheduleItemSocial.state === -1 || scheduleItemSocial.state === 0))
            {
                if(scheduleItemSocial.caption !== undefined) {
                    if(scheduleItemSocial.caption.length <= 0) {
                        result.valid = false;
                        result.message = `${prefix} Caption cannot be empty]`;                
                        return result;
                    }
                }

                if(scheduleItemSocial.properties.title !== undefined) {
                    if(scheduleItemSocial.properties.title.length <= 0) {
                        result.valid = false;
                        result.message = `${prefix} Title cannot be empty]`;                
                        return result;
                    }
                }

                if(scheduleItemSocial.properties.video_cover_timestamp_ms) {
                    if(isNaN(scheduleItemSocial.properties.video_cover_timestamp_ms) || scheduleItemSocial.properties.video_cover_timestamp_ms < 0){
                        result.valid = false;
                        result.message = `${prefix} Invalid Video Cover Timestamp]`;                            
                        return result;
                    }
                }        

                if(scheduleItemSocial.properties.disclosure){
                    if(!scheduleItemSocial.properties.branded_content && !scheduleItemSocial.properties.brand_organic){
                        scheduleItemSocial.valid = false;
                        result.message = `${prefix} 'Branded Content' or 'Your Brand' must be enabled]`;                            
                        return result;
                    }            
                }             
                
                if(scheduleItemSocial.socialType === 3){
                    if(scheduleItemSocial.properties['privacy_level'] === '' || scheduleItemSocial.properties['privacy_level'] === 'Select'){
                        result.valid = false;
                        result.message = `${prefix} Post Privacy Must Be Selected]`;                            
                        return result;
                    }
                }
            }
        }

        result.valid = true;
        result.message = "";        
        return result;
    }

    const setInvalid = (message) => {
        setitemValid(false);
        setitemValidMessage(message);   
    }

    const SetNewClipOnPlaceholderOrDeleted = async (item) => {
        setIsLoading(true);
        await updateScheduledItem(item);        
        setIsLoading(false);
    }

    var renderedList = weeks?.map(item=>
        <div key={item.id} className="col-lg-12 col-xl-6 col-md-12 col-12 ptb-15" >
            <ScheduleItemWrapper                 
                {...props}                
                updateItem={ScheduleItemChanged}
                setClipOnPlaceholderOrDeleted={SetNewClipOnPlaceholderOrDeleted}
                banner={cover5}                
                item={item}  
                doValidation={DoValidation}                   
            />
        </div>
    );

    const isAnythingLoading = () => {
        return isLoading || isLoadingSchedule
    }

    const hasLinkedSocial = () => {                
        if(linkedSocialsData){
            return (linkedSocialsData.instagram || linkedSocialsData.twitter || linkedSocialsData.youtube || linkedSocialsData.tiktok)
        }
        else{
            return false;
        }        
    }

    const onWeekPickChange = (week) => {
        SetWeek(week.firstDay);        
      };

    return (
        <div>
        {isAnythingLoading() ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                <LoaderComponent loading={isAnythingLoading()} />
            </div>
        ) : (
            <>
                {selectedProduct != null ? (   
                    <>
                        { !isLoadingSchedule &&                                                        
                                <div >
                                    <div className="row center">
                                        <div className="col-sm-6 center" id="socials">                                    
                                                {hasLinkedSocial() ? 
                                                    (selectedProduct != null) ? 
                                                        (balances.token_balance > 0) ?
                                                        itemValid ? <Button onClick={SaveSchedule}>Save Schedule</Button> : <div className="center text-warning"> Please fix the following to save your schedule - {itemValidMessage}</div>
                                                        : 
                                                            <Button onClick={HandleClick}>Purchase Tokens To Save Your Schedule</Button>
                                                    : <div className="fs-20 bold-text widget-dark-color mtb-10 text-red-500">Please select a product to save your schedule!</div>
                                                : 
                                                    <div className="fs-20 bold-text widget-dark-color mtb-10 text-red-500">Please link a social to this product to save your schedule!</div>
                                                }                                           
                                        </div>  
                                    </div>
                                    <br></br>
                                    <div className="row center">                            
                                        <div onClick={SetPreviousWeek} className="col-sm-3 cursor-pointer fs-20 bold-text widget-dark-color center">
                                            <i className="fas fa-chevron-left pr-15"></i>
                                            Previous Week
                                        </div>                                                                    
                                        <div className="col-sm-6 center">
                                            <WeekPicker startDate={currentViewingDate} onChange={onWeekPickChange} />
                                        </div>                                         
                                        <div onClick={SetNextWeek} className="col-sm-3 cursor-pointer fs-20 bold-text widget-dark-color center">
                                            Next Week
                                            <i className="fas fa-chevron-right pl-15"></i>
                                        </div>                                                    
                                    </div>                                                                
                                </div>
                        }
                        {
                            isError && 
                            <div className="center text-danger">
                                Failed to save schedule: [{error.data}]
                            </div>
                        }
                        {
                            failureCount() > 0 && 
                            <div className="center text-warning">
                                {failureCount()} Item(s) failed to post - please check your items below
                            </div>
                        }
                        <div className="row" >
                            {renderedList}
                        </div>
                        
                    </>
                ) : 
                (
                    <CenterChildrenWrapper>
                        <div className="cursor-pointer fs-20 bold-text widget-dark-color mtb-10">Please select a product!</div>
                    </CenterChildrenWrapper>
                )}
            </>
        )}
        </div>
    );
};

export default WeekSchedule;