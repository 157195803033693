import React, { useReducer, useState } from "react";
import { connect, useSelector } from "react-redux";
import GridView from "components/filemanager/gridview/GridView";
import FileManagerWrapper from "components/filemanager/filemanager.style";
import PageTitle from "components/common/PageTitle";
import { initialState, reducer } from "components/filemanager/reducer";
import {useDeleteMutation, useDeleteUnusedMutation, useGetByProductAsFileStructureQuery } from "features/clips/clipsApiSlice";
import { LinearProgressWidget } from "components/widgets/statisticswidgets";
import SweetAlert from "react-bootstrap-sweetalert";
import ProductList from 'features/products/ProductList';
import { Spinner } from "reactstrap";

const FileManager = props => {
  const [warningAlert, setWarningAlert] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState);  
  
  const [deleteClips, {isLoading: isDeleting}] = useDeleteMutation([])
  const [deleteUnusedClips, {isLoading: isDeletingUnused}] = useDeleteUnusedMutation()

  let selectedProduct = useSelector((state) => state.products.selectedProduct)
  const {data, isFetching} = useGetByProductAsFileStructureQuery(selectedProduct?.value)
  
  const {
    isViewFolder,
    currentFolder,
  } = state;

  const openFileORFolder = (data, type) => {
    if (type === "folder") {
      dispatch({
        data: {
          isViewFolder: true,
          currentFolder: data
        }
      });
    } else {
      dispatch({
        data: {
          openMediaDialog: true,
          currentMedia: data
        }
      });
    }
  };


  const deleteFolder = data => {
    dispatch({
      type: "deleteFolder",
      id: data.id
    });
  };

  const deleteFile = async data => {
    deleteClips([data.id])
    dispatch({
      type: "deleteFile",
      id: data.id
    });
  };

  const deleteUnused = async () => {
    await deleteUnusedClips(selectedProduct.value)    
  }

  const openFolderDialog = data => {
    dispatch({
      data: {
        moveToFolderDialog: true,
        moveToFolderMedia: data.id
      }
    });
  };

  const moveFiletoRoot = media => {
    dispatch({
      type: "moveMediaToFolder",
      mediaId: media.id,
      folderId: null
    });
  };

  const openRenameFolderModal = folder => {
    dispatch({
      type: "openRenameFolderModal",
      folder: folder
    });
  };

  return (
    <FileManagerWrapper {...props}>      
      <div className="pt-10">        
        <div className="pb-10 pr-10 mtb-30 theme-color center">
          <ProductList />
        </div>         
        { (isFetching || isDeleting || isDeletingUnused)  ? 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
              <Spinner color="primary" animation="border" role="status"/> 
            </div>
          :
            <div>
              <div className="pb-10 pr-10">
                <LinearProgressWidget
                    dark
                    background={props.layoutTheme.cardBackground}  
                    headline="Storage"
                    subheader="Storage Use"
                    progress={data?.usage_percentage}
                    progressColor="#FA7252"
                    info={"Storage Used: " + data?.usage + "Mb"}
                />
              </div>          
              <div className="pb-10">
              { data?.medias && data?.medias.length > 0 ?
                <div
                style={{cursor: "pointer"}}
                className="roy-menu-list center"
                onClick={() => setWarningAlert(true)}>
                  <div className="mtb-30 theme-color">
                    <i className="far fa-trash-alt text-red-500" style={{color: "red"}}></i> Delete ALL Unused Clips 
                  </div>            
                  </div> : null}
              </div>      
              <div> 
                {!isFetching &&    
                <GridView
                  medias={data?.medias}
                  folders={data?.folders}
                  isViewFolder={isViewFolder}
                  currentFolder={currentFolder}
                  openFileORFolder={openFileORFolder}
                  deleteFolder={deleteFolder}
                  deleteFile={deleteFile}
                  moveToFolder={openFolderDialog}
                  moveFiletoRoot={moveFiletoRoot}
                  openRenameFolderModal={openRenameFolderModal}
                />
                }
              </div>
            </div>   
        }     
      </div>
      <SweetAlert
        warning
        showCancel
        show={warningAlert}
        confirmBtnText="Yes, delete them!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => {
            setWarningAlert(false);
            deleteUnused();
        }}
        onCancel={() =>
            setWarningAlert(!warningAlert)
        }
      >
        You will not be able to recover these clips!
      </SweetAlert>
    </FileManagerWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps)(FileManager);
