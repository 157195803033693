import {apiSlice} from '../../app/api/apiSlice'

export const clipsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAsFileStructure: builder.mutation({
            query: () => ({
                url: '/clips/get_as_file_structure',
                method: 'GET'                
            })
        }),      
        getByProductAsFileStructure: builder.query({
            query: (product_id) => ({
                url: `/clips/get_by_product_as_file_structure?product_id=${product_id}`,
                method: 'GET'                
            }),
            providesTags: ['Clips']
        }),  
        getByProduct: builder.query({
            query: (product_id) => ({
                url: `/clips/get_by_product?product_id=${product_id}`,
                method: 'GET',                
            }),
            providesTags: ['Clips']
        }),      
        delete: builder.mutation({
            query: (clip_ids) => ({
                url: '/clips/delete/',
                method: 'POST',
                body: {
                    "clip_ids": clip_ids
                }

            }),
            invalidatesTags: ['Clips']
        }),  
        deleteUnused: builder.mutation({
            query: (product_id) => ({
                url: '/clips/delete_unused/',
                method: 'POST',
                body: {
                    "product_id":product_id
                }  
            }),
            invalidatesTags: ['Clips']
        })     
    })
})


export const {
    useGetAsFileStructureMutation,    
    useGetByProductAsFileStructureQuery,
    useDeleteMutation,
    useGetByProductQuery,
    useDeleteUnusedMutation,
} = clipsApiSlice