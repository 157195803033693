import {React, useState, useRef, useEffect} from "react";
import { useGenerateUrlMutation, useAddFullVideoMutation, useIsVideoProcessingMutation, useGetVideoCostMutation } from "./fileUploadAPISlice";
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios, { CanceledError } from "axios"
import UploadResult from "components/upload/UploadResult";
import UploadProgress from "components/upload/UploadProgress";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import RoyTooltip from "components/common/RoyTooltip";


const FileUpload = ({ className }) => {    
    const navigate = useNavigate();

    const durationOptions = [
      { value: 0, label: "Preferred clip duration", disabled: true},
      { value: 10, label: '10 seconds' },
      { value: 30, label: '30 seconds' },
      { value: 50, label: '50 seconds' }
    ]

    const [file, setFile] = useState();
    const [modal, setModal] = useState(false);
    const [uploadStatus, setUploadStatus] = useState("");
    const [uploadResult, setUploadResult] = useState(null);
    const [videoLength, setVideoLength] = useState(0);
    const [videoCost, setVideoCost] = useState();
    const [hasCredit, setHasCredit] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState();    
    const abortController = useRef(new AbortController());    
    const [canUpload, setCanUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isGettingTokenCost, setIsGettingTokenCost] = useState(false);
    const timestampRef = useRef(Date.now()).current;
    const selectedProduct = useSelector((state) => state.products.selectedProduct)
    const [preferredDuration, setPreferredDuration] = useState(durationOptions[2])

    const [generateUrl] = useGenerateUrlMutation(); 
    const [addFullVideo] = useAddFullVideoMutation();    
    const [videoProcessing] = useIsVideoProcessingMutation();    
    const [getVideoCost] = useGetVideoCostMutation();    
 
    useEffect(() => {
      FetchData()     
    }, [selectedProduct])

    const FetchData = async () => {    
      setIsLoading(true)  
      
      setFile(null)
      setVideoLength(0);    
      setVideoCost(null);
      setCanUpload(false);
      
      if(selectedProduct != null && selectedProduct.value !== 0)
      {
        const result = await videoProcessing(selectedProduct.value);      
        if(result.data !== undefined)
        {        
          setCanUpload(!result.data.result)          
        }                  
      }     
      setIsLoading(false)  
    }    
   
    async function acceptFiles(acceptedFiles) {
      if (acceptedFiles.target.files.length > 0)
      {
        setFile(acceptedFiles.target.files[0]);
        setIsGettingTokenCost(true);
        const length = await getVideoLength(acceptedFiles.target.files[0]);
        setVideoLength(length);
        updateVideoCost(length, preferredDuration.value);
      }
      else
      {
        setFile(null)
        setVideoLength(0);    
        setVideoCost(null);
      }
    }

    async function updateVideoCost(length, clipInterval)
    {
      setHasCredit(false);

      if (length && length > 0 && clipInterval && clipInterval > 0)
      {
        setIsGettingTokenCost(true);
        try {
          const result = await getVideoCost({"videoLength": length, "clipInterval": clipInterval});
      
          if (result.data) {
            setVideoCost(result.data["tokenCost"]);
            setHasCredit(result.data["hasCredit"]);
          }
          else
          {
            setVideoCost("?");
            setHasCredit(false);
          }
        } catch (error) {
          console.error("Error processing video:", error);
        } finally {
          setIsGettingTokenCost(false);
        }
      }
      else
      {
        setVideoCost(null);
      }
    }
    
    function getVideoLength(file) {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
    
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          resolve(video.duration);
        };
    
        video.onerror = reject;
    
        video.src = URL.createObjectURL(file);
      });
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.code === "ERR_CANCELED") {
          // aborted in useEffect cleanup
          return Promise.resolve({status: 499})
        }
        return Promise.reject((error.response && error.response.data) || 'Error')    
      }
    )

    const UploadFile = async () => {     
        setUploadResult(null);
        setUploadStatus("");         
        setModal(true);
        setUploadPercentage(1);
              
        const param = {"file_name" : file.name, "product_id": selectedProduct.value};
        const result = await generateUrl(param);                

        let formData = new FormData();
        const params = {...result.data.url.fields, 'file': file};
        
        for ( var key in params ) {
          formData.append(key, params[key]);
        }                

        const controller = new AbortController();        
        abortController.current = controller;
        const bucketkey = result.data.url.fields['key'];      

        try {                        
            const config = {
              onUploadProgress: progressEvent => {Uploading(progressEvent);},
              signal: controller.signal,
            }

            controller.signal.onCanceled = () => {
              console.log("cancelled");
            }

            axios.post(result.data.url.url, formData, config).then(function(response) {
              // Check if the response code is in the range of 200-299
              if (response.status >= 200 && response.status < 300) {
                const productId = selectedProduct.value;
                setUploadStatus("Preparing your upload for processing...");
                AddFullVideo(file.name, productId);                
              } else {
                // Handle errors or unsuccessful responses
                console.error('Request failed with status code:', response.status);
              }
            });                
        } 
        catch (error) {
          if (axios.isCancel(error)) {
            // aborted in useEffect cleanup
            console.log("Canceled");
          }
          else if(error instanceof CanceledError){
            console.log("Operation canceled")
          }
          else{
            console.error('Error during file upload:', error.message);              
          }
          // if(axios.isCancel(error)){
          //   console.log('Upload canceled', error.message)
          // }
          // else{
           
          //}          
        }
    }; 

    const AddFullVideo = async (fileName, product) => {
      try{      
        var details = {
          'path': fileName,
          'product': product,
          'targetClipDuration': preferredDuration.value,
          'length': videoLength                 
        }

        var result = await addFullVideo(details);

        if (result?.error)
        {
            var error = result.error.data;
            error = `Video upload failed - ${error}`
            setUploadResult(false);
            setUploadStatus(error);            
        }
        else 
        {          
          setUploadResult(true);
          setUploadStatus("We've received your video. We'll send you an email when your schedule is ready!");
          setCanUpload(false);
        }
      }
      catch(err){
        console.log('Failed to add full video' + err.message);
      }
        
    }
        
    const Uploading = (progressEvent) => {
      
      try{
        var progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        if(progress <= 1){
          setUploadPercentage(1)
        }
        else{
          setUploadPercentage(progress);
        }        
        if(progress < 100){
          setModal(true);
        }              
      }
      catch(error){
        console.log("uploading modal error");
      }
      
    }

    const handleCancel = () => {
      try{
        abortController.current.abort();
        setModal(false);
      }
      catch(error){
        console.log(error);
      }      
    }

    const handleOkay = () => {
      setModal(false);
    }

    const handleDurationSelection = (durationOption) => {
      setPreferredDuration(durationOption);
      updateVideoCost(videoLength, durationOption.value);
    }

    
  
    return (       
      <div style={{display:"flex"}}>
        { 
          isLoading ?
            <div>
              <Spinner color="primary" animation="border" role="status"/> 
            </div>
          :
            canUpload ?
              <Form>                
                  <div>
                  <FormGroup style={{display:"flex"}}>   
                      <div>
                        <Input placeholder="Select file to upload" type="file" onChange={acceptFiles} name="file" accept=".mp4,.mov" />
                        <FormText color="muted">
                            Select a file to upload!
                        </FormText>
                      </div>                              
                      <div className="pl-10">
                        <Select                    
                                placeholder="Preferred clip duration"
                                onChange={handleDurationSelection}
                                options={durationOptions}             
                                defaultValue={preferredDuration}   
                                isOptionDisabled={(option) => option.disabled}
                          />                        
                      </div>  
                      {isGettingTokenCost ?
                        <div className="pl-10">
                          <Spinner color="primary" animation="border" role="status"/> 
                        </div>
                       : videoCost &&
                          <div className="pl-10 pt-2">                                                        
                                <div title={(hasCredit) ? "Tokens to process this video" : "You have insufficent tokens to process this video"} id="tokensToProcess" onClick={() => navigate('/subscription')} style={{color:(hasCredit) ? "gold" : "red"}} className="cursor-pointer">
                                  <div className="fas fa-coins" title=""></div>&nbsp;{videoCost}
                                </div>                                                                                         
                          </div>                         
                      }                      
                      <div className="pl-10">                
                      <Button disabled={!file || !hasCredit} onClick={UploadFile}>
                        Upload                
                      </Button>                              
                    </div>
                  </FormGroup> 
                </div>  
              </Form>      
            :  
              <Label style={{display:'flex', justifyContent: 'left', alignItems:'center'}}>
                  {selectedProduct != null && selectedProduct.value !== 0 ? "We're processing your last uploaded video! We'll send you an email as soon as your schedule is ready." : ""}
              </Label>
        }
        
      <Modal
          isOpen={modal}
          toggle={() => setModal(!modal)}
          className={className}          
      >
          <ModalHeader style={{display:'flex', justifyContent: 'center', alignItems:'center'}} toggle={() => setModal(!modal)}>
              {uploadResult === null ?
                 "Uploading Your Video..."
               :
                 uploadResult === true ?
                   "Upload Complete"
                 :
                   "Upload failed"
              }
          </ModalHeader>
          <ModalBody style={{display:'flex', justifyContent: 'center', alignItems:'center'}}>
            <div>
                <UploadProgress 
                  uploadPercentage={uploadPercentage}
                  uploadStatus={uploadStatus}
                  uploadResult={uploadResult}                  
                />
            </div>
          </ModalBody>
          <ModalFooter>             
            <UploadResult 
              uploadPercentage={uploadPercentage}
              uploadResult={uploadResult}
              onCancel={handleCancel}
              onOkay={handleOkay}
            />
          </ModalFooter>
        </Modal>
      </div>                         
    );
};

export default FileUpload;