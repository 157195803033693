import themeChanger from './themeChanger/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import themeSetting from './themeSettings/reducer';
import scrumboard from './scrumboard/reducer';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { apiSlice } from '../app/api/apiSlice';
import authenticationReducer from '../features/authentication/authenticationSlice'
import scheduleReducer from '../features/schedule/scheduleSlice'
import fileUploadReducer from '../features/fileUpload/fileUploadSlice'
import socialsSliceReducer from '../features/socials/socialsSlice' 
import socialToLinkSliceReducer from '../features/socials/socialToLinkSlice' 
import subscriptionSliceReducer from 'features/subscription/subscriptionSlice';
import productsSliceReducer from 'features/products/productsSlice';
import paymentSliceReducer from 'features/subscription/paymentSlice';
import scheduleAdminSliceReducer from 'features/schedule/scheduleAdminSlice';

const createReducer = (asyncReducers) =>
    combineReducers({
        themeChanger,
        LanguageSwitcher,
        themeSetting,
        scrumboard,
        router: routerReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authenticationReducer,
        schedule: scheduleReducer,
        fileUpload: fileUploadReducer,        
        socials: socialsSliceReducer,
        socialToLink : socialToLinkSliceReducer,
        subscription: subscriptionSliceReducer,
        products: productsSliceReducer,
        payment: paymentSliceReducer,
        scheduleAdmin: scheduleAdminSliceReducer,        
        ...asyncReducers
    });

export default createReducer;
