import {React, useEffect, useState} from "react";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"; //npm install @paypal/react-paypal-js
import { selectCurrentToken, selectCurrentUser } from '../authentication/authenticationSlice'
import { useSelector, useDispatch, } from "react-redux";
import {setPaymentStage, setPaymentError, setAmount} from "./paymentSlice";
import { setTokenBalance, setSubscription } from "./subscriptionSlice";
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, Spinner } from "reactstrap";
import { useGetSubscriptionMutation  } from "./subscriptionAPISlice"; 

const PaymentPayPalModal = () => {

    const dispatch = useDispatch();
    const apiToken = useSelector(selectCurrentToken); 
    const apiUser = useSelector(selectCurrentUser); 
    const [isBusy, setIsBusy] = useState(false) 
    const paymentError = useSelector((state) => state.payment.paymentError) 
    const orderAmount = useSelector((state) => state.payment.amount) 
    const paymentStage = useSelector((state) => state.payment.paymentStage)  
    const subscriptionType = useSelector((state) => state.payment.subscriptionType) 
    const webApiUrl = process.env.REACT_APP_WEBAPI_URL
    const [getSubscription] = useGetSubscriptionMutation() 
    

    async function refreshSubscription()
    {
        const result = await getSubscription();    
        dispatch(setSubscription(result));    
    }
    
    async function onCreateOrder(data, actions) 
    {      
        console.info(`OnCreateOrder`)
        var orderUrl = `${webApiUrl}user_subscription/prepaid_order_create/`;
        const token = apiToken;
        const totalPurchaseValue = orderAmount;

        if (totalPurchaseValue > 0)
        {         
            const response = await fetch(orderUrl, 
                {
                    method : "POST",
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({"totalPurchaseValue": totalPurchaseValue})
                }
            )
            
            if (response.ok)
            {
                const contentType = response.headers.get("content-type");
                if (!contentType || !contentType.includes("application/json")) 
                {
                    dispatch(setPaymentError("Order creation failed - invalid server response!"))                           
                }
                const data = await response.json();
                        
                dispatch(setPaymentError(""))  
             
                console.info(`OnCreateOrder - return orderId ${data.orderId}`)
                return data.orderId;
            }
            else
            {
                setIsBusy(false)      
                console.error(`OnCreateOrder - order creation failed ${response.statusText}`)  
                dispatch(setPaymentError(`Order creation failed - ${response.statusText}`))          
            }
        }
        else
        {
            console.error(`OnCreateOrder - transaction value is invalid!`)  
            dispatch(setPaymentError("Order creation failed - transaction value is invalid!"))
        }       
    }

    async function onApproveOrder(data, actions) 
    {       
        console.info(`OnApproveOrder`)
        setIsBusy(true)

        var orderId = data.orderID;        
        const token = apiToken

        var captureUrl = `${webApiUrl}user_subscription/prepaid_order_capture/`       
        
        const response = await fetch(captureUrl, 
            {
                method : "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({"orderId": orderId})
            }
        )
     
        if (response.ok)
        {
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) 
            {
                dispatch(setPaymentError("Order capture failed - invalid server response!"))
            }
            const data = await response.json();

            refreshSubscription()

            dispatch(setPaymentError(""))  
            dispatch(setPaymentStage(3));
            dispatch(setTokenBalance(data.balance))
            setIsBusy(false)

            console.info(`OnApproveOrder - complete`)

        }
        else 
        {            
            const data = await response.json();

            console.error(`OnCreateOrder - purchase capture failed post PayPal order capture`)  
            dispatch(setPaymentError(`Oops - something went wrong with your purchase [Order=${orderId}]. Please contact support!`));   
            
            setIsBusy(false)   
        }        
    }

    async function onShippingChange(data, actions)
    {
        //This handler is attached only as a hack workaround to force credit card payment button to open popup window rather than try load in form as this doesn't work 50%+ of the time
        return actions.resolve();
    }

    async function onCreateSubscription(data, actions) 
    {
        console.info(`OnCreateSubscription`)
        var orderUrl = `${webApiUrl}user_subscription/get_plan_id/`;
        const token = apiToken;
        
        if (subscriptionType > 1)
        {
            const response = await fetch(orderUrl, 
                {
                    method : "POST",
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({"subscriptionType": subscriptionType})
                }
            )
           
            if (response.ok)
            {
                const contentType = response.headers.get("content-type");
                if (!contentType || !contentType.includes("application/json")) 
                {
                    throw new Error("Get plan type - invalid server response!");                            
                }
                const data = await response.json();
                        
                dispatch(setPaymentError(""))  

                //regardless of new recurring subscription, upgrade or downgrade, we create a new order for the plan. On success, 
                //we delete any existing recurring subscription via the API and carry over the balances
                console.info(`OnCreateSubscription - return planId ${data.planId})`)

                var customId = `${apiUser.id}_${Date.now()}`
                
                return actions.subscription.create(
                    {
                        "plan_id": data.planId, 
                        "custom_id":customId,
                        "application_context": {
                            "shipping_preference": "NO_SHIPPING"   
                        },      
                    }
                );                
            }
            else
            {
                console.error(`onCreateSubscription - failed - ${response.statusText}`)  
                dispatch(setPaymentError(`Subscription creation failed - ${response.statusText}`))           
            }
        }
        else
        {
            console.error(`onCreateSubscription - failed - subscription type is invalid!`) 
            dispatch(setPaymentError("Subscription creation failed - subscription type is invalid!"))
        }       
    }


    async function onApproveSubscription(data, actions) 
    {       
        console.info(`OnApproveSubscription`)
        setIsBusy(true)

        var orderId = data.orderID;    
        var subscriptionId = data.subscriptionID;    
        const token = apiToken

        var captureUrl = `${webApiUrl}user_subscription/subscription_order_capture/`       
        
        const response = await fetch(captureUrl, 
            {
                method : "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({"orderId": orderId, "type":subscriptionType, "subscriptionID":subscriptionId})
            }
        )
     
        if (response.ok)
        {
            const contentType = response.headers.get("content-type");   
            if (!contentType || !contentType.includes("application/json")) 
            {
                dispatch(setPaymentError("Subscription Order capture failed - invalid server response!"));        
            }
            const data = await response.json();
            dispatch(setPaymentError(""))  

            //update subscription object on completion
            refreshSubscription()
            
            dispatch(setPaymentStage(3));
            setIsBusy(false)

            console.info(`onApproveSubscription - complete`) 

        }
        else if (response.status === 400)
        {
            //const data = await response.json();

            setIsBusy(false)

            //an uncaptured transaction occurred!
            console.error(`Subscription order succeeded but purchase capture failed - please contact support!`) 
            dispatch(setPaymentError(`Subscription order succeeded but purchase capture failed - please contact support!`));   
        }
        else
        {            
            setIsBusy(false)
            console.error(`Subscription order capture failed - ${response.statusText}`) 
            dispatch(setPaymentError(`Subscription order capture failed - ${response.statusText}`));          
        }        
    }

    function onError(error) 
    {
        if (error.message !== "Detected popup close")
            dispatch(setPaymentError(error.message));
        console.error(`${error.message}`)
    }

    function onCancel() 
    {        
        //dispatch(setPaymentError("Payment was cancelled"));
        console.info("Cancelled!")
    }

    function onInit() 
    {
        console.info("Init!")
    }

    function PayPalLoader()
    {
        const [{ isInitial, isPending, isResolved, isRejected }, dispatch] = usePayPalScriptReducer();

        return (
            isInitial || isPending ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner color="primary" animation="border" role="status"/>                
                </div>
            :
            <div>
                {paymentError !== '' ? 
                    <div>
                    <div className="row">
                        <div className="error-msg" style={{overflowWrap: "break-word",  justifyContent: 'left', alignItems: 'left' }}>
                            {paymentError}             
                        </div>
                    </div>
                    <br/>
                    </div>
                    :
                    null
                }                                    
                <div className="row">  
                    {isBusy ?
                        <div>
                        <div className="row">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner color="primary" animation="border" role="status"/>                
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                Completing transaction. One moment...                         
                            </div>
                        </div>
                        </div>
                    :                    
                    subscriptionType === 1 ?              
                        <PayPalButtons createOrder={onCreateOrder} onInit={onInit} onCancel={onCancel} onApprove={onApproveOrder} onError={onError} //onShippingAddressChange={onShippingChange}
                            style={{"layout": "vertical", "color":"white", "label":"pay", "shape":"pill", "tagline":false}}/>                                       
                        :
                        <PayPalButtons createSubscription={onCreateSubscription} onInit={onInit} onCancel={onCancel} onApprove={onApproveSubscription} onError={onError}
                            style={{"layout": "vertical", "color":"white", "label":"pay", "shape":"pill", "tagline":false}}/>      
                    }                                                           
                </div>
            </div>
        )
    }

    function PayPalPrepaid()
    {
        return (
            <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, 
                        currency: "USD", intent: "capture", components: 'buttons'}}>
                <PayPalLoader/>   
            </PayPalScriptProvider>
        );
    }

    function PayPalSubscription()
    {
        return (
            <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, 
                        currency: "USD", vault: true, intent: "subscription", components: 'buttons'}}>                
                <PayPalLoader/>   
            </PayPalScriptProvider>
        );
    }

    function CloseModal()
    {
        dispatch(setPaymentError(""))
        dispatch(setPaymentStage(0))
        dispatch(setAmount(0))
        setIsBusy(false)
    }

    return (
        <div>
        <Modal size='lg' isOpen={paymentStage===2} centered={true}>                   
            <Form> 
                <ModalHeader>
                    {subscriptionType === 1 ?
                            <>Purchase Autigma Credit - {orderAmount} USD - select payment method</>                                                                                            
                        :
                            <>Purchase Autigma Subscription - {orderAmount} USD monthly - select payment method</>                                                                                                                }
                </ModalHeader>
                <ModalBody>
                    {subscriptionType === 1 ?
                        <PayPalPrepaid/>  
                    :
                        <PayPalSubscription/>
                    }                                                                                            
                </ModalBody>
                <ModalFooter>    
                    <Button disabled={isBusy} onClick={()=>dispatch(setPaymentStage(1))}>Back</Button>
                    <Button disabled={isBusy} onClick={()=>CloseModal()}>Cancel</Button>
                </ModalFooter>                                       
            </Form>                                            
        </Modal>                    
        </div>
    );
};

export default PaymentPayPalModal;