import styled from "styled-components";

export const SchedulWrapper = styled.div`
    .top-header-profile-class {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      pt:10;
      pl:10;
      pr:10;
    }
  }
`;

export const ProductFileWrapper = styled.div`
  display: flex;
  justify-content: center;
  > * {
    padding: 0 15px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    > * {
      padding: 15px 0;
    }
  }
`

export const CenteredChildrenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
`