import React from "react";
import { Tweet } from "react-tweet";

const TopPosts = (props) => {

    const {this_week, last_week} = props

    const getColSize = () => {
        if(this_week && last_week){
            return "col-lg-6 col-sm-12"
        }
        else{
            return "col-6"
        }
    }

    return (
        <div>
            <div className="row pt-10">
                {
                    last_week &&
                    <div className={getColSize() + " center"}>                  
                        <h4 className="fs-20 header bold-text widget-dark-color pt-10">Top Post Last Week</h4>           
                    </div>
                }
                {
                    this_week &&
                    <div className={getColSize() + " center"}>                  
                        <h4 className="fs-20 header bold-text widget-dark-color pt-10">Top Post This Week</h4>           
                    </div>
                }
            </div>
            <div className="row">               
                
                {
                    last_week && 
                    <div className={getColSize() + " center"}>
                    <Tweet id={last_week} />                    
                    </div>
                }
                
                
                {
                    this_week && 
                    <div className={getColSize()}>                  
                    <Tweet id={this_week} />                    
                    </div>
                }
                
            </div>
        </div>
    )
}

export default TopPosts