import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data: [],
    hasLinkedSocials: false
}

const socialsSlice = createSlice({
    name:'socials',
    initialState,
    reducers: { 
        setSocials: (state, action) => {            
            return {
                ...state,
                data: action.payload
            }
        }, 
        setHasLinkedSocials: (state, action) => {
            return {
                ...state,
                hasLinkedSocials: action.payload
            }
        },
        resetSocials: () => {
            return initialState
        }
    }
})

export const {setSocials, setHasLinkedSocials, resetSocials} = socialsSlice.actions
export default socialsSlice.reducer
export const selectSocials = state => state.socials.data
