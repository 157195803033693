import React from 'react';
import Gauge from 'react-liquid-gauge';
import { Spinner } from "reactstrap";

const UploadProgress = ({ uploadPercentage, uploadResult, uploadStatus }) => {
  return (
    <div>
      {uploadPercentage < 100 ? 
          <Gauge 
          value={uploadPercentage} 
          width={150} 
          height={140}
          />
        :
          uploadResult === null ?
            <div>
              <Spinner color="primary" animation="border" role="status"/>&nbsp;&nbsp;{uploadStatus} 
            </div>                     
          :
            <div>{uploadStatus}</div>         
      }
    </div>
  );
};

export default UploadProgress;