import { Spinner } from "reactstrap";

import {React, useState, useRef, useEffect} from "react";
import {Formik, Form, useField, useFormikContext} from "formik"
import {Product} from "types"
import CreatableSelect from 'react-select/creatable';
import {components, InputProps} from 'react-select';

const CaptionThemeEditor = ({ product, label, ...props}) => {
    
    const [field, meta] = useField(props);     
    const {setFieldValue} = useFormikContext();

    var [productCaptionTones, setProductCaptionTones] = useState(product ? product.caption_tones : null);

    var CaptionToneOptions = productCaptionTones?.map((x) => ({
        value: x,
        label: x
      })) || [];
     
    const handleOnChange = (value, {action, option}) => {
        let caption_tones = []

        value?.map((x) => {
            caption_tones.push(x.value)
        })
        
        setProductCaptionTones(caption_tones);                 
        setFieldValue(props.name, caption_tones);
    };
        
    const handleCreate = (inputValue) => {        
        CaptionToneOptions.push({value: inputValue.trim(), label: inputValue.trim()});
        let newCaptionTones = CaptionToneOptions.map((x) => x.value);        
        setFieldValue(props.name, newCaptionTones);
        setProductCaptionTones(newCaptionTones);                    
    };       
    
    const Input = (props) => {
        return (
            <components.Input maxLength={30} {...props} />            
        );            
    };

    //used to hide the drop down menu
    const Menu = () => {
       return (<></>);
    };

    //used to hide the drop down menu
    const IndicatorsContainer = () => {
        return (<></>);
    };    

    return (     
        <>     
        <div className="form-group row">
            <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>                 
            <div className="col-lg-9">                         
                <CreatableSelect {...field} {...props} 
                    onChange={handleOnChange}
                    onCreateOption={(e) => handleCreate(e)}
                    options={CaptionToneOptions}
                    value={CaptionToneOptions}                    
                    placeholder="Type and hit enter to add a theme (e.g Humourous, Drill Sergeant...)"
                    components={{Input, Menu, IndicatorsContainer}}  
                />
                {meta.touched && meta.error ? (     
                <div className="error-msg">{meta.error}</div>     
                ) : null}     
            </div>
        </div> 
        </>    
    );     
};      

export default CaptionThemeEditor;