import {apiSlice} from '../../app/api/apiSlice'

export const fileUploadApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        generateUrl: builder.mutation({
            query: (filedetails) => ({
                url: '/full_videos/generate_upload_url/',
                method: 'POST',
                body: {...filedetails}
            })            
        }),
        addFullVideo: builder.mutation({
            query: (details) => ({
                url: '/full_videos/',
                method: 'POST',
                body: {
                    ...details
                }
            }),
            invalidatesTags: ['Balances']            
        }),        
        isVideoProcessing: builder.mutation({
            query: (productId) => ({
                url: '/full_videos/is_video_processing/',
                method: 'POST',
                body: {
                    "product":productId
                }                         
            }),
        }),
        getVideoCost: builder.mutation({
            query: (videoData) => ({
                url: '/user_subscription/get_video_processing_cost/',
                method: 'POST',
                body: {
                    ...videoData
                }                         
            }),
        }),       
    })
})


export const {
    useGenerateUrlMutation,
    useAddFullVideoMutation,
    useIsVideoProcessingMutation,
    useGetVideoCostMutation,
} = fileUploadApiSlice