import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes, Navigate  } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
// Style Root for making media queries to inline css
// import { StyleRoot } from "radium";
// Layout Routes
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4'

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = props => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

  return (
    <Fragment>      
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >

        <ThemeProvider theme={themes[themeConfig.theme]}>
          <CookieConsent>
            This website uses cookies to enhance the user experience.
            <br />
            <br />            
            <span style={{fontSize: "12px"}}>
            Cookies are small data files that are placed on your device when you visit a website. We use cookies for various purposes, including:
            <br />
            <br />

            <ul>
              <li>Essential Cookies: Necessary for the website to function properly (e.g., session cookies).</li>
              <li>Performance Cookies: Help us understand how visitors interact with our website by collecting and reporting information anonymously (e.g., Google Analytics).</li>
              <li>Functionality Cookies: Allow the website to remember choices you make and provide enhanced features (e.g., language preferences).            </li>
              <li>By continuing to browse the site, you are agreeing to our use of cookies. For more detailed information, please visit our <a href="http://autigma.com/terms">Privacy Policy</a>.</li>
            </ul>
            </span>
            
          </CookieConsent>
          <div>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {/*Start layout routes */}
                <BrowserRouter >
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={< Navigate to = "/dashboard" />}
                   />
                    {layoutRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.path}
                          element={prop.component}
                          key={key}
                          history={history}
                        />
                      );
                    })}
                  </Routes>
                </BrowserRouter>
                {/*End layout routes */}
              </PersistGate>
            </Provider>
          </div>
        </ThemeProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
