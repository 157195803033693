import styled from "styled-components";

export const StepBox = styled.div` 
  border-radius:15px;
  border: 1px solid #e0e0e0;
  height: 220px;
  margin: 5px 0;   
`

export const StepHeader = styled.div`
 width: 100%;
 display:flex;
 justify-content: center;
 font-weight: bold;
 font-size: 32px;
 margin: 5px;
`


export const StepSubHeader = styled.div` 
 text-align: center;
 display:flex;
 justify-content: center;
 font-weight: bold;
 font-size: 16px;
 margin: 5px; 
 height: 40px;
`

export const StepContent = styled.div`
 width: 100%;
 display:flex;
 justify-content: center;
 font-size: 12px;
 margin: 5px;
`