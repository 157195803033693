import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import createReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {configureStore} from '@reduxjs/toolkit'
import { apiSlice } from 'app/api/apiSlice';


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['scrumboard', 'themeSetting', 'LanguageSwitcher', 'themeChanger', 'fileUpload', 'schedule', 'payment']
};

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const persistedReducer = persistReducer(persistConfig, createReducer());
const middleware = [routeMiddleware, apiSlice.middleware];

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(middleware),
    devTools:true
})

const persistor = persistStore(store)

export { store, history, persistor };