import {apiSlice} from '../../app/api/apiSlice'

export const productsAPISlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        products_get: builder.query({
            query: () => ({
                url: '/products/',
                method: 'GET',                
            }), 
            providesTags: ['Products']             
        }),
        products_update: builder.mutation({
            query: product => ({
                url: `/products/${product.id}/`,
                body: {'name':product.name, 'type':product.type, 'description':product.description, 'image':product.image, 'active':product.active, 'url':product.url, 'socials':product.socials, 'hash_tags':product.hash_tags, "caption_tones":product.caption_tones, "caption_tone_scheme":product.caption_tone_scheme, "caption_emojis":product.caption_emojis},
                method: 'PUT',                
            }),  
            invalidatesTags: ['Products', 'Socials']
        }),
        products_add: builder.mutation({ 
            query: product => ({
                url: '/products/',
                method: 'POST',
                body: {'name':product.name, 'type':product.type, 'description':product.description, 'image':product.image, 'active':product.active, 'url':product.url, 'socials':product.socials, 'hash_tags':product.hash_tags, "caption_tones":product.caption_tones, "caption_tone_scheme":product.caption_tone_scheme, "caption_emojis":product.caption_emojis},
            }),
            invalidatesTags: ['Products', 'ProductCount', 'Socials']            
        }),
        products_delete: builder.mutation({
            query: id => ({
                url: `/products/${id}/`,
                method: 'DELETE',                
            }),
            invalidatesTags: ['Products', 'ProductCount', 'Socials']            
        }),  
        products_product_type_data_get: builder.query({
            query: () => ({
                url: `/products/get_product_type_data/`,
                method: 'GET',                
            })            
        }),   
        products_get_allowed_product_count: builder.query({
            query: () => ({
                url: '/products/get_allowed_product_count/',
                method: 'GET',                
            }), 
            providesTags: ['ProductCount']            
        }),    
    })
})


export const {
    useProducts_getQuery,
    useProducts_updateMutation,
    useProducts_addMutation,
    useProducts_deleteMutation,    
    useProducts_product_type_data_getQuery,    
    useProducts_get_allowed_product_countQuery,    
} = productsAPISlice