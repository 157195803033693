import {React, useState, useEffect} from "react";
import { useSelector, useDispatch, } from "react-redux";
import {
    socials_instagram, socials_tiktok, socials_x, socials_youtube, socials_configure_x1, socials_configure_x2, socials_configure_youtube, socials_configure_tiktok
} from "helper/constant";
import LoaderComponent from "components/common/LoaderComponent";
import { useTwitterConnectv1StartMutation,
    useTwitterConnectv1CompleteMutation,
    useTwitterConnectv2StartMutation,
    useTwitterConnectv2CompleteMutation,
    useYoutubeConnectCompleteCreateTokensMutation,
    useYoutubeConnectStartMutation,
    useYoutubeRevokeTokenMutation,
    useTikTokConnectCompleteCreateTokensMutation,
    useTikTokConnectStartMutation,
    useTikTokRevokeTokenMutation,
    useSocials_getQuery,
    useSocials_deleteMutation,
    useSocials_updateMutation
 } from "./socialsAPISlice";

import { selectSocials, setSocials } from "./socialsSlice";
import { selectSocialToLink, setSocialToLink } from "./socialToLinkSlice";
import { selectCurrentUser } from "../authentication/authenticationSlice";

import * as Yup from "yup";

import {
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {Formik, Form, useField} from "formik"

const CONFIG_STATE_NONE = 0;
const CONFIG_STATE_CONFIGURE = 1;
const CONFIG_STATE_STAGE_START = 2;
const CONFIG_STATE_STAGE1 = 3;
const CONFIG_STATE_STAGE2 = 4;
const CONFIG_STATE_STAGE3 = 5;
const CONFIG_STATE_ERROR = 100;
const SOCIAL_TYPE_NONE = 0;
export const SOCIAL_TYPE_TWITTER = 1;
export const SOCIAL_TYPE_YOUTUBE = 2;
export const SOCIAL_TYPE_TIKTOK = 3;
export const SOCIAL_TYPE_INSTAGRAM = 4;

const Socials = () => {

    class Social
    {
        id = 0;
        socialId ="";
        name = "";
        type=0;
        typeName="";
        active=false;
        image=null;
        configState=CONFIG_STATE_NONE;
        configData=null;
        errorData="";
        linked=false
        properties={}
        
        constructor(id = 0, socialId="", name = "", type=0, typeName="", active=false, linked=false, image=null, configState=CONFIG_STATE_NONE, configData=null, errorData="", properties={})
        {        
            this.id=id;
            this.socialId=socialId;
            this.name = name;
            this.type=type;
            this.active=active;
            this.image=image;
            this.typeName=typeName;
            this.configState=configState;
            this.configData=configData;
            this.errorData=errorData;
            this.linked=linked;
            if (properties !== null)
                this.properties=properties;
        } 

        SaveProperties()
        {            
        }

        static async PersistLinkConfig(social)
        {   
            var newSocial = null;
            if (social != null)
            {
                newSocial = Social.Copy(social);  
            }
            
            await dispatch(setSocialToLink(newSocial));
        }       

        static async ClearPersistedLinkConfig()
        {
            Social.PersistLinkConfig(null);
        }

        static Copy(social)
        {
            if (social != null)
            {
                switch (social.type)
                {
                    case SOCIAL_TYPE_YOUTUBE:
                        return new YouTubeSocial(social.id, social.socialId, social.name, social.type, social.typeName, 
                            social.active, social.linked, social.image, social.configState, social.configData, social.errorData, {...social.properties});
                    case SOCIAL_TYPE_TIKTOK:
                        return new TikTokSocial(social.id, social.socialId, social.name, social.type, social.typeName, 
                            social.active, social.linked, social.image, social.configState, social.configData, social.errorData, {...social.properties});
                    default:
                        return new Social(social.id, social.socialId, social.name, social.type, social.typeName, 
                            social.active, social.linked, social.image, social.configState, social.configData, social.errorData, {...social.properties});                        
                }
                
            }
            else
            {
                return null;
            }
        }
    }   

    class YouTubeSocial extends Social 
    {
        privacyStatus = "public"    

        constructor(id = 0, socialId="", name = "", type=0, typeName="", active=false, linked=false, image=null, configState=CONFIG_STATE_NONE, configData=null, errorData="", properties={})
        {
            super(id=id, socialId=socialId, name=name, type=type, typeName=typeName, active=active, linked=linked, image=image, configState=configState, configData=configData, errorData=errorData, properties=properties);            

            if (this.properties?.privacy_status)
            {
                this.privacyStatus = this.properties.privacy_status
            }            
        }
        
        SaveProperties()
        {
            var newProperties = {...this.properties}
            newProperties.privacy_status = this.privacyStatus
            this.properties = newProperties
        }
    }

    class TikTokSocial extends Social 
    {
        privacy_level = "SELF_ONLY"    
        stitch_disabled = false
        comment_disabled = false
        duet_disabled = false
        video_cover_timestamp_ms = 0
        
        constructor(id = 0, socialId="", name = "", type=0, typeName="", active=false, linked=false, image=null, configState=CONFIG_STATE_NONE, configData=null, errorData="", properties={})
        {
            super(id=id, socialId=socialId, name=name, type=type, typeName=typeName, active=active, linked=linked, image=image, configState=configState, configData=configData, errorData=errorData, properties=properties);            

            if (this.properties?.privacy_level)
            {
                if (this.properties?.privacy_level_options && this.properties.privacy_level_options.includes(this.properties.privacy_level))
                    this.privacy_level = this.properties.privacy_level                
            }  
            if (this.properties?.stitch_disabled)
            {
                this.stitch_disabled = this.properties.stitch_disabled
            }          
            if (this.properties?.comment_disabled)
            {
                this.comment_disabled = this.properties.comment_disabled
            }          
            if (this.properties?.duet_disabled)
            {
                this.duet_disabled = this.properties.duet_disabled
            }  
            if (this.properties?.video_cover_timestamp_ms)
            {
                this.video_cover_timestamp_ms = this.properties.video_cover_timestamp_ms
            }  
        }
        
        SaveProperties()
        {
            var newProperties = {...this.properties}
            newProperties.privacy_level = this.privacy_level
            newProperties.stitch_disabled = this.stitch_disabled
            newProperties.comment_disabled = this.comment_disabled
            newProperties.duet_disabled = this.duet_disabled
            newProperties.video_cover_timestamp_ms = this.video_cover_timestamp_ms
            this.properties = newProperties
        }
    }

    const dispatch = useDispatch();
    var socialToLink = useSelector(selectSocialToLink)  

    const [twitterStartAuthv1] = useTwitterConnectv1StartMutation(); 
    const [twitterCompleteAuthv1] = useTwitterConnectv1CompleteMutation(); 
    const [twitterStartAuthv2] = useTwitterConnectv2StartMutation(); 
    const [twitterCompleteAuthv2] = useTwitterConnectv2CompleteMutation(); 

    const [youTubeStartAuth] = useYoutubeConnectStartMutation(); 
    const [youtubeCompleteCreateToken] = useYoutubeConnectCompleteCreateTokensMutation(); 
    const [youtubeRevokeToken] = useYoutubeRevokeTokenMutation();

    const [tikTokStartAuth] = useTikTokConnectStartMutation(); 
    const [tikTokCompleteCreateToken] = useTikTokConnectCompleteCreateTokensMutation(); 
    const [tikTokRevokeToken] = useTikTokRevokeTokenMutation();

    const {data: socialData, isFetching: isLoadingSocials, refetch: refetchSocials} = useSocials_getQuery();
    const [socials_delete] = useSocials_deleteMutation();
    const [socials_update] = useSocials_updateMutation();

    const currentUser = useSelector(selectCurrentUser);    

    const [isLoading, setIsLoading] = useState(false);    
    const [twitterConfigModalShow, setLinkTwitterModalShow] = useState(false);
    const [youtubeConfigModalShow, setLinkYouTubeModalShow] = useState(false);
    const [tikTokConfigModalShow, setLinkTikTokModalShow] = useState(false);
    const [isReauth, setIsReauth] = useState(false);   
    const [socialIdToConfigure, setSocialIdToConfigure] = useState(null);  
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);    
    const [youTubeSettingsModalShow, setYoutubeSettingsModalShow] = useState(false); 
   // const [tikTokSettingsModalShow, setTikTokSettingsModalShow] = useState(false); 

    function OverrideSocialAvailability()
    {
        switch (currentUser.email)
        {
            case "info@autigma.com":
            case "alex@thalmanns.com":
            case "thavendren@gmail.com":
            case "tv@lightheartedtech.com":
            case "test@autigma.com":
                return true;
            default:
                return false;
        }
    }   

    const x = {id:1, name:"X", image:socials_x, url:"https://www.x.com", available:true};
    const youtube = {id:2, name:"YouTube", image:socials_youtube, url:"https://www.youtube.com", available:OverrideSocialAvailability()};
    const tiktok = {id:3, name:"TikTok", image:socials_tiktok, url:"https://www.tiktok.com", available:true};
    const instagram = {id:4, name:"Instagram", image:socials_instagram, url:"https://www.instagram.com", available:false};
    
    const socialPlatforms = [x, youtube, tiktok, instagram];

    var socials = socialData?.map((s) => {
        let social = null;
        switch (s.type)
        {
            case SOCIAL_TYPE_YOUTUBE:
                social = new YouTubeSocial(s.id, s.socialId, s.name, s.type, s.typeName, s.active, s.linked, youtube.image, s.state, null, "", s.properties);
                break;
            case SOCIAL_TYPE_TIKTOK:
                social = new TikTokSocial(s.id, s.socialId, s.name, s.type, s.typeName, s.active, s.linked, tiktok.image, s.state, null, "", s.properties);
                break;
            default:
                social = new Social(s.id, s.socialId, s.name, s.type, s.typeName, s.active, s.linked, socialPlatforms[s.type - 1].image, s.state, null, "", s.properties);
                break;
        }        
        
        return social;
    }) 
    
    useEffect(() => {
        PageLoadHandler();            
    },[]) 

    function typeCompare( a, b ) 
    {
        if ( a.type < b.type ){
          return -1;
        }
        if ( a.type > b.type ){
          return 1;
        }
        return 0;
    }

    function IsBusyLinking()
    {
        var result = socialToLink != null && socialToLink.configState !== CONFIG_STATE_CONFIGURE && socialToLink.configState !== CONFIG_STATE_ERROR && socialToLink.configState !== CONFIG_STATE_NONE
        return result;

    }   

    // Function to handle redirect after authentication
    async function PageLoadHandler() 
    {         
        if (IsBusyLinking())
        { 
            switch (socialToLink.type)
            {
                case SOCIAL_TYPE_TWITTER:                
                    HandleTwitterLink();
                    break;
                case SOCIAL_TYPE_YOUTUBE:
                    HandleYouTubeLink();
                    break;
                case SOCIAL_TYPE_TIKTOK:                    
                    HandleTikTokLink();
                    break;
                case SOCIAL_TYPE_INSTAGRAM:
                    break;
                default:
                    //not a valid auth type - reset                             
                    break;
            }           
        }        
    }  

    async function HandleTwitterLink(socialContinue=null)
    {     
        try
        {
            // Check if the URL contains the authorization code
            const url = window.location.href;
            const urlParams = new URLSearchParams(window.location.search);       
            
            const codev1 = urlParams.get('oauth_token'); //v1
            const codev2 = urlParams.get('code'); //v2    
            
            let social = socialContinue;
            
            if (social === null)
            {
                social = Social.Copy(socialToLink);
            }

            switch (social.configState)
            {  
                case CONFIG_STATE_STAGE1 :
                    
                    //get saved authTokenSecret
                    const oauthTokenSecret = social.configData;

                    if (oauthTokenSecret != null)
                    {
                        // Authorization code is present, you can now exchange it for an access token
                        // You can handle this exchange process using a backend server        
                        const v1Result = await twitterCompleteAuthv1([url, codev1, oauthTokenSecret]);   

                        if (v1Result?.data)
                        {   
                            social.errorData="";
                            social.id = v1Result.data.id;
                            social.socialId = v1Result.data.socialId;
                            social.name = v1Result.data.socialName;
                            social.configState = CONFIG_STATE_STAGE2;
                            
                            Social.PersistLinkConfig(social);
                            
                            //invoke stage 2 manually
                            HandleTwitterLink(social); 
                        }
                        else
                        {
                            //setBusyModalShow(false);
                            setLinkTwitterModalShow(true);                       
                            social.errorData = `Could not complete X Authentication (v1 API) - ${v1Result.error.data}`;
                            social.configState = CONFIG_STATE_ERROR;
                            Social.PersistLinkConfig(social);  
                            //console.error(`Something went wrong during v1 auth - ${v1Result.error.data} - Twitter authorisation failed!`);                                          
                        }                    
                    }
                    else
                    {
                        //setBusyModalShow(false);
                        setLinkTwitterModalShow(true);
                        social.configState = CONFIG_STATE_ERROR;
                        social.errorData = "Could not complete X Authentication (v1 API) - no valid OAuth token returned";
                        Social.PersistLinkConfig(social);                      
                    }                
                
                    break;
                case CONFIG_STATE_STAGE2 :
                    
                    //now do v2
                    const response = await twitterStartAuthv2();  
                    const codeVerifierStart = response.data["codeVerifier"];    

                    social.errorData="";
                    social.configData = codeVerifierStart;
                    social.configState = CONFIG_STATE_STAGE3;
                    Social.PersistLinkConfig(social);
                
                    window.location.href = response.data["url"];
                    break;
                case CONFIG_STATE_STAGE3:              
                
                    const codeVerifierComplete = social.configData;

                    //hack for testing - change URL
                    const fixurl = url.replace("http://localhost:3000/SocialManagement", "https://www.lightheartedtech.com/twitterauth.html");
                    
                    const v2Result = await twitterCompleteAuthv2([fixurl, codeVerifierComplete]);   
                    
                    if (v2Result?.data)
                    {   
                        //all done so no more item to config
                        Social.ClearPersistedLinkConfig(); 
                        //GoToBaseURL();
                        refetchSocials();
                        //once we have completed the auth for Twitter, there is nothing left to do - the new social item will be loaded when we refresh to base page
                        //setBusyModalShow(false);                                 
                    }  
                    else
                    {                   
                        setLinkTwitterModalShow(true);
                        social.configState = CONFIG_STATE_ERROR;
                        social.errorData = `Could not complete X Authentication (v2 API) - ${v2Result.error.data}`;
                        Social.PersistLinkConfig(social);       

                        console.error(`Something went wrong during v2 auth - ${v2Result.error.data} - Twitter authorisation failed!`);    
                    }                
                    
                    break;     
                case CONFIG_STATE_ERROR:
                    break;     
                default:            
                    //invalid url state - reset all states
                    Social.ClearPersistedLinkConfig();                 
                    refetchSocials();
                    break;                             
                
            }   
        }
        catch (err)
        {
            console.error(`Error in handling Twitter social linkage - ${err} - state was cleared!`);    
            Social.ClearPersistedLinkConfig()
            refetchSocials();
        }  
    }    

    async function HandleYouTubeLink()
    {     
        try
        {  
            // Check if the URL contains the authorization code
            const url = window.location.href;
            const urlParams = new URLSearchParams(window.location.search);       
        
            let social = Social.Copy(socialToLink);

            switch (social.configState)
            {           
                case CONFIG_STATE_STAGE1:       
                            
                    const state = social.configData;

                    //hack for testing - change URL
                    const fixurl = url.replace("http://localhost:3000/SocialManagement", "https://www.lightheartedtech.com/twitterauth.html");
                    
                    const response = await youtubeCompleteCreateToken([fixurl, state]);   
                            
                    if (response?.data)
                    {
                        setLinkYouTubeModalShow(false);

                        //done
                        Social.ClearPersistedLinkConfig();

                        refetchSocials();
                    } 
                    else
                    {
                        // setBusyModalShow(false);
                        setLinkYouTubeModalShow(true);

                        if (response.error.status === 400)
                        {   
                            //In this case, we and/or Youtube already has valid access tokens for this channel!
                            social.errorData = response.error.data;
                            social.configState = CONFIG_STATE_ERROR;
                            Social.PersistLinkConfig(social);

                            console.error(`YouTube authorisation failed - existing auth existed on Youtube side for some reason`);
                        }
                        else 
                        {
                            social.configState = CONFIG_STATE_ERROR;
                            social.errorData = `Could not complete Youtube Authentication - ${response.error.data}`;
                            Social.PersistLinkConfig(social);

                            console.error(`Something went wrong during authorisation - ${response.error.data} - Youtube authorisation failed!`);
                        }                                             
                    }      
                
                    break;    
                case CONFIG_STATE_ERROR:
                //  setBusyModalShow(false);
                    setLinkYouTubeModalShow(true);
                    break;
                default:            
                    //invalid url state - reset all states
                    Social.ClearPersistedLinkConfig();                 
                    refetchSocials();
                    break;     
            }                          
    
        }
        catch (err)
        {
            console.error(`Error in handling YouTube social linkage - ${err} - state was cleared!`);    
            Social.ClearPersistedLinkConfig()
            refetchSocials();
        }   
    }

    async function HandleTikTokLink()
    {       
        try
        {
            // Check if the URL contains the authorization code
            const url = window.location.href;
            const urlParams = new URLSearchParams(window.location.search);       
        
            let social = Social.Copy(socialToLink);

            switch (social.configState)
            {           
                case CONFIG_STATE_STAGE1:       
                            
                    const state = social.configData.state;
                    const code_verifier = social.configData.code_verifier;
                    
                    //hack for testing - change URL
                    const fixurl = url.replace("http://localhost:3000/SocialManagement", "https://www.lightheartedtech.com/twitterauth.html");
                    
                    const response = await tikTokCompleteCreateToken([fixurl, state, code_verifier]);   
                            
                    if (response?.data)
                    {
                        setLinkTikTokModalShow(false);

                        //done
                        Social.ClearPersistedLinkConfig();
                        
                        refetchSocials();

                        //console.log("refetching socials");
                        refetchSocials();                      
                    } 
                    else
                    {
                        setLinkTikTokModalShow(true);

                        if (response.error.status === 400)
                        {   
                            //In this case, we and/or TikTok already has valid access tokens for this channel!
                            social.errorData = response.error.data;
                            social.configState = CONFIG_STATE_ERROR;
                            Social.PersistLinkConfig(social);

                            console.error(`TikTok authorisation failed - existing auth existed on TikTok side for some reason`);
                        }
                        else 
                        {
                            social.configState = CONFIG_STATE_ERROR;
                            social.errorData = `Could not complete TikTok Authentication - ${response.error.data}`;
                            Social.PersistLinkConfig(social);

                            console.error(`Something went wrong during authorisation - ${response.error.data} - TikTok authorisation failed!`);
                        }                                             
                    }      
                
                    break;    
                case CONFIG_STATE_ERROR:
                //  setBusyModalShow(false);
                    setLinkTikTokModalShow(true);
                    break;
                default:            
                    //invalid url state - reset all states
                    Social.ClearPersistedLinkConfig();                                     
                    refetchSocials();
                    break;                               
        
            }     
        }
        catch (err)
        {
            console.error(`Error in handling TikTok social linkage - ${err} - state was cleared!`);    
            Social.ClearPersistedLinkConfig()            
            refetchSocials();
        }   
    }
    
    async function Link() 
    {
        let social = Social.Copy(socialToLink);      
        
        //setBusyModalShow(true)
        social.configState = CONFIG_STATE_STAGE_START;
        Social.PersistLinkConfig(social);

        switch (social.type)
        {
            case SOCIAL_TYPE_TWITTER:
                //get a v1 Oauth URL from Twitter
                const twitterResponse = await twitterStartAuthv1();                
                
                if (twitterResponse && twitterResponse?.data)
                {
                    const oauthTokenSecret = twitterResponse.data["oauthTokenSecret"]
                    social.configData = oauthTokenSecret;
                    social.configState = CONFIG_STATE_STAGE1;
                    Social.PersistLinkConfig(social);                  

                    //redirect to auth URL
                    window.location.href = twitterResponse.data["url"];
                }
                else
                {
                    console.error("Something went wrong - Twitter authorisation failed!");
                    social.configState = CONFIG_STATE_ERROR;
                    social.errorData = `Could not start X Authentication : ${twitterResponse.error}`;
                    Social.PersistLinkConfig(social);                    
                }

                break;
            case SOCIAL_TYPE_YOUTUBE:

                if (isReauth && social.socialId !== null && social.socialId !== "")
                    await youtubeRevokeToken(social.socialId)

                const youtubeResponse = await youTubeStartAuth();                
                
                if (youtubeResponse)
                {
                    //const state = youtubeResponse.data["state"]
                    social.configState = CONFIG_STATE_STAGE1;
                    social.configData = "";
                    
                    Social.PersistLinkConfig(social);
                 
                    //redirect to auth URL
                    window.location.href = youtubeResponse.data["url"];
                }
                else
                {
                    console.error("Something went wrong - Youtube authorisation failed!");
                    social.configState = CONFIG_STATE_ERROR;
                    social.errorData = `Could not start Youtube Authentication : ${youtubeResponse.error}`;
                    Social.PersistLinkConfig(social);                    
                }
                break;
            case SOCIAL_TYPE_TIKTOK:
                if (isReauth && social.socialId !== null && social.socialId !== "")
                    await tikTokRevokeToken(social.socialId)

                const tikTokResponse = await tikTokStartAuth();                
                
                if (tikTokResponse)
                {
                    social.configState = CONFIG_STATE_STAGE1;
                    social.configData = {state: tikTokResponse.data["state"], code_verifier:tikTokResponse.data["code_verifier"]};
                   
                    Social.PersistLinkConfig(social);
                 
                    //redirect to auth URL
                    window.location.href = tikTokResponse.data["url"];
                }
                else
                {
                    console.error("Something went wrong - TikTok authorisation failed!");
                    social.configState = CONFIG_STATE_ERROR;
                    social.errorData = `Could not start TikTok Authentication : ${tikTokResponse.error}`;
                    Social.PersistLinkConfig(social);                    
                }
                break;
            case SOCIAL_TYPE_INSTAGRAM:
                break;
            default:
                break;
        }

       // setBusyModalShow(false)
    } 

    function CancelTwitterLink()
    {
        //delete any partially configured social
        setLinkTwitterModalShow(false);      
        setIsLoading(false);
    }

    function CancelYoutubeLink()
    {
        //delete any partially configured social
        setLinkYouTubeModalShow(false);    
        setIsLoading(false);  
    }

    function CancelTikTokLink()
    {
        //delete any partially configured social
        setLinkTikTokModalShow(false);    
        setIsLoading(false);  
    }

    function getSocialToConfigure(id)
    {
        if (socials)
        {
            var index = socials.findIndex((social)=>social.id == id);
            if (index >= 0)
            {
                return socials[index]
            }
        }

        return null
    }

    async function SaveSettings(values)
    {
        setIsLoading(true);
        var social = getSocialToConfigure(socialIdToConfigure)

        SettingsModalShow(false, social.type, null);

        switch (social.type)
        {
            case SOCIAL_TYPE_TWITTER:
                break;
            case SOCIAL_TYPE_YOUTUBE:
                social.privacyStatus = values.privacyStatus
                break;
            // case SOCIAL_TYPE_TIKTOK:
            //     social.privacy_level = values.privacy_level
            //     social.comment_disabled = values.comment_disabled === true || values.comment_disabled === 'true'
            //     social.duet_disabled = values.duet_disabled == true || values.duet_disabled === 'true'
            //     social.stitch_disabled = values.stitch_disabled === true || values.stitch_disabled === 'true'
            //     social.video_cover_timestamp_ms = values.video_cover_timestamp_ms
            //     break;
            case SOCIAL_TYPE_INSTAGRAM:
                break;
        }
        social.SaveProperties();

        const response = await socials_update(social);
        if (!response.error)
        {            
        }   

        refetchSocials();
        setIsLoading(false);        
    }

    function TwitterLink()
    {        
        return (
            <Modal size='lg' isOpen={twitterConfigModalShow} centered={true}>                   
                <Formik initialValues={socialToLink}>
                    {formik => (
                        <Form> 
                            <ModalHeader>
                                Connect with X (Twitter)
                            </ModalHeader>
                            <ModalBody>
                                <br/>                                    
                                <div className="form-group row">
                                    <div className="center">
                                        In order to connect with a X (Twitter) account, you will be redirected to log in and connect via two Twitter authorisation pages. If required, please authorise access for the Autigma app in both cases.
                                        Once both instances are authorised, the configuration for X (Twitter) will be complete. If either authorisation fails or you disallow authorisation at either stage,
                                        the process will fail and you will need to restart the process.
                                    </div>
                                    <div className="col-sm-10">
                                        <br/>
                                        By connecting with X (Twitter), you agree with the <a href="https://x.com/en/tos">X Terms of Service</a> and <a href="https://x.com/en/privacy">Privacy Policy</a>
                                    </div>
                                </div>
                                <br/>
                                <div className="row center">
                                    <div className="col-6 pr-15">
                                        <img width={"90%"} src={socials_configure_x1}/>
                                    </div>
                                    <div className="col-6">
                                        <img src={socials_configure_x2}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group row">
                                    <div className="error-msg">
                                        {socialToLink.errorData ? socialToLink.errorData : ""}
                                    </div>
                                </div>
                                <br/>                                                                                              
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={()=> {      
                                    setLinkTwitterModalShow(false);                                             
                                    Link();
                                }}>Connect</Button>
                                <Button onClick={()=> {
                                    CancelTwitterLink();                          
                                }}>Cancel</Button>                                
                            </ModalFooter>                                                 
                        </Form>
                    )}
                </Formik>
            </Modal> 
        )
    }   

    const GeneralValueSelect = ({ label, ...props }) => {
     
        const [field, meta] = useField(props);     

        const handleSelectChange = (e) => {
            const newValue = e.target.value;
                       
        field.onChange({
                target: {
                  name: field.name,
                  value: newValue,
                },
              });
          };

        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>                 
                <div className="col-lg-9">                         
                    <select {...field} {...props} onChange={(e) => handleSelectChange(e)} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>     
                    ) : null}     
                </div>
            </div> 
          </>    
        );     
      };   

    const ATextInput = ({ label, ...props }) => {

        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]     
        // which we can spread on <input>. We can use field meta to show an error     
        // message if the field is invalid and it has been touched (i.e. visited)
     
        const [field, meta] = useField(props);
     
        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>   
                <div className="col-lg-9">      
                    <input className="form-control react-form-input" {...field} {...props} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>) : null}     
                </div>
            </div>  
          </>     
        );                               
    };

    const ACheckbox = ({ label, ...props }) => {
     
        // React treats radios and checkbox inputs differently from other input types: select and textarea.     
        // Formik does this too! When you specify `type` to useField(), it will     
        // return the correct bag of props for you -- a `checked` prop will be included     
        // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
     
        const [field, meta] = useField({ ...props, type: 'checkbox' });     
        return (
          <>
            <div className="form-group row">
                <label className="col-lg-3 col-form-label">{label}</label>    
                <div className="col-lg-9">    
                    <input type="checkbox" {...field} {...props}  />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>     
                    ) : null}     
                </div>
            </div>
          </>     
        );
     
      };    

    const youtubeSchema = Yup.object().shape({
        privacyStatus: Yup.string().required('Please select a privacy status'),        
    }) 

    function YouTubeSettings()
    {        
        return (
            <Modal size='lg' isOpen={youTubeSettingsModalShow} centered={true}>                   
                <Formik initialValues={getSocialToConfigure(socialIdToConfigure)} validationSchema={youtubeSchema} onSubmit={(values) => {SaveSettings(values)}}>            
                    {formik => (
                        <Form>  
                            <ModalHeader>
                                YouTube
                            </ModalHeader>
                            <ModalBody>
                            <>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <GeneralValueSelect 
                                            label = "Default Video Privacy"
                                            name = "privacyStatus"
                                            className="form-control react-form-input selectpicker"
                                            title="The default YouTube video privacy state - you can still change this at a per-post level in the Schedule"               
                                            data-style="btn-new">
                                            <option title="Private - only you or users you select can see the video" value={"private"}>Private</option>;                                          
                                            <option title="Public - anyone can see the video and will show up in searches" value={"public"}>Public</option>;                                          
                                            <option title="Unlisted - anyone with the video URL can see the video - won't show up in searches" value={"unlisted"}>Unlisted</option>;                                          
                                        </GeneralValueSelect>                                              
                                    </div>
                                </div>
                            </>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit">Save</Button>
                                <Button onClick={()=>{                                    
                                    SettingsModalShow(false, SOCIAL_TYPE_YOUTUBE, null);
                                    }}>Cancel</Button>
                            </ModalFooter>                                                
                        </Form>
                    )}
                </Formik>
            </Modal>  
        )
    }

    function YouTubeLink()
    {        
        return (
             <Modal size='lg' isOpen={youtubeConfigModalShow} centered={true}>                   
                <Formik initialValues={socialToLink}>            
                    {formik => (
                        <Form>  
                            <ModalHeader>
                                Connect with YouTube
                            </ModalHeader>
                            <ModalBody>
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        In order to connect with a YouTube account, you will be redirected to log in to your YouTube account. If required, please authorise access for the Autigma app and ensure you
                                        check the "Manage your YouTube Account" check box before accepting the linkage. 
                                        Once authorised, the authorisation for YouTube will be complete. If authorisation fails or you disallow authorisation,
                                        the process will fail and you will need to restart the process.                        
                                    </div>                                    
                                    <div className="col-sm-10">
                                        <br/>
                                        By connecting with YouTube, you agree with the <a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a> and the <a href="http://www.google.com/policies/privacy">Google Privacy Policy</a>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        <br/>
                                        <img src={socials_configure_youtube}/>
                                    </div>
                                </div>                               
                                <div className="form-group row">
                                    <div className="error-msg">
                                        <br/>
                                        {socialToLink.errorData ? socialToLink.errorData : ""}
                                    </div>
                                </div>
                                <br/>    
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={()=>{    
                                    setLinkYouTubeModalShow(false);                                
                                    Link();
                                    }}>Connect</Button>
                                <Button onClick={()=>{                                    
                                    CancelYoutubeLink();
                                    }}>Cancel</Button>
                            </ModalFooter>                                                
                        </Form>
                    )}
                </Formik>
            </Modal>  
        )
    }  

    // const tikTokSchema = Yup.object().shape({
    //     privacy_level: Yup.string().required('Please select a privacy level'),        
    //     video_cover_timestamp_ms: Yup.number().min(0, "The timestamp value must be greater or equal to zero").required('Please provide cover timestamp value'),        
    // }) 

    // function TikTokPrivacyOptions()
    // {
    //     var options = []
    //     var social = getSocialToConfigure(socialIdToConfigure)        
    //     if (social.properties.privacy_level_options.includes("SELF_ONLY"))
    //     {
    //         options.push({value: "SELF_ONLY", label:"Self Only"})
    //     }
    //     if (social.properties.privacy_level_options.includes("MUTUAL_FOLLOW_FRIENDS"))
    //     {
    //         options.push({value: "MUTUAL_FOLLOW_FRIENDS", label:"Mutual Follow Friends"})
    //     }
    //     if (social.properties.privacy_level_options.includes("FOLLOWER_OF_CREATOR"))
    //     {
    //         options.push({value: "FOLLOWER_OF_CREATOR", label:"Followers of Creator"})
    //     }
    //     if (social.properties.privacy_level_options.includes("PUBLIC_TO_EVERYONE"))
    //     {
    //         options.push({value: "PUBLIC_TO_EVERYONE", label:"Public to Everyone"})
    //     }
    //     return options;
    // }


    // function TikTokSettings()
    // {        
    //     return (
    //         <Modal size='lg' isOpen={tikTokSettingsModalShow} centered={true}>                   
    //             <Formik initialValues={getSocialToConfigure(socialIdToConfigure)} validationSchema={tikTokSchema} onSubmit={(values) => {SaveSettings(values)}}>            
    //                 {formik => (
    //                     <Form>  
    //                         <ModalHeader>
    //                             TikTok
    //                         </ModalHeader>
    //                         <ModalBody>
    //                         <>
    //                             <div className="form-group row">
    //                                 <div className="col-lg-12">
    //                                     <GeneralValueSelect 
    //                                         label = "Default Video Privacy"
    //                                         name = "privacy_level"
    //                                         key="privacy_level"
    //                                         className="form-control react-form-input selectpicker"
    //                                         title="The default TikTok video privacy state - you can still change this at a per-post level in the Schedule"               
    //                                         data-style="btn-new">
    //                                             {
    //                                                 TikTokPrivacyOptions().map((option) =>
    //                                                     {
    //                                                         return (<option key={option.value} value={option.value}>{option.label}</option>);
    //                                                     }
    //                                                 )
    //                                             }  
    //                                     </GeneralValueSelect>                                              
    //                                 </div>
    //                             </div>
    //                             <div className="form-group row">
    //                                 <div className="col-lg-12">
    //                                     <ATextInput
    //                                         label="Default Video Cover Timestamp (ms)"
    //                                         name="video_cover_timestamp_ms"
    //                                         key="video_cover_timestamp_ms"
    //                                         placeholder="Milliseconds"     
    //                                         title="The default time offset (in milliseconds) from start of the clip to use as the post title/cover image"                                                          
    //                                     />                                       
    //                                 </div>
    //                             </div>
    //                             <div className="form-group row">
    //                                 <div className="col-lg-12">
    //                                     <ACheckbox 
    //                                         label = "Comments Disabled"
    //                                         name = "comment_disabled"
    //                                         title="Enables/disables comments on posts"               
    //                                         data-style="btn-new"/>                                
    //                                 </div>
    //                             </div>
    //                             <div className="form-group row">
    //                                 <div>
    //                                     <ACheckbox 
    //                                         label = "Duet Disabled"
    //                                         name = "duet_disabled"
    //                                         title="Enables/disables the duet post functionality where others can post videos side-by-side with your post"               
    //                                         data-style="btn-new"/>                                
    //                                 </div>
    //                             </div>
    //                             <div className="form-group row">
    //                                 <div>
    //                                     <ACheckbox 
    //                                         label = "Stitch Disabled"
    //                                         name = "stitch_disabled"
    //                                         title="Enables/disables the stitch post functionality where others can combine your video with theirs"               
    //                                         data-style="btn-new"/>                                                
    //                                 </div>
    //                             </div>
    //                         </>
    //                         </ModalBody>
    //                         <ModalFooter>
    //                             <Button type="submit">Save</Button>
    //                             <Button onClick={()=>{                                    
    //                                 SettingsModalShow(false, SOCIAL_TYPE_TIKTOK, null);
    //                                 }}>Cancel</Button>
    //                         </ModalFooter>                                                
    //                     </Form>
    //                 )}
    //             </Formik>
    //         </Modal>  
    //     )
    // }

    function TikTokLink()
    {        
        return (
             <Modal size='lg' isOpen={tikTokConfigModalShow} centered={true}>                   
                <Formik initialValues={socialToLink}>            
                    {formik => (
                        <Form>  
                            <ModalHeader>
                                Connect with TikTok
                            </ModalHeader>
                            <ModalBody>
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        In order to connect with a TikTok account, you will be redirected to log in to your TikTok account. If required, please authorise access for the Autigma app and ensure all
                                        the listed authorisations are enabled before accepting the linkage. 
                                        Once authorised, the authorisation for TIkTok will be complete. If authorisation fails or you disallow authorisation,
                                        the process will fail and you will need to restart the process.                        
                                    </div>                                    
                                    <div className="col-sm-10">
                                        <br/>
                                        By connecting with TIkTok, you agree with the <a href="https://www.tiktok.com/legal/page/row/terms-of-service/en">TikTok Terms of Service</a> and the <a href="https://www.tiktok.com/legal/page/row/privacy-policy/en">TikTok Privacy Policy</a>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        <br/>
                                        <img src={socials_configure_tiktok}/>
                                    </div>
                                </div>                               
                                <div className="form-group row">
                                    <div className="error-msg">
                                        <br/>
                                        {socialToLink.errorData ? socialToLink.errorData : ""}
                                    </div>
                                </div>
                                <br/>    
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={()=>{    
                                    setLinkTikTokModalShow(false);                                
                                    Link();
                                    }}>Connect</Button>
                                <Button onClick={()=>{                                    
                                    CancelTikTokLink();
                                    }}>Cancel</Button>
                            </ModalFooter>                                                
                        </Form>
                    )}
                </Formik>
            </Modal>  
        )
    }  
  
    async function StartLinkSocial(socialType, socialTypeName, socialImage)
    {
        //create new social object based on the selected type                   
        switch (socialType)
        {
            case SOCIAL_TYPE_YOUTUBE:
                var social = new YouTubeSocial(0, "", "", socialType, socialTypeName, false, false, socialImage, CONFIG_STATE_CONFIGURE, null, "", {});          
                break;
            case SOCIAL_TYPE_TIKTOK:
                    var social = new TikTokSocial(0, "", "", socialType, socialTypeName, false, false, socialImage, CONFIG_STATE_CONFIGURE, null, "", {});          
                    break;
            default:
                var social = new Social(0, "", "", socialType, socialTypeName, false, false, socialImage, CONFIG_STATE_CONFIGURE, null, "", {});  
                break;        
        } 
                                     
        LinkSocial(social);
    }

    async function LinkSocial(social, reAuth = false)
    {
        await dispatch(setSocialToLink(social));   

        setIsReauth(reAuth);       
        
        switch (social.type)
        {
            case SOCIAL_TYPE_TWITTER:                
                setLinkTwitterModalShow(true);
                break;
            case SOCIAL_TYPE_YOUTUBE:                
                setLinkYouTubeModalShow(true);
                break;
            case SOCIAL_TYPE_TIKTOK:
                setLinkTikTokModalShow(true);
                break;
            case SOCIAL_TYPE_INSTAGRAM:
                break;                
        }     
    }

    async function HandleDelete()
    {   
        setDeleteConfirmationModalShow(false);   

        setIsLoading(true)

        var social = getSocialToConfigure(socialIdToConfigure);
 
        if (social && social.id > 0)
        {  
            //let doDelete = true;

            switch (social.type)
            {
                case SOCIAL_TYPE_YOUTUBE : //Youtube
                    const resultYoutube = await youtubeRevokeToken(social.socialId);    
                    // if (result.error)
                    // {
                    //     doDelete = false;
                    // }
                    break;
                case SOCIAL_TYPE_YOUTUBE : //Youtube
                    const resultTikTok = await tikTokRevokeToken(social.socialId);    
                    // if (result.error)
                    // {
                    //     doDelete = false;
                    // }
                    break;
            }   

            //if (doDelete)
            //{
                const id = social.id;

                const response = await socials_delete(id);
                if (!response.error)
                {
                                  
                }
            //}            
        }  
       
        setIsLoading(false)
        setSocialIdToConfigure(null)           
        
    } 

    function DeleteConfirmationModal()
    {
        return (
            <Modal
              isOpen={deleteConfirmationModalShow}
              centered={true}
              size='lg'
            >
              <ModalHeader>
                    Confirm Social Removal  
              </ModalHeader>
              <ModalBody>
                <p>
                    You are about to remove and revoke Autigma's access to the social [{socialIdToConfigure ? getSocialToConfigure(socialIdToConfigure).name : null}]. 
                </p>
                <br/>
                <p>
                    If you delete this Social, all social post stats collected by Autigma related to this Social will be lost! Your actual social posts will not be affected. 
                </p>
                <br/>
                <p>
                    Are you sure you want to remove this Social?   
                </p>
              </ModalBody>
              <ModalFooter>
                <Button onClick={()=>{HandleDelete()}}>Yes</Button>
                <Button onClick={()=>{setDeleteConfirmationModalShow(false)}}>No</Button>                
              </ModalFooter>
            </Modal>            
          );
    } 

    const isAnythingLoading = () => {            
        return isLoading || isLoadingSocials;
    }
  
    function BusyLinkingModal()
    {
        return (
            <Modal
              isOpen={IsBusyLinking()}
              centered={true}     
              size='sm'                       
            >
              <ModalHeader>  
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    Linking...
                </div>        
              </ModalHeader>
              <ModalBody>                   
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" role="status"/>                
                    </div>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </Modal>
          );
    } 

    function HasSettings(social)
    {
        switch (social.type)
        {
            case SOCIAL_TYPE_TWITTER:
                return false;
            case SOCIAL_TYPE_TIKTOK:
                return false;
            case SOCIAL_TYPE_INSTAGRAM:
                return true;
            case SOCIAL_TYPE_YOUTUBE:
                return true;
            default:
                return false;
        }
    }

    function SettingsModalShow(show, socialType, socialId = null)
    {
        setSocialIdToConfigure(socialId);    
        switch (socialType)
        {
            // case SOCIAL_TYPE_TIKTOK:
            //     setTikTokSettingsModalShow(show);
            //     break;
            case SOCIAL_TYPE_INSTAGRAM:
                break;
            case SOCIAL_TYPE_YOUTUBE:
                setYoutubeSettingsModalShow(show);
                break;
        }
    }
  

    return (
        <div>     
            <div>
                <TwitterLink
                    show={twitterConfigModalShow}
                    onHide={() => setLinkTwitterModalShow(false)}
                />     
                <YouTubeLink
                    show={youtubeConfigModalShow}
                    onHide={() => setLinkYouTubeModalShow(false)}
                />   
                <YouTubeSettings
                    show={youTubeSettingsModalShow}
                    onHide={() => SettingsModalShow(false, SOCIAL_TYPE_YOUTUBE, null)}
                />   
                <TikTokLink
                    show={tikTokConfigModalShow}
                    onHide={() => setLinkTikTokModalShow(false)}
                />   
                {/* <TikTokSettings
                    show={tikTokSettingsModalShow}
                    onHide={() => SettingsModalShow(false, SOCIAL_TYPE_TIKTOK, null)}
                />                     */}
                <DeleteConfirmationModal
                    show={deleteConfirmationModalShow}
                    onHide={() => setDeleteConfirmationModalShow(false)} 
                />  
                <BusyLinkingModal
                    show={IsBusyLinking()}
                />
            </div>
           <div className="fs-20 widget-dark-color bold-text">Link A Social Media Platform</div>
           {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                    <LoaderComponent loading={isLoading} />
                </div> 
            ) : (          
           <div className="mlr-0 pb-20" style={{marginTop: '15px', display: 'flex'}}>            
                {socialPlatforms.map(item=>
                    <div key={item.id} className="pr-25" >            
                        <Card style={{width: 250, height: 380}} className="c-dark">
                            <a href={item.url} target="_blank"> 
                                { item.available ?
                                    <CardImg top src={item.image} alt={item.name} />
                                :
                                    <CardImg style={{filter: 'grayscale(100%)'}} top src={item.image} alt={item.name} /> 
                                }
                            </a>
                            <CardBody>
                                <CardTitle>{item.name} <div style={{height:25}} className="text-warning bold-text">{(item.available ? "" : " Coming soon")}</div></CardTitle>                                
                                <Button type="button" onClick={() => {        
                                    StartLinkSocial(item.id, item.name, item.image);           
                                    }} disabled={!item.available} className="c-primary mt-10">Link</Button>  
                                
                            </CardBody>
                        </Card>
                    </div>
                )}
            </div> )}    
            <div className="fs-20 widget-dark-color bold-text">Linked Platforms</div>
            {isAnythingLoading() ? (                
                <div className="pt-100">
                    <LoaderComponent loading={isAnythingLoading()} text="Fetching your linked socials"/>
                </div>
            ) : (  
            <div className="mlr-0" style={{marginTop: '15px', display:'flex'}}>                    
                {socials !== undefined ? [...socials].sort(typeCompare).map(social=>
                <div key={social.id} className="pr-25" >            
                    <Card className="c-dark" style={{width: 250, height: 380}}>
                        {social.image ?
                            social.active ?
                                <CardImg top src={social.image}/> 
                            :
                                social.linked ?
                                    <CardImg title="This social is deactivated" style={{filter: 'grayscale(100%)'}} top src={social.image}/>
                                :
                                    <CardImg title="This social has not been properly linked/authorised with your social account" top src={social.image}/>                                    
                        :           
                            null
                        }
                        <CardBody>
                            <CardTitle>{social.name}</CardTitle>     
                                {social.linked ? 
                                    <Button title="Reauthenticate this social with your social account" type="button"  style={{float: 'left'}} onClick={() => {   
                                        LinkSocial(social, true);  
                                    }} className= "c-secondary mt-10">ReLink</Button>                                                                            
                                :
                                    <div>
                                        <Button title="This social has not been properly linked/authorised with your social account - click to Reauthenticate" type="button"  style={{float: 'left'}} onClick={() => {   
                                            LinkSocial(social, true);  
                                        }} className= "c-danger mt-10">ReLink</Button>                                        
                                    </div>   
                                    }
                               
                                <div title="Delete this social" style={{float: 'right'}} onClick={() => {   
                                        setSocialIdToConfigure(social.id);    
                                        setDeleteConfirmationModalShow(true);
                                    }} className="mt-10 fs-25 cursor-pointer"><i className="fa fa-trash text-danger" /></div> 
                                {HasSettings(social) ?
                                    <div title="Configure social settings" style={{margin:5, float: 'right'}} onClick={() => {                                           
                                        SettingsModalShow(true, social.type, social.id);                                   
                                    }} className="mt-10 fs-25 cursor-pointer"><i className="fa fa-cog" />
                                    </div> 
                                    :
                                        null
                                } 
                           
                        </CardBody>
                    </Card>
                </div>) : ""}                                
            </div>
        )}
        </div>
    );
};

export default Socials;