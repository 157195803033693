import React from "react";
import FooterWrapper from "./footer.style";
import Button from "components/button/Button";
import { useNavigate } from "react-router-dom";

const Footer = props => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear(); 

  return (
    <FooterWrapper {...props}>
      <div className="footerBack flex-x align-center">
        <div className="flex-1 fs-13 bold-text footer-text">
          © {currentYear} Autigma, All rights reserved.
        </div>
        <div>  
          <button id="privacy" className="bottom-footer" onClick={() => navigate('/privacypolicy')}>Privacy Policy</button>                       
        </div>
        <div>  
          <button id="terms" className="bottom-footer" onClick={() => navigate('/termsofservice')}>Terms of Service</button>                       
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
