import React from "react";
import { useNavigate } from "react-router-dom";
import './style.css'

export default function PrivacyPolicy() {        
    const navigate = useNavigate()
    return (
        <div className="termsContainer">  
           <div className="row pt-45">               
                <div className="col-12 center">
                    <h1 className="widget-dark-color">Autigma Privacy Policy</h1>                                           
                </div>                    
                <div className="col-12 paragraph">                        
                    <br/>
                    This Privacy Policy describes how Autigma ("we", "us", "our") collects, uses, and protects the information you ("user") provide when using our service ("Autigma").                        
                    <dl>
                        <br/>
                        <dt>Information We Collect - We collect and store the following information when you sign up for our services or interact with our website:</dt>
                            <ul>
                                <li>Email Address: We collect your email address to create and manage your account, provide customer support, and send important service-related communications.</li>                                
                                <li>Your Name: We collect your name for correspondence purposes.</li>                                
                                <li>Third Party Social Media Data: We link your Autigma account to various social media accounts of your choice and we store the social media identifier of your account as well as social media post statistics as long as your account is linked to Autigma.</li>
                                    You can delete any of the data we store related to any of your linked social media accounts by doing one of the following:
                                    <ul>
                                        <li>Deleting the linked related Social item/account from the Autigma Socials page. This will delete all stored data immediately.</li>
                                        <li>Logging into your social media account using the relevant link below to revoke Autigma's access to your account from the relevant account settings page: 
                                             <ul>
                                                <li><a href="https://x.com/settings/connected_apps">X (Twitter)</a></li>
                                                <li><a href="https://security.google.com/settings/security/permissions">YouTube (Google)</a></li>
                                                <li><a href="https://www.tiktok.com/setting">TikTok</a></li>
                                                <li><a href="https://www.instagram.com/">Instagram</a></li>
                                            </ul>
                                            <br/>
                                            Autigma will detect this revocation within 7 days and notify you via Email that you can:
                                            <ul>
                                                <li>relink the account, if the revocation was done in error, or</li>
                                                <li>delete the related Social in Autigma immediately, along with all related data, or</li>
                                                <li>that the data will be deleted automatically after 28 days if no additional action is taken.</li>
                                            </ul>  
                                        </li>
                                    </ul> 
                                   
                            </ul>
                        <dt>How We Use Your Information - We use the information we collect for the following purposes:</dt>   
                            <ul>
                                <li>Account Management: We use your email address to create and manage your account, verify your identity, and enable access to our services.</li>
                                <li>Media Statistics: We gather information and statistics about your social posts made from Autigma to help you analyse the impact of your social media posts.</li>
                                <li>Communication: We may use your email address to send you important updates about our services, such as changes to our terms or policies, service disruptions, or security alerts.</li>
                                <li>Customer Support: We may use your email address to respond to your inquiries, provide assistance, and address any issues you may encounter.</li>
                                <li>Analytics: We may collect anonymized usage data to analyze trends, improve our services, and enhance the user experience.</li>
                            </ul>
                        <dt>Third Party Integrations - Autigma uses the following third party social media API services:</dt>
                            <ul>
                                <li>X (Twitter) API</li>
                                <li>YouTube API Services</li>
                                <li>TikTok API</li>
                                <li>Instagram</li>
                            </ul>
                            <ul>
                                <li>In order to use Autigma, you must agree with the privacy polices of these third party services:
                                    <ul>
                                        <li><a href="https://x.com/en/privacy">X (Twitter)</a></li>
                                        <li><a href="http://www.google.com/policies/privacy">YouTube (Google)</a></li>
                                        <li><a href="https://www.tiktok.com/legal/page/row/privacy-policy/en">TikTok</a></li>
                                        <li><a href="https://help.instagram.com/155833707900388">Instagram</a></li>
                                    </ul>
                                    <br/>
                                    These integrations are used exclusively to:
                                    <ul>
                                        <li>Make scheduled social media posts, using clip content and titles/descriptions/captions controlled by you, on your behalf</li>
                                        <li>Collect post statistics (views, likes, comments etc.) for the posts made from Autigma</li> 
                                    </ul>
                                </li>
                                <br/>
                                <li>Additionally:
                                    <ul>
                                        <li>Your social media account integration must be explicitly authorized by you for the Autigma application. </li>
                                        <li>You can revoke Autigma access to any social media account either through the Autigma Socials page by deleting the linked social or from the relevant social account management page (as described under "Information we Collect"). This will remove all data stored for that social on Autigma.</li>
                                        <li>We do not share any social media account information, post data or statistics with any third party for any reason.</li>
                                        <li>If you have additional queries in terms of how we use your social media data or believe we are using your social media data inappropriately, please contact us via the Support Page or the contact details or the contact details at the end of this document..</li>
                                        <li>If you delete a social media account or linkage, all data stored on Autigma for related posts will also be deleted.</li>                                        
                                    </ul>
                                </li>
                            </ul>
                        <dt>Information Security - We are committed to ensuring the security of your information and take appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction.</dt>
                        <dt>Data Sharing and Disclosure - We do not sell, trade, or otherwise transfer your personal information to third parties. It may be necessary for trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you, to have access to your information, but such parties must agree to keep this information confidential.</dt>
                        <dt>Data Retention - We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</dt>
                        <dt>Your Rights - You have the right to access, correct, or delete your personal information at any time. You may also opt-out of receiving non-essential communications from us. Should you wish to delete your entire Autigma user account and all related data, please contact us at <a href="mailto:support@autigma.com">support@autigma.com</a> so that we can perform the account deletion.</dt>
                        <dt>Changes to This Privacy Policy - We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website.</dt>
                    </dl>
                    Contact Us via the Support page or <a href="mailto:support@autigma.com">support@autigma.com</a> if you have any questions or concerns about this Privacy Policy or our data practices.
                    <br/>
                    <br/>
                    <br/>
                </div>                
            </div>
        </div>
    );
}