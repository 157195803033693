import {React, useEffect, useState} from "react";
import { useSelector, useDispatch, } from "react-redux";
import {setPaymentStage, setPaymentError, setAmount} from "./paymentSlice";
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, Container, Row, Col } from "reactstrap";
import { useGetSubscriptionBalancesQuery } from "./subscriptionAPISlice";
   

const PaymentCompleteModal = () => {

    const dispatch = useDispatch();
    const orderAmount = useSelector((state) => state.payment.amount) 
    const paymentStage = useSelector((state) => state.payment.paymentStage)  
    const subscriptionType = useSelector((state) => state.payment.subscriptionType)
    const {refetch} = useGetSubscriptionBalancesQuery();

    function CloseModal()
    {
        dispatch(setPaymentError(""))
        dispatch(setPaymentStage(0))
        dispatch(setAmount(0))
        refetch();
    }

    return (
        <Modal size='lg' isOpen={paymentStage===3} centered={true}>                   
            <Form> 
                <ModalHeader>
                    {subscriptionType === 1 ?
                            <>Purchase Autigma Credit - Complete</>     
                        :   
                            <>Purchase Autigma Subscription - Complete</>           
                    }
                </ModalHeader>
                <ModalBody>
                    {subscriptionType === 1 ?                        
                            <>Transaction successful! Your account has been credited with {orderAmount} USD.</>
                        :
                            <>Transaction successful. Your new subscription has been activated.</>
                    }
                </ModalBody>
                <ModalFooter>                                
                    <Button onClick={()=>CloseModal()}>Close</Button>
                </ModalFooter>                                       
            </Form>                                            
        </Modal>          
    );
};

export default PaymentCompleteModal;