import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    tier: 0,
    free_tier_available: false,
    status:"",
    cancellation_date:"",
    token_balance:0
}

const subscriptionSlice = createSlice({
    name:'subscription',
    initialState,
    reducers: {        
        setSubscription: (state, action) => {            
            return action.payload.data
         },
        resetSubscription: () => {
            return initialState
        },
        setTokenBalance: (state, action) => {
            return {
                ...state,
                token_balance: action.payload
            }            
        }        
    }
})

export const {setSubscription, resetSubscription, setTokenBalance} = subscriptionSlice.actions
export default subscriptionSlice.reducer