import {apiSlice} from '../../app/api/apiSlice'

export const scheduleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchedule: builder.query({
            query: (data) => ({
                url: `/schedule_items/weekly?product=${data.productId}&referenceDate=${data.referenceDate.replace("+", "%2B")}&nowDate=${data.nowDate.replace("+", "%2B")}`,
                method: 'GET',                                                            
            }),
            providesTags: ['Schedule']
        }),
        createSchedule: builder.mutation({
            query: (schedule) => ({
                url: '/schedule_items/bulk_create_update/',
                method: 'POST',
                body: schedule,
            }),
            invalidatesTags: ['Schedule', 'Dashboard']
        }),   
        deleteSchedule: builder.mutation({
            query: ([scheduleItemId, scheduleDate, product]) => ({
                url: '/schedule_items/delete_schedule/',
                method: 'POST',
                body: {"id":scheduleItemId, "date":scheduleDate, "product":product}
            }),
            invalidatesTags: ['Schedule', 'Dashboard']
        }),     
        insertUpdateScheduleItem: builder.mutation({
            query: (scheduleItem) => ({
                url: '/schedule_items/insert_update_schedule/',
                method: 'POST',
                body: scheduleItem
            }),            
            invalidatesTags: ['Schedule', 'Dashboard']
        }),    
    })
})


export const {
    useGetScheduleQuery,
    useCreateScheduleMutation,
    useDeleteScheduleMutation,
    useInsertUpdateScheduleItemMutation,
} = scheduleApiSlice