import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardComponent from 'features/dashboard/Dashboard';

class Dashboard extends Component {            
    render() {                                
        return (
            <div>                
                <div className="plr-15">
                    <div className="mtb-30 theme-color">                        
                        <DashboardComponent {...this.props} />      
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Dashboard);