import {React, useEffect, useState} from "react";
import { useSelector, useDispatch, } from "react-redux";
import LoaderComponent from "components/common/LoaderComponent";
import { setSubscription } from "./subscriptionSlice";
import { useGetSubscriptionMutation, useCancelSubscriptionMutation, useGetTokenCostMutation, useGetSocialPostCostMutation } from "./subscriptionAPISlice";   
import { useGetVideoCostMutation } from "../fileUpload/fileUploadAPISlice"; 
import moment from "moment";

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, Spinner} from "reactstrap";

import PaymentPayPalModal from './PaymentPayPalModal'
import PaymentCompleteModal from './PaymentCompleteModal'
import {setPaymentStage, setSubscriptionType} from "./paymentSlice";
import SubscriptionConfirmModal from "./SubscriptionConfirmModal";
import { selectCurrentToken } from '../authentication/authenticationSlice'
import ReactGA from 'react-ga4'

const SubscriptionHandler = () => {
    
    const dispatch = useDispatch();
    const apiToken = useSelector(selectCurrentToken); 
    const subscription = useSelector((state) => state.subscription)
    const [getPrepaidTokenCost] = useGetTokenCostMutation()   
    const [getSubscription] = useGetSubscriptionMutation()    
    const [cancelSubscription] = useCancelSubscriptionMutation()
    const [isLoading, setIsLoading] = useState(true) 
    const [showCancelModal, setShowCancelModal] = useState(false) 
    const [prepaidTokenCost, setPrepaidTokenCost] = useState(false) 
    const [cancelError, setCancelError] = useState(null) 
    const [getVideoCost] = useGetVideoCostMutation()  
    const [getSocialPostCost] = useGetSocialPostCostMutation()
    const [videoCost, setVideoCost] = useState(0)
    const [socialPostCost, setSocialPostCost] = useState(0)
    const webApiUrl = process.env.REACT_APP_WEBAPI_URL    
    const [isCancelling, setIsCancelling] = useState(false)
    
    const TIER_PREPAID = 1;
    const TIER_SUB_1 = 2;
    const TIER_SUB_2 = 3;
    const TIER_SUB_3 = 4;

    const SUB_STATUS_CREATED = 1;
    const SUB_STATUS_EXPIRED = 3;

    const EXAMPLE_VIDEO_LENGTH_MINUTES = 5
    const EXAMPLE_VIDEO_CLIP_LENGTH_SECONDS = 30

    
    useEffect(() => {
        FetchData();            
    },[])

    async function FetchData() {
        setIsLoading(true)

        const result = await getSubscription();    
        dispatch(setSubscription(result));    
        
        const prepaidCost = await getPrepaidTokenCost();
        setPrepaidTokenCost(prepaidCost.data);

        const videoCost = await getVideoCost({"videoLength": EXAMPLE_VIDEO_LENGTH_MINUTES*60, "clipInterval": EXAMPLE_VIDEO_CLIP_LENGTH_SECONDS});
        
        if (videoCost.data) {
            setVideoCost(videoCost.data["tokenCost"]);            
        }
        else
        {
            setVideoCost(0);            
        }     
        
        const socialPostCost = await getSocialPostCost();
        
        if (socialPostCost.data) {
            setSocialPostCost(socialPostCost.data["token_cost"]);            
        }
        else
        {
            setSocialPostCost(0);            
        }     

        setIsLoading(false)
    }    
    
    async function CancelSubscription() 
    {     
        setIsCancelling(true)

        var cancelUrl = `${webApiUrl}user_subscription/subscription_cancel_recurring/`;

        analyticsEvent("CancelClicked", "CancelSubscription")

        const response = await fetch(cancelUrl, 
            {
                method : "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Token ${apiToken}`,
                },                
            }
        )

        setIsCancelling(false)
        
        if (response.ok)
        {
            setShowCancelModal(false)
            FetchData()
        }        
        else
        {
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) 
            {
                throw new Error("Subscription cancel failed - invalid server response!");                            
            }
            const data = await response.json();

            setCancelError(data["error"])
        }   
    }


    const analyticsEvent = (action, label) => {
        ReactGA.event({
            category: "Subscription",
            action: action,
            label: label,                        
          });
    }
    
    async function OnPlanClicked(plan)
    {        
        if (subscription.tier === plan && plan > TIER_PREPAID)
        {            
            setShowCancelModal(true)
            analyticsEvent("SubscribeClicked", "Pre-Paid")
        }
        else
        {
            dispatch(setSubscriptionType(plan))
            dispatch(setPaymentStage(1))                  
            analyticsEvent("SubscribeClicked", `Subscription tier - ${plan}`)  
        }
    }   

    function GetButtonText(plan_tier) {

        if (subscription.tier === plan_tier)
        {
            return "Cancel Subscription"            
        }
        else if(subscription.tier === TIER_PREPAID)
        {
            return "Subscribe"
        }
        else if(subscription.tier > plan_tier)
        {
            return "Downgrade"
        }
        else if(subscription.tier< plan_tier)
        {
            return "Upgrade"
        }
    }

    function CancelModal()
    {
        return (
            <div>
                <Modal size='lg' isOpen={showCancelModal} centered={true}>                   
                    <Form> 
                        <ModalHeader>
                            <>Cancel Autigma Subscription</>                                                                                            
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row">
                                
                                    {isCancelling ?
                                        <div>
                                            <div className="row">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Spinner color="primary" animation="border" role="status"/>                
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    Cancelling subscription. One moment...                         
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className="col-lg-12">
                                            If you cancel your active subscription, your recurring subscription payment will be cancelled. Upon cancellation, 
                                            your account will immediately revert to a Prepaid account and any existing subscription balance will be retained but
                                            all future operations will be charged at the standard Prepaid rates.
                                        </div>
                                    }
                                
                            </div>  
                            <div className="row">
                                <div className="error-msg" style={{overflowWrap: "break-word",  justifyContent: 'left', alignItems: 'left' }}>
                                    {cancelError}             
                                </div>
                            </div>                                                                                            
                        </ModalBody>
                        <ModalFooter>    
                            <Button disabled={isCancelling} onClick={()=>CancelSubscription()}>Cancel subscription</Button>
                            <Button disabled={isCancelling} onClick={()=>setShowCancelModal(false)}>Close</Button>
                        </ModalFooter>                                       
                    </Form>                                            
                </Modal>                    
            </div>
        );
    }

    function RecurringSubscriptionInfo(plan)
    {
        return (
            (subscription.tier === plan) ? 
                (subscription.status === SUB_STATUS_CREATED) ? 
                    <div className="center fs-14">Renews { moment(subscription.cancellation_date).format("MMMM Do YYYY")}</div>
                : (subscription.status === SUB_STATUS_EXPIRED) ?
                    <div className="center cancelation-pending fs-14">Expired</div>
                    :
                        null                
            :
                null
        );
    }
             
    return (
        <div>            
            {isLoading ?  
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                    <LoaderComponent loading={isLoading}/>
                </div> 
                :
                <div className="pricing-detail">   
                    <div className="container pricing-page">                                                       
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="pricing-card ptb-24">
                                    <div className="fs-34">Prepaid</div>
                                    <div className="fs-16 mb-16">
                                        Purchase Tokens on demand as you need them                                
                                    </div>
                                    <div className="pink-highlight mb-20">
                                        <span className="fs-34">
                                            <sup>$</sup> 0
                                        </span>{" "}                                
                                        monthly
                                    </div>
                                    <div className="list fs-14" style={{height:"200px"}}>
                                        <div className="mb-16">Pay as you use</div>                                                                                                    
                                        <div className="mb-16">Market one game</div>    
                                        <div className="mb-16">Unlimited social accounts</div>                                                                       
                                        <div className="mb-16">50 tokens = $1.00</div>              
                                    </div>                                    
                                    <div className="pt-10">
                                        <button onClick={() => OnPlanClicked(TIER_PREPAID)} className='pricing-button'>
                                            <div>Buy Credit</div>
                                        </button>
                                    </div>  
                                </div>
                            </div>
                        
                            <div className="col-lg-4">
                            <div className="pricing-white-card ptb-24">
                                    <div className="fs-34">Solo</div>
                                    <div className="fs-16 mb-16">    
                                        Great for solo devs looking to market one or two games                            
                                    </div>
                                    <div className="pink-highlight mb-20">
                                        <span className="fs-34">
                                            <sup>$</sup> 30
                                        </span>{" "}                                
                                        monthly
                                    </div>
                                    <div className="list fs-14" style={{height:"200px"}}>
                                        <div className="mb-16">Monthly recurring Token purchase</div>
                                        <div className="mb-16">Market up to two games</div>    
                                        <div className="mb-16">Unlimited social accounts</div>   
                                        <div className="mb-16">Free scheduled social posts</div>
                                        <div className="mb-16">50 tokens = $1.00</div>  
                                    </div>                                    
                                    <div>
                                        <button onClick={() => {OnPlanClicked(TIER_SUB_1)}} className='pricing-button'>
                                            <div>{GetButtonText(TIER_SUB_1)}</div>
                                        </button>                                    
                                    </div>  
                                    <br/>
                                    {RecurringSubscriptionInfo(TIER_SUB_1)}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="pricing-card ptb-24">
                                    <div className="fs-34">Studio</div>
                                    <div className="fs-16 mb-16">      
                                        Best for studios or agencies looking to market multiple games                          
                                    </div>
                                    <div className="pink-highlight mb-20">
                                        <span className="fs-34">
                                            <sup>$</sup> 60
                                        </span>{" "}
                                        monthly
                                    </div>
                                    <div className="list fs-14" style={{height:"200px"}}>
                                        <div className="mb-16">Monthly recurring Token purchase</div>
                                        <div className="mb-16">Market unlimited games</div>    
                                        <div className="mb-16">Unlimited social accounts</div>   
                                        <div className="mb-16">Free scheduled social posts</div>
                                        <div className="mb-16">50 tokens = $0.90</div>                                  
                                    </div>                                    
                                    <div>                                        
                                        <button onClick={() => {OnPlanClicked(TIER_SUB_2)}} className='pricing-button'>
                                            <div>{GetButtonText(TIER_SUB_2)}</div>
                                        </button>                                      
                                    </div>   
                                    <br/>
                                    {RecurringSubscriptionInfo(TIER_SUB_2)}                        
                                </div>
                            </div>

                            {/* <div className="col-lg-4">
                                <div className="pricing-card ptb-24">
                                    <div className="fs-34">Diamond</div>                           
                                    <div className="pink-highlight mb-20">
                                        <span className="fs-34">
                                            <sup>$</sup> 70
                                        </span>{" "}
                                        monthly
                                    </div>
                                    <div className="list fs-14">
                                        <div className="mb-16">All Prepaid features</div>
                                        <div className="mb-16">20% token discount</div>
                                        <div className="mb-16">Prepaid topups at discounted rate</div>
                                        <div className="mb-16 pb-60">50 tokens = $0.80</div>                                          
                                    </div>
                                    <div>
                                        <button onClick={() => {OnPlanClicked(TIER_SUB_3)}} className={'pricing-button'}>
                                            <div>{GetButtonText(TIER_SUB_3)}</div>
                                        </button>                            
                                    </div> 
                                    <br/> 
                                    {RecurringSubscriptionInfo(TIER_SUB_3)}                         
                                </div>
                            </div> */}
                        </div>
                        <div className="row"/>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="row">
                                Video processing cost varies depending on video length and preferred output clip duration. 
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="row">
                                E.g. a {EXAMPLE_VIDEO_LENGTH_MINUTES} minute video with a preferred clip duration of {EXAMPLE_VIDEO_CLIP_LENGTH_SECONDS} seconds costs {videoCost} Tokens to process.
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="row">
                                {socialPostCost > 0 ?
                                    "Scheduled social posts are charged at "+socialPostCost+" Tokens each."
                                :
                                    "Scheduled social posts are free."
                                }
                        </div>
                    </div>                    

                    <CancelModal/>
                    <PaymentPayPalModal/>
                    <PaymentCompleteModal/>        
                    <SubscriptionConfirmModal prepaidTokenCost={prepaidTokenCost}/>     
                </div>   
            }           
                
        </div>
    );
};

export default SubscriptionHandler;