import React, { useEffect, useState } from "react";
import Tutorial from "components/tutorial/Tutorial";
import { useGetDashboardQuery, useGetNewsFeedQuery } from "./dashboardAPISlice";
import LoaderComponent from "components/common/LoaderComponent";
import NewsFeed from "components/news_feed/NewsFeed";
import ScheduleInsights from "components/insights/ScheduleInsights";
import TopPosts from "components/insights/TopPosts";
import EngagementStats from "components/insights/EngagementStats";
import EngagementBarChart from "components/insights/EngagementBarChart";

const Dashboard = (props) => {              
    const { data:dashboardData, isLoading:isDashboardDataLoading} = useGetDashboardQuery(null);
    //Get the news feed every 10 minutes.
    const { data:newsFeed, isLoading:isNewsFeedLoading } = useGetNewsFeedQuery(null);

    return(
        <div>
            {
                (isDashboardDataLoading || isNewsFeedLoading) &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                    <LoaderComponent loading={true} />
                </div>            
            }

            {                
            !isDashboardDataLoading && !isNewsFeedLoading &&
            <div>
                <div>                
                    {   dashboardData?.show_tutorial && 
                            <div className="pb-15">
                                <Tutorial /> 
                            </div>
                    }   
                </div>         

                <div className="row">
                <div className="col-lg-6 col-md-12 pb-15">
                        <ScheduleInsights {...props} this_week_scheduled={dashboardData?.num_scheduled_posts_this_week} last_week_scheduled={dashboardData?.num_scheduled_posts_last_week} this_week_completed={dashboardData?.num_completed_posts_this_week}/>
                    </div>
                
                    <div className="col-lg-6 col-md-12" style={{height:345}}>                    
                        <NewsFeed items={newsFeed}/>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <TopPosts this_week={dashboardData?.top_post_this_week.social_id} last_week={dashboardData?.top_post_last_week.social_id}/>
                    </div>

                    {
                        (dashboardData?.this_week_stats.total_views + dashboardData?.last_week_stats.total_views > 0) &&
                        <div className="col-lg-6 col-md-12">                                  
                            <EngagementBarChart views_this_week={dashboardData?.this_week_stats.total_views} likes_this_week={dashboardData?.this_week_stats.total_likes} shares_this_week={dashboardData?.this_week_stats.total_shares} comments_this_week={dashboardData?.this_week_stats.total_comments} views_last_week={dashboardData?.last_week_stats.total_views} likes_last_week={dashboardData?.last_week_stats.total_likes} shares_last_week={dashboardData?.last_week_stats.total_shares} comments_last_week={dashboardData?.last_week_stats.total_comments}/>                        
                        </div>
                    }
                    
                </div>

                <div>
                    
                </div>

            </div>                                    
            }
        </div>
    )
}

export default Dashboard