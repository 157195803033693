import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { FaDiscord, FaEnvelope } from "react-icons/fa";

class Support extends Component {    


    getFaqData = () => {
        return [
            {
                question: "What is Autigma?",
                description: "Autigma is a marketing assistant designed specifically for independant game developers. It helps you promote your game by allowing you to upload gameplay videos, identify interesting parts, and schedule posts to social media platforms."
            },
            {
                question: "How do I get started?",
                description: "Link a social media account in the socials page, create a product on the products page and upload your gameplay video on the schedule page."
            }, 
            {
                question: "What happens after I upload a video?",
                description: "Based on the settings you chose, Autigma will process the video, and generate social media-read clips with captions for those videos. A schedule based on the current day is automatically populated but you can adjust this as required. When you save the schedule, the social media posts are created automatically for you. You can track stats for those posts on the Schedule page."
            },
            {
                question: "How do Tokens work?",
                description: "You need to purchase Tokens in order to use Autigma. We currently charge Tokens to process a video in order to create clips from it, and per scheduled social post made if you are on the Prepaid Tier. In the case of the video processing, the cost varies according to the length of the video and length of clips you would like to generate. The Token cost will be automatically calculated before you proceed with the video upload. For all operations, the cost varies on the subscription tier. You can see a example processing costs at the bottom of the subscription page, based on your current subscription Tier."
             },
            {
                question: "How do subscriptions work?",
                description: "On registration, you are automatically part of our Prepaid/pay-per-use tier, where you can purchase Tokens used for Autigma operations on an ad-hoc basis. Alternatively, you can opt for a recurring monthly fee that purchases a fixed amount of credit Tokens each month, with some additional benefits. You are able to top up your Token balance with a Prepaid purchase at any time if your Token balance is depleted."
            },
            {
                question: "Can I upgrade or downgrade my subscription?",
                description: "A subscription can be upgraded or downgraded, if possible depending on the current type, at any time, and the charge for the new subscription occurs immediately. Any existing subscription Token balance is carried forward and added to the Tokens purchased through the new subscription. New or pending operations are charged for according to the new subscription discount rate immediately. If downgrading a subscription, there may be limits imposed on the number of games you can use for scheduling if you had more games defined in your account than the subscription allows for."
            },
            {
                question: "Can I cancel my subscription at any time?",
                description: "Yes, you can cancel your subscription at any time. All tokens will remain in your account, and you will be charged at prepaid rates for further operations."
            },           
            {
                question: "How many posts can I schedule?",
                description: "At present, Autigma will allow you to schedule one post per day for any day of the year. The same post may be posted to multiple social platforms per day."
            },
            {
                question: "Is there a limit to the number of videos I can upload?",
                description: "Users have a 10Gb clip limit, you will be allowed to upload videos until your storage limit has been reached. You can clear you storage on the file management page."
            },
            {
                question: "Which social media platforms are supported?",
                description: "Autigma currently supports X (Twitter), YouTube and TikTok. We are working to add additional platforms as required/according to user demand so let us know what you'd like us to target."
            },
            {
                question: "What resolution will my videos be posted at?",
                description: "Autigma currently downscales all videos to 720 x 1280 (9:16) to keep video sizes manageable and universally portable to all supported social platforms, with focus on posts as short-form video content."
            },
            {
                question: "What is the purpose of the product description and how should I write it?",
                description: "The description should be a summary of your game, such as one you might find on Steam. It should indicate the game genre, and provide a concise overview of the game's main story, player mission(s), experiences and outcomes. We use this description when analysing your videos, and for generating related captions."
            },
            {
                question: "What are product caption themes?",
                description: "Caption themes are keywords or short descriptors up to 30 characters you can specify to modify the way a caption might be generated. For example, if you specify the caption theme 'Funny', the generated caption will attempt to make the caption humourous. Results vary depending on the theme and how it relates to the video."
            },
            {
                question: "What are the Q and R numbers shown on my clips?",
                description: "These values are (Q)uality and (R)elevance rating percentages and are based on analysis of the clip content. The Relevance rating indicates how aligned the clip content is with the game's genre/description provided. The Quality rating represents the clip's overall presentation quality. Reasons for the rating are usually available by hovering over the rating. Autigma prioritises scheduling clips with higher ratings."
            },
            {
                question: "Why doesn't Autigma have feature X?",
                description: "Good question, let's hear about it! Drop us an email from this page or join and send us a message on the Autigma Discord server. We have lots of additional features in the pipeline and ideas we want to implement, but we want to know what would help you most so let us know!"
            }
        ]
    }

    getFaq = () => {
        return this.getFaqData().map((faq, index) => (
            <div className="ptb-15 center" key={index}>
                <div className="row card" style={{ background: this.props.layoutTheme.cardBackground, width: "50%", border:"1px solid grey" }}>
                    <div className="col-12 pt-15">
                        <div className="widget-dark-color bold-text fs-30">{faq.question}</div>
                    </div>
                    <div className="col-12 ptb-15">
                        <div className="widget-dark-color fs-20">{faq.description}</div>
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="mtb-30 theme-color">                           
                <div className="row">                        
                    <div className="col-12 center">
                        <div className="col-3">
                            <div className="col-12 center pt-20">                            
                                <h3 className="widget-dark-color">Join our Discord</h3>                                                                                    
                            </div>
                            <div className="col-12 center pt-20">
                                <FaDiscord onClick={() => window.open("https://discord.gg/JZKmXUM8ra")} style={{cursor: "pointer"}} className="widget-dark-color" size={50}/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-12 center pt-20">                            
                                <h3 className="widget-dark-color">Send us an email</h3>                                                                                    
                            </div>
                            <div className="col-12 center pt-20">
                                <FaEnvelope onClick={() => window.open("mailto:support@autigma.com")} style={{cursor: "pointer"}} className="widget-dark-color" size={50}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-45">                        
                    <div className="col-12 center">
                      <h1 className="widget-dark-color">Frequently Asked Questions (FAQ)</h1>                      
                    </div>
                    <div className="col-12">
                        {this.getFaq()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Support);