import React, {useState, useEffect} from 'react';
import { FormGroup, Label } from 'reactstrap';
import { useGetScheduleItemSocialsMutation } from "./scheduleItemSocialApiSlice";
import 'react-datepicker/dist/react-datepicker.css'
import { Spinner } from "reactstrap";
import { faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScheduleItemSocialState = (props) => {
    
    const {
        id,        
        state,
        stateDetails
    } = props      

    const [getScheduleItemSocials] = useGetScheduleItemSocialsMutation();
    const [scheduleItemSocials, setScheduleItemSocials] = useState();  
    const [isFetching, setIsFetching] = useState(true);
    const [statesHaveErrors, setStatesHaveErrors] = useState(false);
    

    useEffect(() => {               
        LoadScheduleItemSocials();
    }, [])

    async function LoadScheduleItemSocials() 
    {
        setIsFetching(true);

        setStatesHaveErrors(false);

        if (id != null && id > 0)
        {           
            const states = await getScheduleItemSocials(id);

            if (states != null)
            {
                if (states.error != null && states.error["status"] !== 200)
                {
                   setScheduleItemSocials(null);                
                }
                else
                {
                    if (states.data != null)
                    {                        
                        for (let i = 0; i < states.data.length; i++)
                        {
                            var state = states.data[i]

                            if (state.state >= 3)
                            {
                                setStatesHaveErrors(true);
                                break;
                            }                            
                        }    

                        setScheduleItemSocials(states.data);
                    }
                    else
                    {
                        setScheduleItemSocials(null);
                    }
                }
            }
            else
            {
                setScheduleItemSocials(null);
            }        
        }
        else
        {
            setScheduleItemSocials(null);
        }

        setIsFetching(false)
    }    

    function GetStateTextColour(state)
    {
        switch (state)
        {
            case 3 : return "fs-14 medium-text mb-10 label2--text"
            case 4 : return "fs-14 medium-text mb-10 label2--text"
            default : return "fs-15 bold-text widget-dark-color mtb-10"
        }
    }

    function FormatSocialStats(socialType, socialPostStats)
    {
        if (socialPostStats !== null && socialPostStats !== "")
        {
            try
            {
                var statsData = JSON.parse(socialPostStats);

                switch (socialType)
                {
                    case 1:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-heart"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Retweets" className="fas fa-retweet"></i>&nbsp;{statsData.retweets}&nbsp;&nbsp;
                                <i title="Replies" className="fas fa-comment"></i>&nbsp;{statsData.replies}&nbsp;&nbsp;                               
                            </>
                        );
                    case 2:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-thumbs-up"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Dislikes" className="fas fa-thumbs-down"></i>&nbsp;{statsData.dislikes}&nbsp;&nbsp;
                                <i title="Comments" className="fas fa-comment"></i>&nbsp;{statsData.comments}&nbsp;&nbsp;                               
                            </>
                        );
                    case 3:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-thumbs-up"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Shares" className="fas fa-share"></i>&nbsp;{statsData.shares}&nbsp;&nbsp;
                                <i title="Comments" className="fas fa-comment"></i>&nbsp;{statsData.comments}&nbsp;&nbsp;                               
                            </>
                        );
                    case 4:
                    default:
                        return null; //not implemented
                }
            }
            catch(e)
            {
                return null; 
            }
        }
        else
        {
            return "No stats available";
        }
    }

    function GetSocialIcon(socialType){
        switch (socialType)
                {                    
                    case 1:
                        return <FontAwesomeIcon icon={faXTwitter} />;
                    case 2:     
                        return <FontAwesomeIcon icon={faYoutube} />;    
                    case 3:
                        return <FontAwesomeIcon icon={faTiktok} />;        
                    case 4:
                        return <FontAwesomeIcon icon={faInstagram} />;        
                    default:
                        return null; //not implemented
                }
        
    }

    const getErrorStateDetails = (item) => {        
        return (
            <><i className='pr-10'>{GetSocialIcon(item.socialType)}</i>{item.stateDetails}</>
        )
    }

    function GetSocialStateDetails()
    {        
        if (isFetching)
        {
            return (
                <div>
                    <Spinner color="primary" animation="border" role="status"/> 
                </div>
            )
        }
        else
        {
            if (scheduleItemSocials !== null && scheduleItemSocials?.error == null)
            {
                const sortedSocials = [...scheduleItemSocials].sort((a, b) => a.socialType - b.socialType);
                return (    
                    <div>
                        {sortedSocials.map(item=>                                                                                     
                            <div key={item.id} className={GetStateTextColour(item.state)}>                                                        
                                {item.state >= 3 ? (
                                    getErrorStateDetails(item)
                                ): (
                                    <><a style={{color:'white', textDecoration:'none'}} href={item.socialPostUrl} target="_blank" rel="noreferrer" title='View post' className='cursor-pointer'><i className='pr-10'>{GetSocialIcon(item.socialType)}</i>{FormatSocialStats(item.socialType, item.socialPostStats)}</a></>
                                )}                       
                            </div>
                        )}
                    </div>       
                )
            }
            else
            {
                return null;
            }
        }
    }   

    return (
        state === 2 ? 
        (
            <div >
                <FormGroup>                
                    {GetSocialStateDetails()}                      
                </FormGroup>
            </div>
        )  : 
        (   
            state >= 3 ?                                                                 
                 <div >
                    {statesHaveErrors ?
                        GetSocialStateDetails()
                    :
                        <FormGroup>
                            <Label className={GetStateTextColour(state)} for={"txtstateDetails_"+id}>{stateDetails}</Label> 
                        </FormGroup> 
                    }
                </div>
            :                                    
                null                                    
        )  
    );
};

export default ScheduleItemSocialState;