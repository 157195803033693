import {apiSlice} from '../../app/api/apiSlice'

export const socialsAPISlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        twitterConnectv1Start: builder.mutation({
            query: () => ({
                url: '/socials/twitter_connect_v1_start/',
                method: 'GET',                
            }),                                   
        }),
        twitterConnectv1Complete: builder.mutation({
            query: responseData => ({
                url: '/socials/twitter_connect_v1_complete/',
                method: 'POST',
                body: {'responseUrl':responseData[0], 'oauthToken':responseData[1], 'oauthTokenSecret':responseData[2]},                
            }),
            invalidatesTags: ['Socials']            
        }),
        twitterConnectv2Start: builder.mutation({
            query: () => ({
                url: '/socials/twitter_connect_v2_start/',
                method: 'GET',                
            }),                   
        }),
        twitterConnectv2Complete: builder.mutation({
            query: requestData => ({
                url: '/socials/twitter_connect_v2_complete/',
                method: 'POST',
                body: {'responseUrl':requestData[0], 'codeVerifier':requestData[1]}
            }),
            invalidatesTags: ['Socials']          
        }),
        twitterGetTweetStats: builder.mutation({
            query: requestData => ({
                url: '/socials/twitter_get_tweet_stats/',
                method: 'POST',
                body: {'tweetIds':requestData}
            }),              
        }),
        youtubeConnectStart: builder.mutation({
            query: () => ({
                url: '/socials/youtube_connect_start/',
                method: 'GET',                
            }),                           
        }),
        youtubeConnectCompleteCreateTokens: builder.mutation({
            query: requestData => ({
                url: '/socials/youtube_connect_complete_createTokens/',
                method: 'POST',
                body: {'responseUrl':requestData[0], 'state':requestData[1]}
            }),
            invalidatesTags: ['Socials']           
        }),        
        youtubeRevokeToken: builder.mutation({
            query: (channelId) => ({
                url: '/socials/youtube_connect_revoke_token/',
                method: 'POST',      
                body: {'channelId':channelId}          
            }),                 
        }),
        tikTokConnectStart: builder.mutation({
            query: () => ({
                url: '/socials/tiktok_connect_start/',
                method: 'GET',                
            }),           
        }),
        tikTokConnectCompleteCreateTokens: builder.mutation({
            query: requestData => ({
                url: '/socials/tiktok_connect_complete_createTokens/',
                method: 'POST',
                body: {'responseUrl':requestData[0], 'state':requestData[1], 'code_verifier':requestData[2]}
            }),
            invalidatesTags: ['Socials']           
        }),        
        tikTokRevokeToken: builder.mutation({
            query: (socialId) => ({
                url: '/socials/tiktok_connect_revoke_token/',
                method: 'POST',      
                body: {'socialId':socialId}          
            }),        
        }),
        socials_get: builder.query({
            query: () => ({
                url: '/socials/',
                method: 'GET',                
            }),
            providesTags:['Socials', 'Products']
        }),
        socials_add: builder.mutation({
            query: (social) => ({
                url: `/socials/`,
                method: 'POST',       
                body: {'socialId':social.socialId, 'name':social.name, 'type':social.type, 'properties':social.properties}                         
            }),
            invalidatesTags: ['Socials']                    
        }),
        socials_update: builder.mutation({
            query: (social) => ({
                url: `/socials/${social.id}/`,
                method: 'PUT',     
                body: {'socialId':social.socialId, 'name':social.name, 'type':social.type, 'properties':social.properties}                             
            }),
            invalidatesTags: ['Socials']  
        }),
        socials_delete: builder.mutation({
            query: (id) => ({
                url: `/socials/${id}/`,
                method: 'DELETE',                                
            }),
            invalidatesTags: ['Socials']                        
        }),
        social_GetIsLinkedByProduct : builder.query({
            query: (product_id) => ({
                url: `/socials/get_is_linked_by_product?product_id=${product_id}`,
                method:'GET',
            }),
            providesTags: ['Socials']
        })        
    })
})


export const {
    useTwitterConnectv1StartMutation,
    useTwitterConnectv1CompleteMutation,
    useTwitterConnectv2StartMutation,
    useTwitterConnectv2CompleteMutation,
    useYoutubeConnectStartMutation,
    useYoutubeConnectCompleteCreateTokensMutation,
    useYoutubeRevokeTokenMutation,
    useTikTokConnectStartMutation,
    useTikTokConnectCompleteCreateTokensMutation,
    useTikTokRevokeTokenMutation,
    useSocials_getQuery,
    useSocials_addMutation,
    useSocials_updateMutation,
    useSocials_deleteMutation,
    useSocial_GetIsLinkedByProductQuery
} = socialsAPISlice