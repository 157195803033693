import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data: [],
    selectedProduct: null,
    productTypeData: null
}

const productsSlice = createSlice({
    name:'products',
    initialState,    
    reducers: { 
        setProducts: (state, action) => {
            return{
                ...state,
                data:action.payload
            }
        }, 
        setSelectedProduct: (state, action) => {
            return {
                ...state,
                selectedProduct: action.payload
            }
        },
        resetProduct: () => {
            return initialState
        },   
        setProductTypeData: (state, action) => {
            return {
                ...state,
                productTypeData: action.payload
            }
        },     
    }
})

export const {setProducts, setSelectedProduct, resetProduct, setProductTypeData} = productsSlice.actions
export default productsSlice.reducer
export const selectProducts = state => state.products.data
export const selectProductTypeData = state => state.products.productTypeData
