import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label } from "reactstrap";
import WeekSchedule from 'features/schedule/WeekSchedule';
import {SchedulWrapper, CenteredChildrenWrapper} from './schedule.style';

import FileUpload from 'features/fileUpload/FileUpload';
import ProductList from 'features/products/ProductList';
import ProductSocialsBanner from 'features/socials/ProductSocialsBanner';

class Schedule extends Component {    
    render() {   

        return (
            <SchedulWrapper>                  
                <div className="mtb-30 theme-color">                
                    <CenteredChildrenWrapper>
                        <div style={{display:"flex"}}>                                                                                    
                            <div className='pl-20'>                                
                                <ProductList />                                                                                    
                            </div>
                            <div className='pl-20'>
                                <FileUpload />                                                         
                            </div>
                        </div>
                    </CenteredChildrenWrapper>                   
                    <WeekSchedule {...this.props}/>                
                </div>
            </SchedulWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Schedule);