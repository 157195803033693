import {apiSlice} from '../../app/api/apiSlice'


export const scheduleItemSocialApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({        
        getScheduleItemSocials: builder.mutation({
            query: (scheduleItemId) => ({
                url: '/scheduleitem_social/get_by_scheduleItemid/',
                method: 'POST',            
                body: {"scheduleItemId": scheduleItemId}   
            })
        }),
        getScheduleItemSocial: builder.mutation({
            query: (scheduleItemSocialId) => ({
                url: `/scheduleitem_social/${scheduleItemSocialId}/`,
                method: 'GET',                            
            })
        }),
        addScheduleItemSocial: builder.mutation({
            query: (scheduleItemId, socialId, caption, properties) => ({
                url: '/scheduleitem_social/',
                method: 'POST',            
                body: {"scheduleItemId": scheduleItemId, "socialId": socialId, "caption": caption, "properties": properties}   
            }),
            invalidatesTags: ['ScheduleItemSocial']    
        }),
        deleteScheduleItemSocial: builder.mutation({
            query: (scheduleItemSocialId) => ({
                url: `/scheduleitem_social/${scheduleItemSocialId}/`,
                method: 'DELETE',                            
            }),
            invalidatesTags: ['ScheduleItemSocial']    
        }),
        updateScheduleItemSocial: builder.mutation({
            query: (scheduleItemSocialId, caption, properties) => ({
                url: `/scheduleitem_social/${scheduleItemSocialId}/`,
                method: 'PATCH',                            
                body: {'caption': caption, 'properties': properties}
            }),
            invalidatesTags: ['ScheduleItemSocial']    
        }),
    })
})


export const {
    useGetScheduleItemSocialsMutation,
    useGetScheduleItemSocialMutation,
    useAddScheduleItemSocialMutation,
    useUpdateScheduleItemSocialMutation,
    useDeleteScheduleItemSocialMutation,
} = scheduleItemSocialApiSlice