import React, { Fragment } from 'react';

const LoaderComponent = props => {

    const {text} = props

    return (
        <Fragment>
            {
                props.loading &&
                <div>
                    <div className="tbl-loader center">
                    <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />                                                
                    </div>
                    </div>
                    {text && <div className='pt-50'><div className='center'>{text}</div></div>}
                </div>
                
            }
        </Fragment>
    );
};

export default LoaderComponent;