import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    amount: 0,
    subscriptionType: 1,
    paymentStage: 0,
    paymentError: ""
}

const paymentSlice = createSlice({
    name:'payment',
    initialState,
    reducers: {        
        setAmount: (state, action) => {  
            return {
                ...state,
                amount: action.payload
            }                      
         },
        setSubscriptionType: (state, action) => {
            return {
                ...state,
                subscriptionType: action.payload
            }            
        },  
        setPaymentStage: (state, action) => {
            return {
                ...state,
                paymentStage: action.payload
            }            
        }, 
        setPaymentError: (state, action) => {
            return {
                ...state,
                paymentError: action.payload
            }            
        },            
    }
})

export const {setAmount, setSubscriptionType, setPaymentStage, setPaymentError} = paymentSlice.actions
export default paymentSlice.reducer