import {useState, useEffect} from "react";
import Select from "react-select";
import { useProducts_getQuery } from "./productsAPISlice";
import ActionButtonModal from "components/modals/ActionButtonModal";
import { useNavigate  } from 'react-router-dom';
import { setSelectedProduct, setProducts } from "./productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from 'reactstrap';

const ProductList = (props) => {
    const dispatch = useDispatch();
    const {data: productsData, isLoading: isLoadingProducts} = useProducts_getQuery()
 
    let selectedProduct = useSelector((state) => state.products.selectedProduct)
    const navigate = useNavigate();    
    const [showProductsModal, setShowProductsModal] = useState(false)       
    const [isLoading, setIsLoading] = useState(false) 

    var productOptions = productsData?.reduce(function(filtered, product)
    {
        if (product.active)
        {
            filtered.push(product)
        }

        return filtered;
    }, []).map((x) => {
        var productOption = {
            value: x.id, label: x.name
        }
        return productOption;
    })

    const handleProductChange = (product) => {        
        dispatch(setSelectedProduct(product))
        if(props.selectionChanged){
            props.selectionChanged(product)
        }
        
    };    

    const handleGoToProductManagePageClick = () => {
        navigate('/ProductManagement')
    }

    return (
        <div>
            {isLoading ? 
                <Spinner animation="border" role="status"/>  
            :
                <div>
                    <ActionButtonModal 
                        title={"No Products Defined"} 
                        getBody={() => {return <div>"We need to add a product that we can create a schedule for!"</div>}} 
                        isOpen={showProductsModal} 
                        buttonDescription={"Manage Products"}
                        onActionPressed={handleGoToProductManagePageClick}
                    />      
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} id="socials">            
                        <div style={{width:250}}>
                            <Select                    
                                placeholder="Select Product"
                                onChange={handleProductChange}
                                options={productOptions}             
                                value={selectedProduct}   
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>            
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductList