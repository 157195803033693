import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/authentication/authenticationSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_WEBAPI_URL,
    credentials: 'include',        
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token
        if(token){
            headers.set("authorization", `Token ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if(result?.error?.status === 401){        
        api.dispatch(logOut())
        // console.log('sending refresh token')
        // //send refresh token to get new access token
        // const refreshResult = await baseQuery('/refresh', api, extraOptions)
        // console.log(refreshResult)
        // if(refreshResult?.data) {
        //     const user = api.getState().auth.user
        //     //store new token
        //     api.dispatch(setCredentials({
        //         ...refreshResult.data, 
        //         user
        //     }))
        //     //retry original
        //     result = await baseQuery(args, api, extraOptions)
        // }
        // else{
        //     api.dispatch(logOut())
        // }
    }
   

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: ['Socials', 'Products', 'Clips', 'Balances', 'News', 'Schedule', 'Dashboard']
})