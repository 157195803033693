import {createSlice} from '@reduxjs/toolkit'

//this is used specifically to provide state to the social to link during the Oauth process
const socialToLinkSlice = createSlice({
    name:'socialToLink',
    initialState: 
    {
        social: null    
    },
    reducers: { 
        setSocialToLink: (state, action) => {
            state.social = action.payload
        }, 
    }
})

export const {setSocialToLink} = socialToLinkSlice.actions
export default socialToLinkSlice.reducer
export const selectSocialToLink = state => state.socialToLink.social
