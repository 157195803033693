import {React, useState, useEffect} from "react";
import { useSelector, useDispatch, } from "react-redux";
import LoaderComponent from "components/common/LoaderComponent";
import {useFormikContext, Formik, Form, useField} from "formik"
import * as Yup from "yup";
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useProducts_addMutation, useProducts_getQuery, useProducts_updateMutation, useProducts_deleteMutation, useProducts_product_type_data_getQuery, useProducts_get_allowed_product_countQuery } from "./productsAPISlice";
import { setProducts } from "./productsSlice";
import { useNavigate } from "react-router-dom";
import CaptionThemeEditor from "./CaptionThemeEditor";

import { useSocials_getQuery } from "./../socials/socialsAPISlice";
import {Product} from "types"
import './style.css'
import {SOCIAL_TYPE_TWITTER, SOCIAL_TYPE_YOUTUBE, SOCIAL_TYPE_TIKTOK, SOCIAL_TYPE_INSTAGRAM} from "./../socials/Socials"

const Products = () => {    
    const dispatch = useDispatch();    
    const navigate = useNavigate();
    const {data: products, isFetching: isLoadingProducts} = useProducts_getQuery();
    const {data: productTypeData, isFetching: isLoadingProductType} = useProducts_product_type_data_getQuery(); 
    const {data: socials, isFetching: isLoadingSocials} = useSocials_getQuery();   
    const {data: products_allowed_product_count, isFetching: isProductsAllowedProductCountLoading} = useProducts_get_allowed_product_countQuery();
    
    const [productToEdit, setProductToEdit] = useState(new Product());
    const [configModalShow, setConfigModalShow] = useState(false);
    //const [configIsEdit, setConfigIsEdit] = useState(false);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const [products_add] = useProducts_addMutation(); 
    const [products_update] = useProducts_updateMutation(); 
    const [products_delete] = useProducts_deleteMutation(); 
    
    function GetSocialTypeName(socialType)
    {
        switch (socialType)
            {
                case SOCIAL_TYPE_TWITTER:                
                    return "X"                    
                case SOCIAL_TYPE_YOUTUBE:
                    return "YouTube"
                case SOCIAL_TYPE_TIKTOK:
                    return "TikTok"
                case SOCIAL_TYPE_INSTAGRAM:
                    return "Instagram"
                default:
                    return "?"
            }   
    }

    const socialOptions = socials?.filter((social) => (social.linked && social.active)).map((social) => ({
        value: social.id, 
        label: `${social.name} (${GetSocialTypeName(social.type)})`,        
      }));

    function nameCompare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
    }    

    async function SubmitEditForm(values) 
    {   
        setIsLoading(true);
        setConfigModalShow(false);                         

        await products_update(values).then(() => {
            setIsLoading(false);            
        })
    } 

    async function SubmitAddForm(values)
    {   
        setConfigModalShow(false);        
        setIsLoading(true);             
                
        await products_add(values).then(() => {
            setIsLoading(false)
        })        
    }  
    
    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a product name'),
        url: Yup.string().url('Please enter a valid URL').notRequired(),
        description: Yup.string().required("Please provide a product description"),
        socials: Yup.array().min(1, "Please select/configure at least one social this product will use")        
    })  

    const ATextInput = ({ label, ...props }) => {

        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]     
        // which we can spread on <input>. We can use field meta to show an error     
        // message if the field is invalid and it has been touched (i.e. visited)
     
        const [field, meta] = useField(props);
     
        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>   
                <div className="col-lg-9">      
                    <input className="form-control react-form-input" {...field} {...props} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>) : null}     
                </div>
            </div>  
          </>     
        );                               
      };
     
    const ATextArea = ({ label, ...props }) => {

        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]     
        // which we can spread on <input>. We can use field meta to show an error     
        // message if the field is invalid and it has been touched (i.e. visited)
     
        const [field, meta] = useField(props);
     
        return (     
          <>
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>     
                <div className="col-lg-9">  
                    <textarea className="form-control react-form-input" {...field} {...props} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>) : null}     
                </div>
            </div>     
          </>     
        );                               
      };


    const AFileInput = ({ label, ...props }) => {

        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]     
        // which we can spread on <input>. We can use field meta to show an error     
        // message if the field is invalid and it has been touched (i.e. visited)
        const {
            values,
            setFieldValue,
          } = useFormikContext();
        const [field, meta] = useField(props);

        const selectImageFile = (e, product) => {
            const reader = new FileReader()
    
            const file = e.target.files[0];

            reader.onload = () => {
    
                setFieldValue(field.name, reader.result)                     
            }
    
            reader.readAsDataURL(file);    
            
        }    
     
        return (     
          <> 
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>  
                <div className="col-lg-9">   
                    <input className="form-control react-form-input" {...props} onChange={(e) => selectImageFile(e, productToEdit)} />                      
                </div>      
            </div>      
          </>     
        );                               
      };
     
     
    const ACheckbox = ({ label, ...props }) => {
     
        // React treats radios and checkbox inputs differently from other input types: select and textarea.     
        // Formik does this too! When you specify `type` to useField(), it will     
        // return the correct bag of props for you -- a `checked` prop will be included     
        // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
     
        const [field, meta] = useField({ ...props, type: 'checkbox' });     
        return (
          <>
            <div className="form-group row">
                <label className="col-lg-3 col-form-label">{label}</label>    
                <div className="col-lg-9">    
                    <input type="checkbox" {...field} {...props}  />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>     
                    ) : null}     
                </div>
            </div>
          </>     
        );
     
      };    

    const MultiSelectCheckbox = ({ label, options, ...props }) => {
        
        const [field, meta] = useField(props);
      
        // Helper function to handle checkbox changes
        const handleCheckboxChange = (optionValue) => {
          const { value } = field;
          const updatedValue = value.some(e => e.id === optionValue)
            ? value.filter((item) => item.id !== optionValue)
            : [...value, {id:optionValue}];
      
          field.onChange({
            target: {
              name: field.name,
              value: updatedValue,
            },
          });
        };
      
        return (
          <>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">{label}</label>
              <div className="col-lg-9">
                {options.length > 0 ? options.map((option) => (
                  <div key={option.value}>
                    <input
                      type="checkbox"
                      id={option.value}
                      key={option.value}
                      {...field}
                      {...props}
                      value={option.value}
                      checked={field.value.some(e=> e.id === option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                    <span>&nbsp;&nbsp;</span>
                    <label htmlFor={option.value}>{option.label}</label>
                  </div>
                )) : 
                <div>
                    There are no configured/active socials!
                </div>
                }
                {meta.touched && meta.error ? (
                  <div className="error-msg">{meta.error}</div>
                ) : null}
              </div>
            </div>
          </>
        );
      };    
     
    const AProductTypeSelect = ({ label, ...props }) => {
     
        const [field, meta] = useField(props);     

        var prodType = JSON.parse(productTypeData)
        var val = prodType[field.value]
        if (productToEdit.hash_tags == undefined || productToEdit.hash_tags === "")
        {
            productToEdit.hash_tags = val.hash_tags
        }

        const handleSelectChange = (e, product) => {
            const newValue = e.target.value;
            
            var newHashTags = product.hash_tags; 
            if (productTypeData != null)
            {
                newHashTags = JSON.parse(productTypeData)[newValue].hash_tags    
            }
                   
            field.onChange({
              target: {
                name: 'hash_tags',
                value: newHashTags,
              },
            });
            field.onChange({
                target: {
                  name: field.name,
                  value: newValue,
                },
            });
          };

        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>                 
                <div className="col-lg-9">                         
                    <select {...field} {...props} onChange={(e) => handleSelectChange(e, productToEdit)} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>     
                    ) : null}     
                </div>
            </div> 
          </>    
        );     
      };   
      
    const ACaptionSchemeSelect = ({ label, ...props }) => {
     
        const [field, meta] = useField(props);     

        const handleSelectChange = (e, product) => {
            const newValue = e.target.value;
            
            field.onChange({
                target: {
                  name: field.name,
                  value: newValue,
                },
              });
          };

        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>                 
                <div className="col-lg-9">                         
                    <select {...field} {...props} onChange={(e) => handleSelectChange(e, productToEdit)} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>     
                    ) : null}     
                </div>
            </div> 
          </>    
        );     
      };  
  
    function ProductEditor()      
    {          
        return (
        <div>
            <Modal size='lg' isOpen={configModalShow} centered={true}>                   
                <Formik initialValues={productToEdit} validationSchema={schema} 
                    onSubmit={(values) => {productToEdit.id > 0 ? SubmitEditForm(values) : SubmitAddForm(values)}}>            
                    {formik => (
                        <Form> 
                            <ModalHeader>
                                {productToEdit.id > 0  ? "Edit Product" : "Add Product"}
                            </ModalHeader>
                            <ModalBody>
                                <>
                                <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ATextInput
                                                label="Product Name"
                                                name="name"
                                                placeholder="Product Name"      
                                                key="name"                                          
                                            />             
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <AProductTypeSelect 
                                                label = "Product Category"
                                                name = "type"
                                                key="type"
                                                className="form-control react-form-input selectpicker"
                                                title="What category does your product fall into?"               
                                                data-style="btn-new">
                                                {
                                                    Object.entries(JSON.parse(productTypeData)).map(([type, data], index) =>
                                                        {
                                                            return (<option key={index} value={type}>{data.typeName}</option>);
                                                        }
                                                    )
                                                }                                                                                               
                                            </AProductTypeSelect>                                              
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ATextArea
                                                label = "Description"
                                                name="description"
                                                key="description"
                                                className="form-control react-form-input"
                                                rows="3"                            
                                                type="text"
                                                title="Describe your product (potentially affects the quality of captions generated)"                                                
                                                placeholder="Describe your product (affects the quality of captions generated)"                                           
                                            />                                              
                                        </div>
                                    </div>    
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ATextArea
                                                label = "Hash Tags"
                                                name="hash_tags"
                                                key="hash_tags"
                                                className="form-control react-form-input"
                                                rows="1"                            
                                                type="text"    
                                                maxLength="50"
                                                title="Optional, but recommended, hash tags to include with social posts. Note that this may reduce the usable post title length on some social platforms (such as Youtube)"                                                
                                                placeholder="Hash tags to include with social posts"                                           
                                            />                                              
                                        </div>
                                    </div>                       
                                    <br/>                                    
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ATextInput
                                                label="Product URL"
                                                name="url"
                                                key="url"
                                                placeholder="Product URL to be used in social media posts"     
                                                title="The product URL that will be included in social media posts to certain platforms"                                                          
                                            />                                         
                                        </div>
                                    </div>     
                                    <br/>                                    
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ACaptionSchemeSelect 
                                                label = "Caption Theme Scheme"
                                                name = "caption_tone_scheme"
                                                className="form-control react-form-input selectpicker"
                                                title="How to apply caption themes to generated clips. Caption themes can be defined below and are used to modify the tone of the generated caption."               
                                                key="caption_tone_scheme"
                                                data-style="btn-new">
                                                <option title="Only use one/the first caption theme" value={1}>Fixed/Single</option>;
                                                <option title="Randomise the caption theme from the list" value={2}>Random</option>;
                                                <option title="Use each caption theme per generated clip in their defined ordered (resets per video analysed)" value={3}>Ordered</option>;
                                            </ACaptionSchemeSelect>                                               
                                        </div>
                                    </div>                     
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <CaptionThemeEditor 
                                                product = {productToEdit}
                                                label = "Caption Themes"
                                                name = "caption_tones"
                                                key="caption_tones"
                                                className="form-control react-form-input selectpicker"
                                                title="List of possible captions themes to use when generating clips"               
                                                data-style="btn-new"                                              
                                                isMulti={true}                                                                                                                                                       
                                                >                                               
                                            </CaptionThemeEditor>                                               
                                        </div>
                                    </div>  
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ACheckbox
                                                label="Allow Caption Emojis"
                                                name="caption_emojis"
                                                key="caption_emojis"
                                                className="form-check-input"
                                                //value="true"                           
                                                checked={formik.values.caption_emojis}
                                                title="Allow generated captions to contain Emojis"     
                                            /> 
                                        </div>
                                    </div>                     
                                    <br/>
                                    <div className="form-group row theme-color">
                                        <div className="col-lg-12">
                                            <AFileInput
                                                label="Product Image"
                                                type="file"
                                                name="image"
                                                key="image"
                                                accept="image/png, image/jpeg"
                                                className="form-control-file"
                                                //onChange={selectImageFile}      
                                                title="A product image, for use on Autigma only"                                                
                                            />
                                        </div>
                                    </div> 
                                    <br/>
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <MultiSelectCheckbox
                                                label="Linked Socials"
                                                name="socials"
                                                key="socials"
                                                options={socialOptions}   
                                                title="Which social accounts will be used for posting content related to this product."                                                  
                                            /> 
                                        </div>
                                    </div>                               
                                    {/* <div className="form-group row">
                                        <div className="col-lg-12">
                                            <ACheckbox
                                                label="Active"
                                                name="active"
                                                key="active"
                                                className="form-check-input"
                                                value="true"                           
                                                checked={formik.values.active}
                                                title="If a product is disabled, it cannot be used for posting content"     
                                            /> 
                                        </div>
                                    </div>   */}
                                </>
                            </ModalBody>
                            <ModalFooter>                                
                                <Button type="submit">Ok</Button>
                                <Button onClick={()=>setConfigModalShow(false)}>Cancel</Button>                                
                            </ModalFooter>                                      
                        </Form>
                    )}
                </Formik>
            </Modal>    
        </div>
        );           
    }   
   
    const productImage = (product) => 
    {
        if (product.image && product.image !== "")
        {
            const image = product.image;      
            
            if (product.active)
                return (<CardImg className="aspect-ratio-image" style={{height:200}} top src={image}/>);
            else
                return (<CardImg className="aspect-ratio-image" style={{height:200, filter: 'grayscale(100%)'}} top src={image}/>); 
        }  
        else
        {
            if (product.active)                
                return (<div style={{height: 200, border: '1px solid lightgrey', borderRadius: '10px', color: '#66ff00'}} className="fas fa-gamepad center fs-100" ></div>);
            else
                return (<div style={{height: 200, border: '1px solid lightgrey', borderRadius: '10px', color: '#66ff00', filter: 'grayscale(100%)'}} className="fas fa-gamepad center fs-100" ></div>);
        }      
    }

    async function HandleDelete()
    {
        setConfirmationModalShow(false);     
        setIsLoading(true);

        const id = productToEdit.id;

        const response = await products_delete(id);
        if (!response.error)
        {
            const index = products.indexOf(productToEdit);
            if (index >= 0)
            {
                var newProducts = [...products]; //make a copy of the immutable products with new element
                newProducts.splice(index,1);

                dispatch(setProducts(newProducts));
            }
        }

        setIsLoading(false);  
    }

    function ConfirmationModal()
    {
        return (
            <Modal
              isOpen={confirmationModalShow}
              centered={true}
            >
              <ModalHeader>
                    Confirm Product Removal  
              </ModalHeader>
              <ModalBody>
                <p>
                    If you remove this product, all related schedules and clips will be removed as well. Your social posts will not be affected. Are you sure you want to remove this product?
                </p>
              </ModalBody>
              <ModalFooter>
                <Button onClick={()=>{HandleDelete()}}>Yes</Button>
                <Button onClick={()=>{setConfirmationModalShow(false)}}>No</Button>
              </ModalFooter>
            </Modal>
          );
    }     
    
    function AddProduct()
    { 
        var product = new Product();

        if (productTypeData != null)
            product.hash_tags = productTypeData[product.type].hash_tags;

        setProductToEdit(product);
        //setConfigIsEdit(false);
        setConfigModalShow(true);  
    }


    function EditProduct(product)
    { 
        setProductToEdit(product);
        //setConfigIsEdit(false);
        setConfigModalShow(true);  
    }

    const isAnythingLoading = () => {        
        return isLoadingProducts || isLoadingProductType || isLoadingSocials || isLoading || isProductsAllowedProductCountLoading;
    }
  
    return (
        <div>
        {isAnythingLoading() ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                <LoaderComponent loading={isAnythingLoading()} text="Fetching Your Products..."/>
            </div>
        ) : (                
            <div>
                <ConfirmationModal
                    show={confirmationModalShow}
                    onHide={() => setConfirmationModalShow(false)}
                /> 
                <ProductEditor/>
                <div className="mlr-0" style={{marginTop: '15px', display: 'flex'}}>                    
                    {products && products.map(product=>
                    <div key={product.id} className="ptb-15 pr-20">            
                        <Card className="c-dark" style={{height: 320,  width: 250}}> 
                            {productImage(product)}
                            <CardBody>
                                <CardTitle className="center bold-text">{product.name}</CardTitle>
                                    {product.active ?
                                        <div style={{float: 'left', color:'white'}} onClick={() => {    
                                            EditProduct(product);   
                                        }} className="mt-10 fs-25 cursor-pointer"><i className="fa fa-edit"/></div>
                                    :
                                        null
                                    }
                                    <div style={{float: 'right'}} onClick={() => {   
                                        setProductToEdit(product);                                             
                                        setConfirmationModalShow(true);
                                    }} className="mt-10 fs-25 cursor-pointer"><i className="fa fa-trash text-danger" /></div>                                          
                                
                            </CardBody>
                        </Card>
                    </div>)}
                    <div key={0} className="ptb-15" >            
                        <Card className="c-dark" style={{height: 320, width: 250}}>
                            <div style={{height: 200, border: '1px solid lightgrey', borderRadius: '10px'}} className="fas fa-gamepad center fs-100" ></div>
                            <CardBody className="c-dark">
                                <CardTitle className="center bold-text">{products_allowed_product_count > products.length ? "Add Your Game Here" : "Need to add more Games?"}</CardTitle>                                     
                                <div className="center">
                                <Button style={{width:50, fontWeight:'bold'}} type="button" onClick={() => {
                                    products_allowed_product_count > products.length ? AddProduct() : navigate('/subscription')                                                       
                                    }} className="c-primary mt-10 fs-18">+
                                </Button> 
                                </div>
                            </CardBody>
                        </Card>
                    </div>                    
                </div>
            </div>
        )}
        </div>
    );
};

export default Products;