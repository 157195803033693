import React from "react";

const NewsFeed = (props) => {
  
  const {items} = props

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="fill-height">
      <div className="roe-card-style fill-height" style={{overflow:'auto'}}>
        <div className="roe-card-body" >
          <div className="flex-x align-center">
            <h4 className="mb-0 fs-18 header bold-text widget-dark-color pt-10">News</h4>           
          </div>

          {items?.map((item) => { return (
            <div key={item.id} className="ptb-15 border-bottom mt-9">            
            <div className="mlr-10 flex-1">
              <p className="mb-1 fs-15 bold-text widget-dark-color">{item.title}</p>
              <small className="flex fs-11 mb-1 text-muted widget-dark-color">{formatDateTime(item.created)}</small>              
              <small className="text-muted widget-dark-color">{item.description}</small>
            </div>
            
          </div>)
          })}

        </div>
      </div>
    </div>
  );
};

export default NewsFeed;
