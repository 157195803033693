import React from "react";
import { useNavigate } from "react-router-dom";
import { StepBox, StepHeader, StepContent, StepSubHeader } from "./TutorialStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faYoutube, faTiktok, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Button} from "reactstrap";

const Tutorial = (props) => {
    const navigate = useNavigate();    

  return (
    <div className="row">
        <div className="center fs-34 bold-text widget-dark-color mtb-10">
            Get Started
        </div>        
        
        <div className="col-4">
            <StepBox>                
                <StepHeader className="widget-dark-color">
                    Step 1
                </StepHeader>
                <div className="row">                        
                        <StepSubHeader >
                            Link Your Social Media Accounts
                        </StepSubHeader>
                </div>
                <StepContent>                    
                    <div className="row">                        
                        <div className="col-3">
                            <FontAwesomeIcon className="widget-dark-color fs-30" icon={faXTwitter} />
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon className="widget-dark-color fs-30" style={{fontSize:30}} icon={faTiktok} />
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon className="widget-dark-color fs-30" icon={faYoutube} />
                        </div>
                        <div className="col-3">
                            <FontAwesomeIcon className="widget-dark-color fs-30" icon={faInstagram} />
                        </div>
                    </div>
                </StepContent>
                <StepContent className="pt-10">
                    <Button type="button" onClick={() => navigate('/SocialManagement')} 
                        className="c-primary mt-10">
                        Take Me There
                    </Button> 
                </StepContent>
            </StepBox>
        </div>
    
        <div className="col-4">
            <StepBox>
                <StepHeader className="widget-dark-color">
                    Step 2
                </StepHeader>
                <div className="row">                        
                        <StepSubHeader >
                            Add Your Product / Game
                        </StepSubHeader>
                </div>
                <StepContent>                    
                    <div className="row">                        
                        <div className="col-3">
                            <i className="fas fa-gamepad widget-dark-color fs-30"></i>
                            {/* <FontAwesomeIcon className="widget-dark-color fs-30" icon={faGamepad} /> */}
                        </div>
                       
                    </div>
                </StepContent>
                <StepContent className="pt-10">
                    <Button type="button" onClick={() => navigate('/ProductManagement')} 
                        className="c-primary mt-10">
                        Take Me There
                    </Button> 
                </StepContent>
            </StepBox>
        </div>
        <div className="col-4">
            <StepBox>
                <StepHeader className="widget-dark-color">
                    Step 3
                </StepHeader>
                <div className="row">                        
                        <StepSubHeader >
                            Upload your gameplay videos
                        </StepSubHeader>
                </div>
                <StepContent>                    
                    <div className="row">                        
                        <div className="col-3">
                            <i className="fas fa-video widget-dark-color fs-30"></i>
                            {/* <FontAwesomeIcon className="widget-dark-color fs-30" icon={faGamepad} /> */}
                        </div>
                       
                    </div>
                </StepContent>
                <StepContent className="pt-10">
                    <Button type="button" onClick={() => navigate('/Schedule')} 
                        className="c-primary mt-10">
                        Take Me There
                    </Button> 
                </StepContent>
            </StepBox>
        </div>
    </div>
  );
};



export default Tutorial