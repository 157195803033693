import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ActionButtonModal = ({isOpen, title, getBody, buttonDescription, onActionPressed}) => {   

    return (
        <Modal
            isOpen={isOpen}            
            className="c-primary"
            backdrop={true}
            >
            <ModalHeader>
                <div style={{color:"grey"}}>
                    {title}
                </div>
            </ModalHeader>
            <ModalBody>
                <div style={{color:"grey"}}>
                    {(isOpen) ? getBody() : null}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="c-primary" onClick={() => onActionPressed()}>
                    {buttonDescription}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ActionButtonModal;