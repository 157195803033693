import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageTitle from 'components/common/PageTitle';
import UserProfile from 'features/profile/UserProfile';

class Profile extends Component {    
    render() {               
        return (
            <div className="mtb-30 theme-color">
                <PageTitle title="sidebar.userprofile" />                                
                <UserProfile/>                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Profile);