import React from "react";
import { useFormik } from "formik";
import { loginBack, autigmaLogo } from "helper/constant";
import enhancer from "./enhancer/RegisterFormEnhancer";
import { useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../../features/authentication/authenticationApiSlice";

const RegisterForm = () => {
    const navigate = useNavigate();
    const [register] = useRegisterMutation();

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            agreeToTerms: false,
        },
        onSubmit: async (values, {setFieldError, setSubmitting}) => {
            if (values.email !== "" && values.password !== "") {
                const data = {
                    "email":values.email,
                    "password":values.password,
                    "first_name":values.firstname,
                    "last_name":values.lastname,                    
                }
                const result = await register(data)         
                setSubmitting(false)       
                if(result.error){
                    if(result.error.data.email){
                        setFieldError("email", "Email address already in use")
                    }
                }
                else{
                    navigate("/login?registered=true");
                }
                
            }
        },
        // Add your validation logic here
        validate: values => {
            const errors = {};

            if (!values.firstname) {
                errors.firstname = "First Name is required";
            }

            if (!values.lastname) {
                errors.lastname = "Last Name is required";
            }

            if (!values.email) {
                errors.email = "Email is required";
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address";
            }

            if (!values.password) {
                errors.password = "Password is required";
            }

            if(!values.agreeToTerms) {
                errors.agreeToTerms = "Please accept the terms & privacy policy"
            }

            return errors;
        },
    });

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };

    const Error = ({ field }) => {
        const field1 = field;
        if ((formik.errors[field1] && formik.touched[field1]) || formik.submitCount > 0) {
            return (
                <span className="error-msg">
                    {formik.errors[field1]}
                </span>
            );
        } else {
            return <span />;
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div>
                <div className="form-container">
                    <div className="login-icon">
                        <img src={autigmaLogo} alt="icon" height="100px" />
                    </div>
                    <div className="login-title">Create Account</div>
                    <form className="pa-24" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                className="form-control react-form-input"
                                id="firstname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="First Name"
                            />
                            <Error field="firstname" />
                        </div>

                        <div className="form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                className="form-control react-form-input"
                                id="lastname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Last Name"
                            />
                            <Error field="lastname" />
                        </div>

                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control react-form-input"
                                id="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Email"
                            />
                            <Error field="email" />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control react-form-input"
                                id="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Password"
                            />
                            <Error field="password" />
                        </div>

                        <div className="form-group">
                            <div className="form-check text-center mtb-16">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agreeToTerms"
                                    onChange={formik.handleChange}
                                />
                                <label
                                    className="form-check-label register-privacy-text"
                                    htmlFor="agreeToTerms"
                                >
                                    Agree to{" "}
                                    {/* <a href="https://autigma.com/Terms">terms & privacy policy</a> */}
                                    <a href="https://autigma.com/terms">terms</a> & <a href="https://autigma.com/privacy">privacy policy</a> 
                                </label>
                                <div className="row">
                                    <Error field="agreeToTerms" />
                                </div>
                                
                            </div>
                        </div>

                        <button disabled={(formik.isSubmitting) ? true : false} type="submit" style={{height:50}} className="btn form-button">
                            {formik.isSubmitting ? 'Registering...' : 'Register'}
                        </button>
                        <div
                            className="text-center link-label"
                            onClick={() => navigate("/login")}
                        >
                            Login ?
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default enhancer(RegisterForm);
