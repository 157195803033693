import Subscription from "views/pages/subscription/Subscription";
import SocialManagement from "views/pages/socials/SocialManagement";
import ProductManagement from "views/pages/products/ProductManagement";
import Schedule from "views/pages/schedule/Schedule";
import FileManager from "views/pages/files/FileManager";
import Profile from "views/Profile"
import Dashboard from "views/pages/dashboard/Dashboard";
import Support from "views/pages/support/Support";

const dashboardRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/socialmanagement", component: <SocialManagement /> },
  { path: "/productmanagement", component: <ProductManagement /> },  
  { path: "/schedule", component: <Schedule /> },  
  { path: "/subscription", component: <Subscription /> },
  { path: "/files", component: <FileManager /> },
  { path: "/profile", component: <Profile /> },
  { path: "/support", component: <Support /> },
];

export default dashboardRoutes;