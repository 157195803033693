import React, {useEffect, useState} from 'react';
import { FormGroup, Label, Input, Spinner, Badge } from 'reactstrap';
import { FaVideo } from "react-icons/fa";
import ReactPlayer from 'react-player'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ClipSelector from 'features/clips/ClipSelector';
import DatePicker from "react-datepicker";
import DatepickerWrapper from "components/forms/alldatepickers/datepicker.style";
import 'react-datepicker/dist/react-datepicker.css'
import ScheduleItemSocialState from './ScheduleItemSocialState';
import { useDeleteScheduleMutation, useInsertUpdateScheduleItemMutation } from "./scheduleApiSlice";
import { faXTwitter, faYoutube, faTiktok, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, set } from 'lodash';
import Select from "react-select";
import { useSocials_getQuery } from "../socials/socialsAPISlice";
import {Alert} from 'reactstrap';

const ScheduleItem = (props) => {       
    const {
            item
        } = props    

    const {data: socialsData} = useSocials_getQuery();

    const youtubePrivacyOptions = [
        { value: 'private', label: 'Private' },
        { value: 'public', label: 'Public' },
        { value: 'unlisted', label: 'Unlisted'}
        ]

    const tikTokPrivacyOptions = [
            { value: 'SELF_ONLY', label: 'Self Only' },
            { value: 'MUTUAL_FOLLOW_FRIENDS', label: 'Mutual Follower/Friends' },
            { value: 'FOLLOWER_OF_CREATOR', label: 'Followers of Creator'},
            { value: 'PUBLIC_TO_EVERYONE', label: 'Public to Everyone'}
        ]

    const [selectedSocialIndex, setSelectedSocialIndex] = useState(0)
    const [clipSelectorOpen, setClipSelectorOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const [deleteSchedule] = useDeleteScheduleMutation();    
    const [updateSchedule] = useInsertUpdateScheduleItemMutation();      
    
    const [date, setDate] = useState(new Date(item.date))
    const [clip, setClip] = useState(cloneDeep(item.clip))    
    const [scheduledItemSocials, setScheduledItemSocials] = useState(cloneDeep(item.scheduleItemSocials))        
    const [validMessage, setValidMessage] = useState("");
    const itemDate = new Date(item.date);
    const [videoDuration, setVideoDuration] = useState();    
    const [initialised, setInitialised] = useState(false);   

    useEffect(() => {
        scheduledItemSocials.forEach((socialItem) => {
            let {valid, message} = props.doValidation(false, item, socialItem)    

            if(!valid){
                setValidMessage(message);                
            }                                   
        })
      
    }, [])

    const getYouTubeLabelByValue = (value) => {
        const option = youtubePrivacyOptions.find(option => option.value === value);
        return option.label;
    }

    const getTikTokLabelByValue = (value) => {
        const option = tikTokPrivacyOptions.find(option => option.value === value);
        if (option != undefined)
            return option.label;
        else
            return 'Select';
    }    

    const UpdateItem = (newClip = null, newDate = null) => {
        const newItem = {...item}
        
        if(newDate){
            newItem.date = newDate;
        }

        if(newClip){
            newItem.clip = newClip;
        }               
             
        newItem.scheduleItemSocials = scheduledItemSocials;
        props.updateItem(newItem);
        newItem.scheduleItemSocials.forEach(si => {
            let {isValid, message} = props.doValidation(false, item, si);
            if(!isValid){
                setValidMessage(message);    
                return;
            }            
        })        

        return newItem;
    }   

    const CaptionChanged = (event, index, itemToUpdate) => {
        const value = event.target.value;
        itemToUpdate.caption = value;        
        scheduledItemSocials[index] = itemToUpdate;        
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TitleChanged = (event, index, itemToUpdate) => {        
        const value = event.target.value;         
        itemToUpdate.properties.title = value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const FilterYouTubeInput = (e, index, itemToUpdate) => {
        const key = e.key; 
        if (itemToUpdate.properties?.invalid_chars && itemToUpdate.properties.invalid_chars !== null)
        {
            if (itemToUpdate.properties.invalid_chars.includes(key))
            {
                e.preventDefault();
            }
        }        
    }

    const YouTubePrivacyChanged = (e, index, itemToUpdate) => {
        const value = e.value; 
        itemToUpdate.properties.privacy_status = value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokPrivacyChanged = (e, index, itemToUpdate) => {
        const value = e.value; 

        if(e.value !== 'PUBLIC_TO_EVERYONE'){
            itemToUpdate.properties.disclosure = false;
        }

        itemToUpdate.properties.privacy_level = value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);        
        UpdateItem();
    }

    const TikTokDuetChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.duet_disabled = !value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokCommentChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.comment_disabled = !value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokStitchChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.stitch_disabled = !value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokCoverChanged = (e, index, itemToUpdate) => {
        var value = parseInt(e.target.value); 

        itemToUpdate.properties.video_cover_timestamp_ms = value;
        scheduledItemSocials[index] = itemToUpdate;                    
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokBrandOrganicChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.brand_organic = value;
        scheduledItemSocials[index] = itemToUpdate;        
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const TikTokBrandedContentChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.branded_content = value;
        scheduledItemSocials[index] = itemToUpdate;          
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    const handleDuration = (duration) => {
        
        let tiktokitem = scheduledItemSocials.find(x => x.socialType === 3)
                
        if(tiktokitem){
            let index = scheduledItemSocials.indexOf(tiktokitem)
            let itemToUpdate = {...tiktokitem};            
            let maxDuration = itemToUpdate.properties.max_video_post_duration_sec;

            if(duration > maxDuration){                                
                scheduledItemSocials[index] = itemToUpdate;        
                setValidMessage("Video duration must be less than " + maxDuration + " seconds");
                UpdateItem();     
            }
        }
    }

    const EnableChanged = (event, index, itemToUpdate) => {
        const value = event.target.checked;
        itemToUpdate.enabled = value;
        scheduledItemSocials[index] = itemToUpdate;
        setScheduledItemSocials(scheduledItemSocials);
        UpdateItem();
    }

    function DateChanged(date){
        let parsedDate = new Date(date)                
        setDate(parsedDate);
        UpdateItem(null, parsedDate);
    }

    const TikTokDisclosureChanged = (e, index, itemToUpdate) => {
        const value = e.target.checked; 
        itemToUpdate.properties.disclosure = value;                        

        if(!value){
            itemToUpdate.properties.branded_content = false;
            itemToUpdate.properties.brand_organic = false;
        }

        scheduledItemSocials[index] = itemToUpdate;        
        setScheduledItemSocials(scheduledItemSocials);        
        UpdateItem();
    }    

    const ClipChanged = (clip) => {
        var state = item.state
        if (state === -3 || state === -2) //deleted or placeholder     
        {
            setClipSelectorOpen(false)
            let newItem = UpdateItem(clip);
            props.setClipOnPlaceholderOrDeleted(newItem);
            state = -1
        }
        else{            
            setClip(clip)
            UpdateItem();            
        }        
    }

    async function HandleDelete()
    {           
        setIsDeleting(true)
        var date = new Date(item.date);
        await deleteSchedule([item.id, date.toISOString(), item.product]);              
        setIsDeleting(false)
    } 

    function GetStateText(state)
    {
        switch (state)
        {
            case -3: return "Deleted"
            case -2: return ""
            case -1: return "Not scheduled"
            case 0 : return "Scheduled"                     
            case 1 : return "Posting..."
            case 2 : return "Posted"
            case 3 : return `Retry #${item.processCount} @ ${new Date(item.modified).toLocaleString()}`
            case 4 : return `Failed @ ${new Date(item.modified).toLocaleString()}`
            default : return ""
        }
    } 

    function GetStateTextColour(state)
    {
        switch (state)
        {
            //case 3 : return "fs-15 medium-text mb-10 label2--text"
            default : return "fs-15 bold-text widget-dark-color"
        }
    }    

    function SetModal(value) 
    {
        setClipSelectorOpen(value)
    }

    function CanEdit() 
    {
        // if(item.state < -1){
        //     return false;
        // }

        if (IsInPast())
        {
            //item time is in the past
            if (item.state < 0 || item.state === 1 || item.state === 2 || item.state === 0)
                //cannot edit a placeholder, processing or processed item
                return false;
        }
        else if (item.state === 1 || item.state === 2 || item.state === -3)
        {
            //cannot edit a processing, processed or deleted item
            return false;
        }

        return true;        
    }

    function IsInPast(){
        if (itemDate < new Date())
        {
            return true;
        }
        return false;
    }

    function TikTokCheckBoxCanEdit(socialItem, optionName)
    {
        if (!SocialIsDisabled(socialItem))
        {
            if (socialItem?.properties[optionName] === false)
            {
                return CanEdit()
            }
            else
            {
                return false
            }
        }
        else
        {
            return false;
        }
    }
    
    async function HandleRetry()
    {           
        setIsUpdating(true);
        //reschedule - to cause the AWS scheduler to run immediately, we just need to update the date slightly. It will rerun automatically if its in the past.
        var date = Date.parse(item.date) + 1000; //1 second // + 10*60*1000

        var newDate = new Date(date);

        var dateString = newDate.toISOString();
       
        await updateSchedule({"id":item.id, "date":dateString, "state": 0, "stateDetails":item.stateDetails, "processCount":item.processCount})
        
        setIsUpdating(false);
    } 

    function CanChangeClip() {
        if(item.state === 1 || item.state===2){
            return false;
        }

        if(IsInPast()){
            return false;
        }

        return true;
    }

    function GetQRClass(qualityRating)
    {
        if (qualityRating >= 70)
        {
            return "col-3 fs-20 bold-text bright-green-text cursor-pointer"
        }
        else if (qualityRating >= 60)
        {
            return "col-3 fs-20 bold-text warning--text cursor-pointer"
        }
        else
        {
            return "col-3 fs-20 bold-text error--text cursor-pointer"
        }
    }

    const GetSocialIconClass = (index) => {
        return index===selectedSocialIndex ? "widget-dark-color fs-20 cursor-pointer" : "fs-20 cursor-pointer"
    }

    const GetSocialIcon = (socialType, index) => {
        switch(socialType){
            case 1:
                return <FontAwesomeIcon className={GetSocialIconClass(index)} onClick={() => setSelectedSocialIndex(index)} icon={faXTwitter} />
            case 2:
                return <FontAwesomeIcon className={GetSocialIconClass(index)} onClick={() => setSelectedSocialIndex(index)} icon={faYoutube} />
            case 3:
                return <FontAwesomeIcon className={GetSocialIconClass(index)} onClick={() => setSelectedSocialIndex(index)} style={{fontSize:30}} icon={faTiktok} />
            case 4:
                return <FontAwesomeIcon className={GetSocialIconClass(index)} onClick={() => setSelectedSocialIndex(index)} icon={faInstagram} />
        }
    }

    function SocialPropertyDisabled(socialItem)
    {
        return SocialIsDisabled(socialItem) || !CanEdit()
    }

    const TwitterSocialProperties = (x, index) => {
        return (
            <div>
                <Label for={"txtCaption_"+index}>Caption</Label>
                <Input 
                    type="textarea" 
                    name="caption" 
                    id={"txtCaption_" + index}
                    rows={7}
                    onChange={(e) => CaptionChanged(e, index, x)}
                    disabled={SocialPropertyDisabled(x)}
                    value={x.caption}
                    maxLength={x.properties["max_caption_length"]}
                    style={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}
                    />                                 
                <Label title='Caption length' className='fs-12 pt-10' for="txtCaptionLength" >({x.caption.length} / {x.properties["max_caption_length"]})</Label>
            </div>
        )
    }

    const YoutubeSocialProperties = (x, index) => {        
        return (
            <div>                                                                
                <FormGroup>
                <Label for={"txtYouTubePrivacy_" + x.id}>Privacy</Label>
                <Select
                    id={"select_" + x.id}
                    options={youtubePrivacyOptions}
                    defaultValue={x.properties['privacy_status']}
                    value={{label: getYouTubeLabelByValue(x.properties['privacy_status']), value: x.properties['privacy_status']}}                    
                    onChange={(e) => YouTubePrivacyChanged(e, index, x)}
                    isDisabled={SocialPropertyDisabled(x)} 
                    styles={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                    className='react-form-input'     
                />
                </FormGroup>
                <FormGroup>
                {/* note - don't change text "Title" - YouTube requirements! */}
                {/* TODO - need to add filtering of < and > chars properties => properties["invalid_chars"] */}
                <Label for={"txtTitle_" + x.id}>Title</Label>  
                    <Input 
                    type="text" 
                    name="title" 
                    id={"txtTitle_"+x.id}
                    onInput={(e) => TitleChanged(e, index, x)}
                    onKeyDown={(e) => FilterYouTubeInput(e, index, x)}
                    disabled={SocialPropertyDisabled(x)}                                        
                    value={x.properties['title']}      
                    className='react-form-input'         
                    style={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}                          
                    maxLength={x.properties['max_title_length']} />   
                    
                <Label title='Title length' className='fs-12 pt-10' for="txtTitleLength" >({x.properties['title'].length} / {x.properties['max_title_length']})</Label>                                                                 
                {/* note - don't change text "Description" - YouTube requirements! */}
                {/* TODO - need to add filtering of < and > chars properties => properties["invalid_chars"] */}
                <br/>
                <Label for={"txtDescription_"+x.id}>Description</Label>
                <Input 
                    type="textarea" 
                    name="caption" 
                    id={"txtCaption_" + index}
                    rows={7}
                    onChange={(e) => CaptionChanged(e, index, x)}
                    onKeyDown={(e) => FilterYouTubeInput(e, index, x)}
                    disabled={SocialPropertyDisabled(x)}
                    value={x.caption}
                    maxLength={x.properties['max_description_length']} 
                    style={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}
                    />                                 
                <Label title='Description length' className='fs-12 pt-10' for="txtCaptionLength" >({x.caption.length} / {x.properties['max_description_length']})</Label>                                                              
                </FormGroup>
            </div>
        )
    }

    const getDeclaration = (brandedContent, brandOrganic) => {
        if(brandOrganic && brandedContent){
            return (
                <FormGroup>
                        <div className='col-12'>
                            <Label className='fw-bold' for={"txtTikTokDeclaration"}>By posting, you agree to TikTok's <a href='https://www.tiktok.com/legal/page/global/bc-policy/en' target='_blank'>Branded Content Policy</a> and <a href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' target='_blank'>Music Usage Confirmation</a> </Label>
                        </div>                                                                               
                </FormGroup>
            )
        }

        if(brandOrganic){
           return (
           <FormGroup>
                <div className='col-12'>
                    <Label className='fw-bold' for={"txtTikTokDeclaration"}>By posting, you agree to TikTok's <a href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' target='_blank'>Music Usage Confirmation</a> </Label>
                </div>                                                                               
            </FormGroup>)
        }

        if(brandedContent){
            return (
                <FormGroup>
                        <div className='col-12'>
                            <Label className='fw-bold' for={"txtTikTokDeclaration"}>By posting, you agree to TikTok's <a href='https://www.tiktok.com/legal/page/global/bc-policy/en' target='_blank'>Branded Content Policy</a> and <a href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' target='_blank'>Music Usage Confirmation</a> </Label>
                        </div>                                                                               
                </FormGroup>
            )
        }

        return ( <FormGroup>
            <div className='col-12'>
                <Label className='fw-bold' for={"txtTikTokDeclaration"}>By posting, you agree to TikTok's <a href='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' target='_blank'>Music Usage Confirmation</a> </Label>
            </div>                                                                               
        </FormGroup>);
        
    }

    function createOptionsArray(inputArray) {
        let array = [{value: 'Select', label: 'Select'}];
        inputArray.map(item => array.push({
            value: item,
            label: item.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())
        }));                
        return array;
    }

    const getBrandPrompt = (branded_content, brand_organic) => {

        if(brand_organic && branded_content){
            return "Your video will be labeled as 'Paid partnership'";
        }

        if(brand_organic){
            return "Your video will be labeled as 'Promotional content'";
        }

        if(branded_content){
            return "Your video will be labeled as 'Paid partnership'";
        }
        
    }

    const TikTokSocialProperties = (x, index) => {        

        const privacyOptions = createOptionsArray(x.properties['privacy_level_options']);           
        const allowBrandedOptions = x.properties['privacy_level'] === 'PUBLIC_TO_EVERYONE';             
        const disclosure = x.properties["disclosure"];
        const branded_content = x.properties["branded_content"];
        const brand_organic = x.properties["brand_organic"];

        return (
            <div>   
                <FormGroup>
                    <Label className='fw-bold' for={"txtTikTokPrivacy_" + x.id}>Post Privacy</Label>
                        <Select
                            id={"select_" + x.id}                            
                            options={privacyOptions} 
                            defaultValue={'Select'}
                            value={{label: getTikTokLabelByValue(x.properties['privacy_level']), value: x.properties['privacy_level']}}                    
                            onChange={(e) => TikTokPrivacyChanged(e, index, x)}
                            isDisabled={SocialPropertyDisabled(x)} 
                            styles={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input'   
                            title="Modifies the post's privacy level i.e. how visible it will be."  
                        />
                </FormGroup>
                <div className='row pl-10 pb-1 fw-bold'>Allow users to:</div>
                <div className='row'>
                    <div className='col-4'>
                <FormGroup>
                    <div className='row'>
                    <div className='col-6'>
                        <Label for={"txtTikTokDuet_" + x.id}>Duet</Label>
                    </div>                                                            
                    <div className='col-2'>
                        <input
                            id={"select_" + x.id}
                            type="checkbox"
                            checked={!x.properties["duet_disabled"]}
                            onChange={(e) => TikTokDuetChanged(e, index, x)}
                            disabled={!TikTokCheckBoxCanEdit(x, "duet_disabled_option")} 
                            styles={TikTokCheckBoxCanEdit(x, "duet_disabled_option") ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input'     
                            title="Controls if other users can post videos side-by-side with your post"  
                            />
                    </div>
                    </div>
                </FormGroup>
                </div>
                <div className='col-4'>
                <FormGroup>
                <div className='row'>
                    <div className='col-6'>
                        <Label for={"txtTikTokStitch_" + x.id}>Stitch</Label>
                    </div>                                                            
                    <div className='col-2'>
                        <input
                            id={"select_" + x.id}
                            type="checkbox"
                            checked={!x.properties["stitch_disabled"]}
                            onChange={(e) => TikTokStitchChanged(e, index, x)}
                            disabled={!TikTokCheckBoxCanEdit(x, "stitch_disabled_option")} 
                            styles={TikTokCheckBoxCanEdit(x, "stitch_disabled_option") ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input'   
                            title="Controls if other users can incorporate your post into theirs"    
                            />
                    </div>
                    </div>
                </FormGroup>
                </div>
                <div className='col-4'>
                <FormGroup>
                <div className='row'>
                    <div className='col-6'>
                        <Label for={"txtTikTokComment_" + x.id}>Comment</Label>
                    </div>                                                            
                    <div className='col-2'>
                        <input
                            id={"select_" + x.id}
                            type="checkbox"
                            checked={!x.properties["comment_disabled"]}
                            onChange={(e) => TikTokCommentChanged(e, index, x)}
                            disabled={!TikTokCheckBoxCanEdit(x, "comment_disabled_option")} 
                            styles={TikTokCheckBoxCanEdit(x, "comment_disabled_option") ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input'  
                            title="Controls if other users can add comments to the post"     
                            />
                    </div>
                    </div>
                </FormGroup>
                </div>                
                </div>
                
                {!allowBrandedOptions && <Label className='text-warning' for={"allowBrandedOptionsMessage_" + x.id}>Branded content options are only available for publicly available posts.</Label>}
                <div className='row'>
                    <div className='col-12'>
                    <FormGroup>
                        <div className='row'>
                        <div className='col-5'>
                            <Label className='fw-bold' for={"discloseContent_" + x.id}>Disclose video content</Label>                                                        
                        </div>                                                                                    
                        <div className='col-2'>
                            <input
                                id={"select_" + x.id}
                                type="checkbox"
                                checked={disclosure}
                                onChange={(e) => TikTokDisclosureChanged(e, index, x)}
                                disabled={!allowBrandedOptions} 
                                styles={TikTokCheckBoxCanEdit(x, "comment_disabled_option") ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                                className='react-form-input'  
                                title="Controls if other users can add comments to the post"     
                                />
                        </div>
                        {allowBrandedOptions && <Label for={"discloseContentDescription_" + x.id}>Turn on to disclose that this video promotes goods or services in exchange for something of value.</Label>}        
                        </div>        
                    </FormGroup>
                    </div>
                </div>
                
                { disclosure && 
                <div className='row'>                
                <div className='col-12'>
                <FormGroup className='pl-15'>
                    <div className='row'>
                    <div className='col-5'>
                        <Label className='fw-bold' for={"txtTikTokBrandOrganic_" + x.id}>Your Brand</Label>                        
                    </div>                                                            
                    <div className='col-2'>
                        <input
                            id={"select_" + x.id}
                            type="checkbox"
                            checked={x.properties["brand_organic"]}
                            onChange={(e) => TikTokBrandOrganicChanged(e, index, x)}
                            disabled={SocialPropertyDisabled(x)} 
                            styles={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input'  
                            title="YOur are promoting yourself or your own business"     
                            />
                    </div>
                    </div>
                    <Label size='sm' for={"txtTikTokBrandOrganicDescription_" + x.id}>You are promoting yourself or your own business. This video will be classified as Brand Organic.</Label>
                </FormGroup>
                </div>
                <div className='col-12'>
                <FormGroup className='pl-15'>
                    <div className='row'>
                        <div className='col-5'>
                            <Label className='fw-bold' for={"txtTikTokComment_" + x.id}>Branded Content</Label>                    
                        </div>                                                            
                        <div className='col-2'>
                            <input
                                id={"select_" + x.id}
                                type="checkbox"
                                checked={x.properties["branded_content"]}
                                onChange={(e) => TikTokBrandedContentChanged(e, index, x)}
                                disabled={SocialPropertyDisabled(x)} 
                                styles={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                                className='react-form-input'  
                                title="Indicates you are promoting another brand or third-party"     
                                />
                        </div>
                        <Label size='sm' for={"txtTikTokBrandOrganicDescription_" + x.id}>You are promoting another brand or third-party. This video will be classified as Branded Content.</Label>
                    </div>
                </FormGroup>
                </div>
                
                </div>
                
                }
                {disclosure && (branded_content || brand_organic) && <Alert className='c-primary'>{getBrandPrompt(branded_content, brand_organic)}</Alert>}
                <div className='col-12'>
                    {getDeclaration(branded_content, brand_organic)}
                </div>
                <FormGroup>
                    <div className='col-6'>
                        <Label for={"txtTikTokCoverTimestamp_" + x.id}>Cover Timestamp</Label>
                    </div>                                                            
                    <div className='col-2'>
                        <input
                            id={"select_" + x.id}
                            value={x.properties["video_cover_timestamp_ms"]}
                            onChange={(e) => TikTokCoverChanged(e, index, x)}
                            disabled={SocialPropertyDisabled(x)} 
                            styles={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}  
                            className='react-form-input' 
                            title="Sets the video/post cover/title image from the video frame at the specified time offset (in milliseconds)"         
                            />
                    </div>
                </FormGroup>
                
                <FormGroup>
                    <Label for={"txtCaption_"+index}>Caption</Label>
                    <Input 
                        type="textarea" 
                        name="caption" 
                        id={"txtCaption_" + index}
                        rows={7}
                        onInput={(e) => CaptionChanged(e, index, x)}
                        disabled={SocialPropertyDisabled(x)}
                        value={x.caption}
                        maxLength={x.properties["max_caption_length"]}
                        style={!SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}
                        />                                 
                    <Label title='Caption length' className='fs-12 pt-10' for="txtCaptionLength" >({x.caption.length} / {x.properties["max_caption_length"]})</Label>
                </FormGroup>
            </div>
        )
    }

    const SocialProperties = (x, index) => {    
        switch (x.socialType)
        {
            case 1:
                return TwitterSocialProperties(x, index);
            case 2:
                return YoutubeSocialProperties(x, index);
            case 3:
                return TikTokSocialProperties(x, index);
            case 4:
                return null;
        }
    }

    function SocialIsDisabled(socialItem)
    {
        switch (socialItem.socialType)
        {
            case 3:
                if (socialItem.properties?.creator_status_error != undefined)
                    return true;
                else
                    return false;
            default:
                return false;
        }
    }

    function SocialDisabledReason(socialItem)
    {
        switch (socialItem.socialType)
        {
            case 3:
                return socialItem.properties?.creator_status_error;                
            default:
                return null;
        }
    }

    function FormatSocialStats(socialType, socialPostStats)
    {
        if (socialPostStats !== null && socialPostStats !== "")
        {
            try
            {
                var statsData = JSON.parse(socialPostStats);

                switch (socialType)
                {
                    case 1:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-heart"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Retweets" className="fas fa-retweet"></i>&nbsp;{statsData.retweets}&nbsp;&nbsp;
                                <i title="Replies" className="fas fa-comment"></i>&nbsp;{statsData.replies}&nbsp;&nbsp;                               
                            </>
                        );
                    case 2:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-thumbs-up"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Dislikes" className="fas fa-thumbs-down"></i>&nbsp;{statsData.dislikes}&nbsp;&nbsp;
                                <i title="Comments" className="fas fa-comment"></i>&nbsp;{statsData.comments}&nbsp;&nbsp;                               
                            </>
                        );
                    case 3:
                        return (
                            <>
                                <i title="Views" className="fas fa-eye"></i>&nbsp;{statsData.views}&nbsp;&nbsp;
                                <i title="Likes" className="fas fa-thumbs-up"></i>&nbsp;{statsData.likes}&nbsp;&nbsp;
                                <i title="Shares" className="fas fa-share"></i>&nbsp;{statsData.shares}&nbsp;&nbsp;
                                <i title="Comments" className="fas fa-comment"></i>&nbsp;{statsData.comments}&nbsp;&nbsp;                               
                            </>
                        );
                    case 4:
                    default:
                        return null; //not implemented
                }
            }
            catch(e)
            {
                return null; 
            }
        }
        else
        {
            return "No stats available";
        }
    }

    const getScheduleItemSocialEditors = () => { 

        const sortedSocials = scheduledItemSocials?.sort((a, b) => {
            // X (Twitter) first
            if (a.socialType === 1) return -1;
            if (b.socialType === 1) return 1;
            // YouTube second
            if (a.socialType === 2) return -1;
            if (b.socialType === 2) return 1;
            // Tiktok
            if(a.socialType === 3) return -1;
            if(b.socialType === 3) return 1;
            return 0;
        });

        return sortedSocials?.map((x, index) => {                    
            const socialName = (socialsData?.find((s) => s.id === x.social).name); 
            return (
                <div key={index}>                                                
                    {selectedSocialIndex === index &&                                                
                        <div>                                                    
                            <FormGroup>
                                <div className='row'>
                                    <div className='center pt-2'>
                                        {socialName}
                                    </div>
                                    {
                                        CanEdit() && SocialIsDisabled(x) ? 
                                            <div className='text-warning'>
                                                {SocialDisabledReason(x)}
                                            </div>                                            
                                        :
                                            null
                                    }
                                </div>
                                
                                { x.state === 2 &&                                
                                <div className='row pt-10 pb-10'>                                    
                                    <div className='col-12 center'>
                                        <a style={{color:'white', textDecoration:'none'}} href={x.socialPostUrl} target="_blank" rel="noreferrer" title='View post' className='cursor-pointer'>
                                            {FormatSocialStats(x.socialType, x.socialPostStats)}
                                        </a>
                                        </div>
                                </div>
                                }
                                {
                                 x.state === 3 &&
                                 <div className='row pt-10 pb-10'>                                    
                                    <div className='col-12 center text-warning'>
                                        {x.stateDetails}
                                    </div>
                                </div>
                                }

                                <div className='row'>
                                    <div className='col-2'>
                                        <Label for={"txtCaption_"+index}>Enabled</Label>
                                    </div>                                                            
                                    <div className='col-2'>
                                        <input
                                            type="checkbox"
                                            name="enabled"
                                            id={"chkEnable_"+index}
                                            checked={x.enabled}
                                            onChange={(e) => EnableChanged(e, index, x)}
                                            disabled={SocialPropertyDisabled(x)}
                                            style={SocialPropertyDisabled(x) ? {backgroundColor:'white'} : {backgroundColor:'grey'}}/>
                                    </div>
                                    
                                </div>
                               
                                
                                {   
                                    SocialProperties(x, index)
                                    
                                }                                
                            </FormGroup>
                        </div>
                    }
                </div>
            )
        })
    }

    const allDisabled = () => {
        let numDisabled = 0;

        if(scheduledItemSocials?.length === 0){
            return false;            
        }

        scheduledItemSocials?.forEach((x) => {
            if(!x.enabled){
                numDisabled += 1;
            }
        })

        return scheduledItemSocials?.filter((x) => !x.enabled).length === scheduledItemSocials?.length;
    }

    const canDelete = () => {
        if(CanEdit() && (item.state === -1 || item.state === 0)){
            return true;
        }              

        //Should only happen in the dev case. where the schedule does not actually exist in AWS.
        if(IsInPast() && item.state === 0){
            return true;
        }

        return false;
    }

    return (        
            <div>
                <div className="roe-card-style">                
                    <div>                    
                        <DatepickerWrapper {...props}>
                            <DatePicker
                                selected={date}
                                onChange={date => DateChanged(date)}                            
                                disabled={!CanEdit()}
                                showTimeSelect={CanEdit()}         
                                showTimeSelectOnly                   
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                className="custom-datepicker"
                                calendarClassName="custom-calender-class"
                                />
                        </DatepickerWrapper>
                    </div>
                    <div className="roe-card-body pt-24 backImage">
                        <div>
                            <div className='row'>
                                <div className="col-sm-6 fs-20 bold-text widget-dark-color">
                                    {item.date ? days[date.getDay()] : null}
                                </div>               
                                                      
                                <div className='col-sm-6'>
                                    <div className='row justify-content-end'>
                                        {
                                            CanChangeClip() && 
                                            <div className='center col-sm-1' style={{ cursor: 'pointer' }} title='Change clip' onClick={() => {SetModal(true)}}>
                                                <i className="fas fa-recycle mr-10 c-text-primary" style={{fontSize: '20px'}} />
                                            </div>
                                        }                                        
                                        {item.state >= 3 ?
                                            isUpdating ?
                                                <Spinner animation="border" role="status"/>  
                                            :
                                                <div className='center col-sm-1' style={{ cursor: 'pointer' }} title='Retry post immediately' onClick={() => {HandleRetry()}}>
                                                    <i className="fas fa-redo mr-10 c-text-primary" style={{fontSize: '20px'}} />
                                                </div>
                                            :
                                            null
                                        }
                                        {isDeleting ?
                                                <Spinner animation="border" role="status"/>  
                                            :
                                                canDelete() &&
                                                    <div className='center col-sm-1' style={{ cursor: 'pointer' }} title='Delete' onClick={() => {HandleDelete()}}>
                                                        <i className="fas fa-trash mr-10 c-text-danger" style={{fontSize: '20px'}} />
                                                    </div>                                            
                                        }
                                        {
                                            clip && <>
                                                <div className='col-sm-2'>
                                                    <Badge><div title={"Quality Rating - " + clip?.quality_rating_details} className={GetQRClass(clip?.quality_rating)}>Q {clip?.quality_rating}</div></Badge>                                                                 
                                                </div>
                                                <div className='col-sm-2'>
                                                    <Badge><div title={"Relevance Rating - " + clip?.relevance_rating_details} className={GetQRClass(clip?.relevance_rating)}>R {clip?.relevance_rating}</div></Badge>                         
                                                </div>                                        
                                            </>  
                                        } 
                                    </div>
                                </div>
                                
                            </div>             
                           
                            
                            <div className='row pt-10'>
                                <div className={[GetStateTextColour(item.state), 'col-sm-6'].join(' ')}>
                                    {GetStateText(item.state)}                                                                                              
                                </div>      
                                
                                {
                                validMessage !== "" &&                                
                                    <div className='col-sm-6 text-warning center'>
                                        {validMessage}
                                    </div>                                
                                }                                
                            </div>

                            {
                                CanEdit() && allDisabled() && item.id > 0 &&                          
                                <div className='text-warning'>
                                    None of your posts are enabled for this day!
                                </div>
                            }                            
                            
                            {
                                item.state >= 3 &&
                                <div>
                                    <div className='text-warning'>{item.stateDetails}</div>
                                </div>
                            }
                            <div className='row pt-10'>
                                {
                                    clip?.stream_url ?
                                <>
                                <div className='col-sm-6 player-column'>                                    
                                            <div className='player-wrapper center pt-20' title={item.description}>
                                                <ReactPlayer    
                                                    height={700}                                     
                                                    url={clip.stream_url} 
                                                    controls={true}                                                    
                                                    onDuration={(duration) => handleDuration(duration)}                                               
                                                    className='react-player'                                                                                                        
                                                    //light={'<img src={videoPlaceholder} width={"100%"}/>'}                                                                                    
                                                />                                            
                                            </div>
                                        
                                </div>                  
                                
                                <div className="col-sm-6 fs-15 medium-text widget-light-grey-color properties-column" >
                                    <div>
                                        <div className='row center pt-10'>
                                        {
                                            scheduledItemSocials?.map((x, index) => {
                                                return (<div key={index} className='col-1 center'>
                                                    {GetSocialIcon(x.socialType, index)}
                                                </div>)
                                            })
                                        }
                                        </div>
                                        {                                    
                                            getScheduleItemSocialEditors()
                                        }
                                    </div>                               
                                </div>    
                                </> : (
                                            <div style={{height: 800}} className='center'>
                                                <FaVideo size={360}/>
                                            </div>
                                        )     
                                }
                            </div>                                                
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={clipSelectorOpen}
                    toggle={() => SetModal(!clipSelectorOpen)}                    
                    size='lg'                                   
                    centered        
                    contentClassName='modal'            
                >
                    <ModalHeader className='modal-body '>
                        <div className='widget-dark-color bold-text'>Select a clip</div>                    
                    </ModalHeader>
                    <ModalBody className='modal-body'>
                        <div>
                        <ClipSelector
                            clipChanged={ClipChanged}
                        />
                        </div>
                    </ModalBody>   
                    <ModalFooter></ModalFooter>              
                    </Modal>
            </div>
        
    );
};

export default ScheduleItem;