import React, { useState } from "react";
import { folder } from "helper/constant";
import ReactPlayer from 'react-player'
import SweetAlert from "react-bootstrap-sweetalert";

const MyFile = ({
  media,
  type,
  deleteFile,
}) => {
  const [warningAlert, setWarningAlert] = useState(false)

  const getTypeTag = () => {
    if(type === "folder"){
      return <img src={folder} alt="file" />
    }

    if(type === "video"){
      return  <div style={{ height: 180 }}>
          <ReactPlayer 
              url={media.file} 
              controls={true}
              width='100%'
              height='100%'              
          />
        </div>
    }
  }
  
  const getRatingColor = (value) => {
    if(value <= 60){
      return 'Orange';
    }
    if(value < 70) {
      return 'Yellow';
    }
    
    return 'YellowGreen'
  }

  return (
    <div className="roe-shadow-2 file-block" style={{width:300}}>
      <div className="image-file">
        {getTypeTag()}
      </div>

      <div className="row ptb-10">
        <div className="col-2 widget-dark-color">
          <i className="fa fa-star"></i>
        </div>
        <div title={"Quality Rating - " + media.quality_rating_details} className="col-3 bold-text fs-14" style={{color: getRatingColor(media.quality_rating)}}>
          Q: {media.quality_rating}
        </div>
        <div title={"Relevance Rating - " + media.relevance_rating_details} className="col-3 bold-text fs-14" style={{color: getRatingColor(media.relevance_rating)}}>
          R: {media.relevance_rating}
        </div>        
      </div>

      <div className="widget-dark-color bold-text fs-14 demi-bold-text ptb-5 file-name" style={{height: 100, overflow:'auto'}}>
        {media.description}
      </div>
      
      <div className="bold-text widget-dark-color fs-13 demi-bold-text ptb-10 file-name">
        {media.size.toFixed(2)} Mb
      </div>
      <div
        className="roy-menu-list"        
        onClick={() => setWarningAlert(true)}
        >
         <div className="bold-text far fa-trash-alt" style={{color:"red"}}></div>
      </div>
      <SweetAlert
        warning
        showCancel
        show={warningAlert}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => {
            setWarningAlert(false);
            deleteFile(media);
        }}
        onCancel={() =>
            setWarningAlert(!warningAlert)
        }
      >
        You will not be able to recover this clip!
      </SweetAlert>
    </div>
  );
};

export default MyFile;
