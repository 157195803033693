import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageTitle from 'components/common/PageTitle';
import SubscriptionHandler from 'features/subscription/SubscriptionHandler';
import ReactGA from 'react-ga4'

class Subscription extends Component {    
    
    render() {             
        ReactGA.send({
            hitType: "pageview",
            page: "/subscription",
            title: "Subscription",
        })
        
        return (
            <div className="mtb-30 theme-color">                                   
                <SubscriptionHandler/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
}

export default connect(mapStateToProps, null)(Subscription);