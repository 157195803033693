import {React, useEffect, useState} from "react";
import { useSelector, useDispatch, } from "react-redux";
import {setAmount, setPaymentStage, setPaymentError, setSubscriptionType} from "./paymentSlice";
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import Select from "react-select";

const SubscriptionConfirmModal = (props) => {

    const {
        prepaidTokenCost
    } = props    

    const TIER_PREPAID = 1;
    const TIER_SUB_1 = 2;
    const TIER_SUB_2 = 3;
    const TIER_SUB_3 = 4;

    function CreateValueOption(tokenAmount)
    {
        return {value: (prepaidTokenCost * tokenAmount), label: `${tokenAmount} Tokens - ${prepaidTokenCost * tokenAmount} USD`}
    }

    const prePaidOptions = [
        CreateValueOption(250),
        CreateValueOption(500), 
        CreateValueOption(1000), 
        CreateValueOption(2000), 
        CreateValueOption(4000)        
    ]


    const dispatch = useDispatch();
    const orderAmount = useSelector((state) => state.payment.amount) 
    const paymentStage = useSelector((state) => state.payment.paymentStage)
    const subscriptionType = useSelector((state) => state.payment.subscriptionType)
    const subscription = useSelector((state) => state.subscription)
 
    useEffect(() => {     
        SetDefaultValues();  
    },[orderAmount])

    async function SetDefaultValues()
    {   
        dispatch(setPaymentError(""))

        if (orderAmount === 0 && subscriptionType === 1)                        
            dispatch(setAmount(prepaidTokenCost * 250));      
    }

    function CloseModal()
    {
        dispatch(setPaymentError(""))
        dispatch(setPaymentStage(0))       
    }

    
    function GetSubscriptionCost(tier)
    {
        //hard coded here for now - should be obtaining from API!
        switch (tier)
        {
            case 2:
                return 30;
            case 3:
                return 60;
            case 4:
                return 90;
            default:
                return 0;
        }        
    }

    function GetSubscriptionName(tier)
    {
        //hard coded here for now - should be obtaining from API!
        switch (tier)
        {
            case 2:
                return "Solo";
            case 3:
                return "Studio";
            case 4:
                return "Enterprise";
            default:
                return "Unknown";
        }        
    }

    function GetSubscriptionProductCount(tier)
    {
        //hard coded here for now - should be obtaining from API!
        switch (tier)
        {
            case 1:
                return "one";
            case 2:
                return 'two';
            case 3:
                return "unlimited";            
        }        
    }
    
    function ProceedToPayment()
    {
        if (subscriptionType > 1)
            dispatch(setAmount(GetSubscriptionCost(subscriptionType)))
        dispatch(setPaymentStage(2))
    }

    function NewSubscriptionForm()
    {
        return (
            <Modal size='lg' isOpen={paymentStage===1} centered={true}>                   
                <Form> 
                    <ModalHeader>
                        <>Purchase Autigma Subscription - confirmation</>                                                
                    </ModalHeader>
                    <ModalBody>                    
                        <p>
                            An Autigma subscription is a recurring monthly fee that purchases a fixed amount of credit Tokens at a 
                            discounted rate. Operations will always use the discounted subscription Tokens balance unless that is insufficient,
                            in which case any available standard rate Prepaid Tokens will be used. 
                        </p>
                        <br/>
                        <p>
                            A subscription can be upgraded or downgraded, if possible depending on the current type, at any time, and the charge for the new subscription occurs immediately.
                            Any existing subscription Token balance is carried forward and added to the Tokens purchased through the new subscription. New or pending operations 
                            are charged for according to the new subscription discount rate immediately.
                        </p>
                        <br/>
                        <p>
                            A subscription can be cancelled at any time and your account will immediately be converted back into a Prepaid account.
                            Any existing subscription Token balance is carried forward but new or pending operations are charged for according to the Prepaid rate immediately.
                        </p>
                        <br/>
                        <p>
                            New subscription monthly cost  : {GetSubscriptionCost(subscriptionType)}  USD                                                 
                        </p>
                        
                    </ModalBody>
                    <ModalFooter>    
                        <Button onClick={()=>ProceedToPayment()}>I Agree</Button>                            
                        <Button onClick={()=>CloseModal()}>Cancel</Button>
                    </ModalFooter>                                       
                </Form>                                            
            </Modal>  
        );
    }

    function UpgradeSubscriptionForm()
    {
        return (
            <Modal size='lg' isOpen={paymentStage===1} centered={true}>                   
                <Form> 
                    <ModalHeader>
                        <>Upgrade Autigma Subscription - confirmation</>                                                
                    </ModalHeader>
                    <ModalBody>                    
                        <p>
                            You have opted to upgrade your current subscription from the {GetSubscriptionName(subscription.tier)} tier to the {GetSubscriptionName(subscriptionType)} tier.                     
                        </p>
                        <br/>
                        <p>
                            You will be charged for the new subscription immediately. Upon successful payment, the subscription change occurs immediately. Any existing subscription Token balance is carried forward and added to the Tokens purchased through the new subscription. Operations 
                            carried forward and all new or pending operations will use the new subscription discount rate.                   
                        </p>
                        <br/>
                        <p>
                            Current subscription monthly cost : {GetSubscriptionCost(subscription.tier)} USD     
                        </p>
                        <p>                                            
                            New subscription monthly cost : {GetSubscriptionCost(subscriptionType)} USD                                                 
                        </p>
                        
                    </ModalBody>
                    <ModalFooter>    
                        <Button onClick={()=>ProceedToPayment()}>I Agree</Button>                            
                        <Button onClick={()=>CloseModal()}>Cancel</Button>
                    </ModalFooter>                                       
                </Form>                                            
            </Modal>   
        );
    }

    function DowngradeSubscriptionForm()
    {
        return (
            <Modal size='lg' isOpen={paymentStage===1} centered={true}>                   
                <Form> 
                    <ModalHeader>
                        <>Downgrade Autigma Subscription - confirmation</>                                                
                    </ModalHeader>
                    <ModalBody>                    
                        <p>
                            You have opted to downgrade your current subscription from the {GetSubscriptionName(subscription.tier)} tier to the {GetSubscriptionName(subscriptionType)} tier.                     
                        </p>
                        <br/>
                        <p>
                            You will be charged for the new subscription immediately. Upon successful payment, the subscription change occurs immediately. Any existing subscription Token balance is carried forward and added to the Tokens purchased through the new subscription. Operations 
                            carried forward and all new or pending operations will use the new subscription discount rate.                   
                        </p>
                        <br/>
                        <p>
                            Note that the new subscription allows for {GetSubscriptionProductCount(subscriptionType)} product(s) whereas your current subscription allows for {GetSubscriptionProductCount(subscription.tier)} product(s). If you have more products defined than the new subscription allows,
                            these will be disabled and will no longer by usable for scheduling and other operations. You can re-activate a disabled product by deleting one or more of the active products.
                        </p>
                        <br/>
                        <p>
                            Current subscription monthly cost : {GetSubscriptionCost(subscription.tier)} USD     
                        </p>
                        <p>                                            
                            New subscription monthly cost : {GetSubscriptionCost(subscriptionType)} USD                                                 
                        </p>
                        
                    </ModalBody>
                    <ModalFooter>    
                        <Button onClick={()=>ProceedToPayment()}>I Agree</Button>                            
                        <Button onClick={()=>CloseModal()}>Cancel</Button>
                    </ModalFooter>                                       
                </Form>                                            
            </Modal>
        );
    } 

    const onAmountChange = (amount) => {
        dispatch(setAmount(parseFloat(amount.value)));        
    }
    
    function PrepaidForm()
    {
        return (
            <Modal size='lg' isOpen={paymentStage===1} centered={true}>                   
            <Form> 
                <ModalHeader>
                    <>Purchase Autigma Credit - select a value</>                            
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Amount</label>   
                        <div className="col-lg-9">  
                            <Select className="form-control react-form-input"   
                                name="amount"                
                                placeholder="Prepaid purchase amount"
                                onChange={onAmountChange}
                                options={prePaidOptions}             
                                defaultValue={CreateValueOption(250)}                                   
                            />                                 
                        </div>
                    </div> 
                    <br/>                                                    
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Total Value : {orderAmount}  USD                                                 
                        </div>
                    </div>                                                             
                </ModalBody>
                <ModalFooter>    
                    <Button disabled={orderAmount === 0} onClick={()=>ProceedToPayment()}>Next</Button>                            
                    <Button onClick={()=>CloseModal()}>Cancel</Button>
                </ModalFooter>                                       
            </Form>                                            
        </Modal>         
        );
    } 

    return (
        <div>
            { (subscriptionType === TIER_PREPAID) ?
                PrepaidForm() 
                :
                subscription.tier === TIER_PREPAID ?             
                    NewSubscriptionForm()                
                    :
                    subscription.tier < subscriptionType ?
                        UpgradeSubscriptionForm()
                    :
                        DowngradeSubscriptionForm()
            }   
        </div>                                  
    );
};

export default SubscriptionConfirmModal;