import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    startDate : new Date()
}

const scheduleAdminSlice = createSlice({
    name:'scheduleAdmin',
    initialState,
    reducers: {        
        setStartDate: (state, action) => {
            return {
                ...state,
                startDate: action.payload
            }
        },        
    }
})

export const {setStartDate} = scheduleAdminSlice.actions
export default scheduleAdminSlice.reducer