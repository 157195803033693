import {apiSlice} from '../../app/api/apiSlice'

export const subscriptionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubscription: builder.mutation({
            query: () => ({
                url: '/user_subscription/',
                method: 'GET',                
            })            
        }), 
        cancelSubscription: builder.mutation({
            query: (plan) => ({
                url: '/user_subscription/subscription_cancel_recurring/',
                method: 'POST',                
            })
        }),        
        createOrder: builder.mutation({
            query: (totalPurchaseValue) => ({
                url: '/user_subscription/prepaid_order_create/',
                body: {
                    'totalPurchaseValue': totalPurchaseValue                  
                },
                method:'POST'
            })
        }),
        captureOrder: builder.mutation({
            query: (orderData) => ({
                url: '/user_subscription/order_capture/',
                body: {
                    'orderData': orderData
                },
                method:'POST'
            }),
            invalidatesTags: ['Balances']
        }),
        getTokenCost: builder.mutation({
            query: () => ({
                url: '/user_subscription/get_prepaid_token_cost/',               
                method:'GET'
            })
        }),
        getSubscriptionBalances: builder.query({
            query: () => ({
                url: '/user_subscription/get_subscription_balances/',               
                method:'GET'
            }),
            providesTags: ['Balances']
        }),
        getPlanId: builder.mutation({
            query: (planType) => ({
                url: '/user_subscription/get_plan_id/',
                body: {
                    'planType': planType
                },
                method:'POST'
            })
        }),
        getSocialPostCost: builder.mutation({
            query: () => ({
                url: '/user_subscription/get_subscription_social_post_cost/',                
                method:'GET'
            })
        })
    })
})


export const {
    useGetSubscriptionMutation,
    useCancelSubscriptionMutation,
    useCreateOrderMutation,
    useCaptureOrderMutation,
    useGetTokenCostMutation,
    useGetPlanIdMutation,
    useGetSubscriptionBalancesQuery,
    useGetSocialPostCostMutation
} = subscriptionApiSlice