import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { loginBack, autigmaLogo } from "helper/constant";
import enhancer from "./enhancer/LoginFormEnhancer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCredentials } from "../../../features/authentication/authenticationSlice";
import { useLoginMutation } from "../../../features/authentication/authenticationApiSlice";
import { useDispatch } from "react-redux";
import ReactGA from 'react-ga4'

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [login] = useLoginMutation();
    const [verifyMessage, setVerifyMessage] = useState();
    const [isVerifying, setIsVerifying] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const verification_token = searchParams.get('token');    
    const registered = searchParams.get('registered')

    useEffect(() => {
        verifiyUser();
    })

    const verifiyUser = () => {        
        if(verification_token){
            let api_url = process.env.REACT_APP_WEBAPI_URL
            fetch(`${api_url}users/verify_user?token=${verification_token}`)
                .then(data => {
                    if(data.status === 404){
                        setVerifyMessage("Verification failed - User not found!");
                    }
                    else if(data.status === 200){
                        setVerifyMessage("Verification Completed! Please login...");
                    }
                    
                    setIsVerifying(false);
                })
                .catch(error => {
                    setVerifyMessage(error.message);
                    setIsVerifying(false);
                });
            searchParams.delete('token')
            setSearchParams(searchParams)
        }
        else if(registered){
            setVerifyMessage("Registration completed - please check your email inbox for a verification email.")
            searchParams.delete('registered')
            setSearchParams(searchParams)
        }
        else{
            setIsVerifying(false)            
        }
    }

    ReactGA.send({
        hitType: "pageview",
        page: "/login",
        title: "Login",
    })

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            isVerified: false
        },
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            if (values.email !== "" && values.password !== "") {
                var result = await login(
                    {
                        "email": values.email, 
                        "password": values.password                    
                    })                           
                
                if(result.error === undefined) {                                        
                    dispatch(setCredentials(result));                    

                    setSubmitting(false)
                    navigate("/dashboard");
                }                     
                else if(result.error.status === 401){                    
                    setErrors({ password: result.error.data });                                
                }                           
                else if(result.error.status >= 400){
                    setErrors({ password: "Invalid username or password"});
                }
                else{
                    setErrors({password: result.error})
                }
                setSubmitting(false)
            }
        },

        validate: values => {
                        const errors = {};
                
                        if (!values.email) {
                            errors.email = "Email is required";
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                            errors.email = "Invalid email address";
                        }
                
                        if (!values.password) {
                            errors.password = "Password is required";
                        }
                
                        return errors;
                    },
    });

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0,
    };

    const Error = ({ field }) => {
        const field1 = field;
        if ((formik.errors[field1] && formik.touched[field1]) || formik.submitCount > 0) {
            return (
                <span className="error-msg">
                    {formik.errors[field1]}
                </span>
            );
        } else {
            return <span />;
        }
    };    

    return (
        <div className="container-fluid" style={loginContainer}>
            {isVerifying &&
                <div className="center">
                    We're verifying your account! Please give us a moment.
                </div>
            }
            {!isVerifying &&
            <div className="form-container">
                <div className="login-icon">
                    <img src={autigmaLogo} alt="icon" height="100px" />
                </div>
                <div className="login-title">Sign in to your account</div>
                <form className="pa-24" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control react-form-input"
                            id="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            placeholder="Email"
                        />
                        <Error field="email" />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control react-form-input"
                            id="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Password"
                        />
                        <Error field="password" />
                    </div>

                    {/* <div className="form-check text-center mtb-16">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                        >
                            Remember me
                        </label>
                    </div> */}
                    
                    <div className="pt-15">
                    <button disabled={(formik.isSubmitting) ? true : false} type="submit" style={{height:50}} className="btn form-button">
                        {formik.isSubmitting ? 'Logging in...' : 'Login'}
                    </button>
                    </div>
                    <div
                        className="text-center link-label"
                        onClick={() => navigate("/register")}
                    >
                        Register
                    </div>
                    
                    {/* <div
                        className="text-center link-label"
                        onClick={() => navigate("/forgotPassword")}
                    >
                        Forgot Password ?
                    </div> */}
                </form>
            </div>
            }
            {
                verifyMessage && 
                <div className="center widget-dark-color" style={{color:"white"}}>
                    {verifyMessage}
                </div>
            }
        </div>
    );
};

export default enhancer(LoginForm);
