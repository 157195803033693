export const sidebarData = [
  {
    name: 'sidebar.dashboard',
    routepath: '/Dashboard',
    iconClass: 'fas fa-chalkboard'
  },  
  {
    name: 'sidebar.socialmanagement',
    routepath: '/SocialManagement',
    iconClass: 'fas fa-comment'
  },
  {
    name: 'sidebar.products',
    routepath: '/ProductManagement',
    iconClass: 'fas fa-clone'
  },
  {
    name: 'sidebar.schedule',
    routepath: '/Schedule',
    iconClass: 'fas fa-calendar-alt'
  },
  {
    name: 'sidebar.files',
    routepath: '/Files',
    iconClass: 'fas fa-file'
  },
  {
    name: 'sidebar.subscription',
    routepath: '/Subscription',
    iconClass: 'fas fa-dollar-sign'
  },
  {
    name: 'sidebar.support',
    routepath: '/Support',
    iconClass: 'fas fa-phone fa-flip-horizontal'
  },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/

export const HorizontalSidebarData = [
  {
      name: 'sidebar.intro',
      routepath: '/Dashboard',
      iconClass: 'fas fa-chalkboard'
  },
  {
    name: 'sidebar.products',
    routepath: '/ProductManagement',
    iconClass: 'fas fa-clone'
  },
  {
    name: 'sidebar.socialmanagement',
    routepath: '/SocialManagement',
    iconClass: 'fas fa-comment'
  },
  {
    name: 'sidebar.schedule',
    routepath: '/Schedule',
    iconClass: 'fas fa-calendar-alt'
  },
  {
    name: 'sidebar.subscription',
    routepath: '/Subscription',
    iconClass: 'fas fa-dollar-sign'
  },
];

// ### For Horizontal sidebar

//     <!-- Basics -->
//         {
//             name: "sidebar.single",
//             iconClass: "fab fa-stripe-s",
//             routepath: "/single"
//         }
//     <!-- One Level -->
//         {
//             name: "sidebar.onelevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     routepath: "/ex",
//                 }
//             ]
//         }
//     <!-- Second level -->
//         {
//             name: "sidebar.secondlevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     iconClass: "fas fa-plus",
//                     child: [
//                         {
//                             name: "sidebar.example1",
//                             routepath: "/ex1",
//                         },
//                         {
//                             name: "sidebar.example2",
//                             routepath: "/ex2",
//                         }
//                     ]
//                 }
//             ]
//         }