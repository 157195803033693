import {React, useState, useRef, useEffect} from "react";
import { useSelector, useDispatch, } from "react-redux";
import LoaderComponent from "components/common/LoaderComponent";
import {Formik, Form, useField} from "formik"
import * as Yup from "yup";

import { setCredentials, selectCurrentUser, selectCurrentToken } from "../authentication/authenticationSlice";
import { useUpdateMutation } from "../authentication/authenticationApiSlice";
import { useGetSubscriptionBalancesQuery } from "../subscription/subscriptionAPISlice"

import {
    Button,
    Container, Row, Col
} from "reactstrap";

const UserProfile = () => {
    
    const dispatch = useDispatch();
    
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState("");
    const persistedUser = useSelector(selectCurrentUser);     
    const persistedToken = useSelector(selectCurrentToken);   
    const [user_update] = useUpdateMutation();          
    const [userState, setUserState] = useState(null)
    const {data:balances} = useGetSubscriptionBalancesQuery();

    const PASSWORD_HOLDER = "***Redacted***"
   
    useEffect(() => {
        PageLoadHandler();    
    },[])
    
    async function LoadData()
    {
        setIsLoading(true);              
                
        var userCopy = {...persistedUser};

        //we overwrite any password field for the user profile password field so that it's not blank or long
        userCopy.password = PASSWORD_HOLDER;
        setUserState(userCopy);

        setIsLoading(false);
    
    }   

    // Function to handle redirect after authentication
    async function PageLoadHandler() 
    {         
        LoadData();            
    } 
        
    
    async function SubmitEditForm(values) 
    {   
        setIsSaving(true);

        var user = {...userState}

        user.first_name = values.first_name;
        user.last_name = values.last_name;
        user.email = values.email;

        if (values.password === PASSWORD_HOLDER)
            user.password = "";          
        else        
          //set a new password
          user.password = values.password;

        var result = await user_update(user);

        user.password = PASSWORD_HOLDER  

        if (result?.error)
          setSaveError(result.error);
        else
        {            
            var token = persistedToken;
            dispatch(setCredentials({data : {user, token}}))
            setUserState(user);
        }          
        
        setIsSaving(false);
    }  
    
    
    const schema = Yup.object().shape({
        first_name: Yup.string().required('Please enter a first name'),
        last_name: Yup.string().required('Please enter a last name'),
        email: Yup.string().email().required("Please provide a valid email address"),
        password: Yup.string().min(8, "Password must be at least 8 characters in length"),        
    })  

    const ATextInput = ({ label, ...props }) => {

        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]     
        // which we can spread on <input>. We can use field meta to show an error     
        // message if the field is invalid and it has been touched (i.e. visited)
     
        const [field, meta] = useField(props);
     
        return (     
          <>     
            <div className="form-group row">
                <label className="col-lg-3 col-form-label" htmlFor={props.id || props.name}>{label}</label>   
                <div className="col-lg-9">      
                    <input className="form-control react-form-input" {...field} {...props} />     
                    {meta.touched && meta.error ? (     
                    <div className="error-msg">{meta.error}</div>) : null}     
                </div>
            </div>  
          </>     
        );                               
      };     
 
  
    return (
        <div>
        {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-100">
                <LoaderComponent loading={isLoading} />
            </div>
        ) : (                
            <div> 
                <Formik initialValues={userState} validationSchema={schema} 
                    onSubmit={(values) => {SubmitEditForm(values)}}>            
                    {formik => (
                        <Form> 
                            <div className="form-group row">
                                User
                            </div>
                            <br/>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <ATextInput
                                        label="First Name"
                                        name="first_name"
                                        placeholder="First Name"                                                
                                    />             
                                </div>
                            </div>
                            <br/>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <ATextInput
                                        label="Last Name"
                                        name="last_name"
                                        placeholder="Last Name"                                                
                                    />             
                                </div>
                            </div>
                            <br/>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <ATextInput
                                        label="Email"
                                        name="email"
                                        placeholder="Email"                                                
                                    />             
                                </div>
                            </div>
                            <br/>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <ATextInput
                                        type="password"
                                        label="Password"
                                        name="password"
                                        placeholder="Password"                                                
                                    />             
                                </div>
                            </div>                           
                            <br/>
                            <br/>                            
                            <Button type="submit">{isSaving ? "Saving..." : "Save"}</Button>                           
                        </Form>
                    )}
                </Formik>     
                <br/>
                <br/>
                <div className="form-group row">
                    Token Balances
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-lg-12">
                        <label>Prepaid Balance :</label>         
                        <label>&nbsp;{balances.token_prepaid_balance}</label>           
                    </div>
                    <div className="col-lg-12">
                        <label>Subscription Balance : </label>         
                        <label>&nbsp;{balances.token_subscription_balance}</label>           
                    </div>
                    <div className="col-lg-12">
                        <label>Total Balance : </label>         
                        <label>&nbsp;{balances.token_balance}</label>           
                    </div>
                </div>       
            </div>
        )}
        </div>
    );
};

export default UserProfile;