import {apiSlice} from '../../app/api/apiSlice'

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/login/',
                method: 'POST',
                body: {...credentials}
            }),
            invalidatesTags: ['Socials', 'Products', 'Balances', 'News', 'Clips']
        }),
        register: builder.mutation({
            query: data => ({
                url: '/users/',
                method: 'POST',
                body: {...data}
            })
        }),
        update: builder.mutation({
            query: (user) => ({
                url: `/users/${user.id}/`,
                method: 'PATCH',
                body: {...user}
            })
        }),        
    })
})


export const {
    useLoginMutation,
    useRegisterMutation,
    useUpdateMutation,
} = authApiSlice