import React from 'react';
import { Button } from 'reactstrap';

const UploadResult = ({ uploadResult, uploadPercentage, onCancel, onOkay }) => {
  return (
    <div>
      {uploadResult === null ? 
          <Button disabled={uploadPercentage===100} onClick={onCancel}>Cancel</Button>
        :
          <Button onClick={onOkay}>Close</Button>      
      }      
    </div>
  );
};

export default UploadResult;