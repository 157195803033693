import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ authData, children }) => { 
   return (
        <div>
            {authData.token ? (
                <Fragment>{children}</Fragment>
            ) : (
               <Navigate replace to="/login" />
            )}
        </div>
    );
};
