import {createSlice} from '@reduxjs/toolkit'

const initialState = [
]
const scheduleSlice = createSlice({
    name:'schedule',
    initialState,
    reducers: {
        getSchedule: (state, {payload}) => {
            return [...state, ...payload]
        },
        getScheduleItem: (state, {payload}) => {
            const id = payload;

            const index = state.findIndex(item => item.id === id);

            if (index !== -1) {
                return state[index];                
            }
            
            return null;
        },
        setSchedule: (state, action) => {
            if(action.payload === null){
                return []
            }
            
            return action.payload;
        },
        removeScheduleItem: (state, action) => {
            const id = action.payload;
             // Find the index of the item to update
            const indexToRemove = state.findIndex(item => item.id === id);

            if (indexToRemove !== -1) {
            return [
                ...state.slice(0, indexToRemove),
                ...state.slice(indexToRemove + 1)
            ];
            }

              // If the item with the provided ID is not found, return the current state unchanged
            return state;
        },        
        updateScheduledItem: (state, action) => {
            const { id, newClip, newDatetime, newState, newScheduleItemSocials } = action.payload;
            // Find the index of the item to update
            const indexToUpdate = state.findIndex(item => item.id === id);
            
            if (indexToUpdate !== -1) {
                // Create a copy of the item to update
                const updatedItem = { ...state[indexToUpdate] };
                // Update the caption of the copied item

                if (newClip){
                    updatedItem.clip = newClip
                }
                
                if(newDatetime){
                    updatedItem.date = newDatetime.toISOString();                
                }
                
                if(newState){
                    updatedItem.state = newState
                }

                if(newScheduleItemSocials){
                    updatedItem.scheduleItemSocials = newScheduleItemSocials
                }
                
                // Create a new state array with the updated item
                return [
                    ...state.slice(0, indexToUpdate),
                    updatedItem,
                    ...state.slice(indexToUpdate + 1)
                ];
            }
            // If the item with the provided ID is not found, return the current state unchanged
            return state;
        },
        resetSchedule: () => {
            return initialState;
        }
    }
})

export const {getSchedule, getScheduleItem, setSchedule, updateScheduledItem, removeScheduleItem, resetSchedule} = scheduleSlice.actions
export default scheduleSlice.reducer