import {React, useEffect, useState} from "react";
import {useSelector} from 'react-redux'
import { useGetByProductQuery } from "./clipsApiSlice";
import ReactPlayer from "react-player";

const ClipSelector = (props) => {    
    const selectedProduct = useSelector((state) => state.products.selectedProduct)    
    const {data, isLoading} = useGetByProductQuery(selectedProduct?.value);    

    const {        
        clipChanged
    } = props    
   
    function onClipSelected(clip) {
        clipChanged(clip)
    }

    const getRatingColor = (value) => {
        if(value <= 60){
          return 'Orange';
        }
        if(value < 70) {
          return 'Yellow';
        }
        
        return 'YellowGreen'
      }

    var renderedList = data ? data.map(item=>
        <div key={item.id} style={{borderBottom: "1px solid lightgrey"}} className="col-xl-12 ptb-15">               
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div className="col-4" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <ReactPlayer 
                    url={item.stream_url} 
                    controls={true}                   
                    height='100%'
                />
                </div>
                <div className="col-8">
                <div title="Click to select this clip" style={{alignItems:'center', cursor:'pointer'}} onClick={() => onClipSelected(item)}>
                <div className="row ptb-10 center">
                    <div className="col-2 widget-dark-color">
                    <i className="fa fa-star"></i>
                    </div>
                    <div title={"Quality Rating - " + item.quality_rating_details} className="col-3" style={{color: getRatingColor(item.quality_rating)}}>
                    Q: {item.quality_rating}
                    </div>
                    <div title={"Relevance Rating - " + item.relevance_rating_details} className="col-3" style={{color: getRatingColor(item.relevance_rating)}}>
                    R: {item.relevance_rating}
                    </div>        
                </div>                    
                
                    <div style={{maxHeight: "70px", overflow: "auto"}}>
                        <p className="pl-20">{item.description}</p>
                    </div>                   
                </div>
                </div>
            </div>
        </div>
    ) : <div>No clips found to select!</div>

    return (
        <div>
            {isLoading ? <div>Loading...</div> : renderedList}            
        </div>
    );
};

export default ClipSelector;