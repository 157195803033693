import {createSlice} from '@reduxjs/toolkit'

const fileUploadSlice = createSlice({
    name:'fileUpload',
    initialState: {
        url:null,
        fields:null
    },
    reducers: {       
        setDetails: (state, action) => {
            const {url, fields}  = action.payload.result.data.url
            return {
                ...state,
                url: url,
                fields: fields
            };
        },
    }
})

export const {setDetails} = fileUploadSlice.actions
export default fileUploadSlice.reducer