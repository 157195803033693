import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-content:center;
  align-items: center;
  justify-content: space-between;
  width:50%;
`

export const CenterChildrenWrapper = styled.div`
    display: flex;
    justify-content:center;    
`