import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { CategoryScale } from "chart.js";

const EngagementBarChart = (props) => {

    Chart.register(...registerables);
    const categoryScale = CategoryScale.id;
    Chart.register(categoryScale, CategoryScale);

    const {views_this_week, likes_this_week, shares_this_week, comments_this_week, views_last_week, likes_last_week, shares_last_week, comments_last_week} = props            

    const options = {
        maintainAspectRatio: true,
        textColor: "white",        
        plugins: {
            legend: {
              labels: {
                color: 'white'
              }
            }
        },
        scales: {
            x: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'  // Light white-grey color
              }
            },
            y: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'  // Light white-grey color
              }
            }
        }
    }

    const data = {
        labels: ["Views", "Likes", "Shares", "Comments"],
        datasets: [
          {
            label: "This Week",
            data: [views_this_week, likes_this_week, shares_this_week, comments_this_week],
            backgroundColor: ["#767ca3", "#767ca3", "#767ca3", "#767ca3"],
            borderColor: ["#767ca3", "#767ca3", "#767ca3", "#767ca3"],
            borderWidth: 1,
            fill: false,
            cubicInterpolationMode: 'monotone'
      
          },
          {
            label: "Last Week",
            data: [views_last_week, likes_last_week, shares_last_week, comments_last_week],
            backgroundColor: ["#eef0fa", "#eef0fa", "#eef0fa", "#eef0fa"],
            borderColor: ["#eef0fa", "#eef0fa", "#eef0fa", "#eef0fa"],
            borderWidth: 1,
            fill: false,
            cubicInterpolationMode: 'monotone'
      
          }
        ]
      };

    return (
        <div className="fill-height">
            <div className="row pt-10">
            <div className="col-12 center">
                <h4 className="fs-20 header bold-text widget-dark-color pt-10">Engagement Statistics</h4>           
            </div>
            <div className="fill-height center pt-20">
                <Bar                   
                    data={data}     
                    options={options}                    
                />
            </div>            
            </div>
        </div>
    );
}

export default EngagementBarChart